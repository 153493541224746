import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FormControl,
  Text,
  Button,
  Box,
  Grid,
  Flex,
  Select,
  Input,
} from "@chakra-ui/react";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { useStore } from "../../../store/store";

type FormData = {
  accidentalDamage: string;
  mechanicalElectricalBreakdown: string;
  theftBurglary: string;
  fireNaturalDisasters: string;
  transitCoverage: string;
  vandalism: string;
  thirdPartyLiability: string;
  businessInterruption: string;
  consumablesAccessories: string;
  softwareMalfunction: string;
  temporaryReplacement: string;
  installationDamage: string;
  coverageLocation: string;
  coverageLocation_Other: string;
};

const ElectronicStep2 = ({
  mode,
  handleBack,
  handleNext,
  formValues,
  setFormValues,
}: any) => {
  const { setHoveredLabel, resetHoveredLabel } = useStore();
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      accidentalDamage: "yes",
      mechanicalElectricalBreakdown: "yes",
      theftBurglary: "yes",
      fireNaturalDisasters: "yes",
      transitCoverage: "yes",
      vandalism: "yes",
      thirdPartyLiability: "yes",
      businessInterruption: "yes",
      consumablesAccessories: "yes",
      softwareMalfunction: "yes",
      temporaryReplacement: "yes",
      installationDamage: "yes",
      coverageLocation: "Pan India",
    },
  });

  const onSubmit = (data: FormData) => {
    setFormValues({ ...formValues, ...data });
    handleNext();
  };
  useEffect(() => {
    if (formValues) {
      reset({
        ...formValues,
      });
    }
  }, [formValues, reset]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" w="100%">
        <Box borderBottom="1px solid" mt="2px" mb="3" pb="3">
          <Text fontWeight="bold" fontSize="20px" color="darkorchid">
            Coverages
          </Text>
        </Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <FormControl isInvalid={Boolean(errors?.accidentalDamage)}>
            <HoverableFormLabel>Accidental Damage</HoverableFormLabel>
            <Controller
              name="accidentalDamage"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() => setHoveredLabel("Accidental Damage")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              )}
            />
            {errors?.accidentalDamage && (
              <Text color="red.500" fontSize="sm">
                {errors?.accidentalDamage?.message}
              </Text>
            )}
          </FormControl>

          {/* Repeat similar FormControl for other fields */}
          <FormControl
            isInvalid={Boolean(errors?.mechanicalElectricalBreakdown)}
          >
            <HoverableFormLabel>
              Mechanical/Electrical Breakdown
            </HoverableFormLabel>
            <Controller
              name="mechanicalElectricalBreakdown"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() =>
                    setHoveredLabel("Mechanical/Electrical Breakdown")
                  }
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              )}
            />
            {errors?.mechanicalElectricalBreakdown && (
              <Text color="red.500" fontSize="sm">
                {errors?.mechanicalElectricalBreakdown?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.theftBurglary)}>
            <HoverableFormLabel>Theft/Burglary</HoverableFormLabel>
            <Controller
              name="theftBurglary"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() => setHoveredLabel("Theft/Burglary")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              )}
            />
            {errors?.theftBurglary && (
              <Text color="red.500" fontSize="sm">
                {errors?.theftBurglary?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.fireNaturalDisasters)}>
            <HoverableFormLabel>Fire/Natural Disasters</HoverableFormLabel>
            <Controller
              name="fireNaturalDisasters"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() => setHoveredLabel("Fire/Natural Disasters")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              )}
            />
            {errors?.fireNaturalDisasters && (
              <Text color="red.500" fontSize="sm">
                {errors?.fireNaturalDisasters?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.transitCoverage)}>
            <HoverableFormLabel>Transit Coverage</HoverableFormLabel>
            <Controller
              name="transitCoverage"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() => setHoveredLabel("Transit Coverage")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              )}
            />
            {errors?.transitCoverage && (
              <Text color="red.500" fontSize="sm">
                {errors?.transitCoverage?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.vandalism)}>
            <HoverableFormLabel>Vandalism</HoverableFormLabel>
            <Controller
              name="vandalism"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() => setHoveredLabel("Vandalism")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              )}
            />
            {errors?.vandalism && (
              <Text color="red.500" fontSize="sm">
                {errors?.vandalism?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.thirdPartyLiability)}>
            <HoverableFormLabel>Third-Party Liability</HoverableFormLabel>
            <Controller
              name="thirdPartyLiability"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() => setHoveredLabel("Third-Party Liability")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              )}
            />
            {errors?.thirdPartyLiability && (
              <Text color="red.500" fontSize="sm">
                {errors?.thirdPartyLiability?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.businessInterruption)}>
            <HoverableFormLabel>Business Interruption</HoverableFormLabel>
            <Controller
              name="businessInterruption"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() => setHoveredLabel("Business Interruption")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              )}
            />
            {errors?.businessInterruption && (
              <Text color="red.500" fontSize="sm">
                {errors?.businessInterruption?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.consumablesAccessories)}>
            <HoverableFormLabel>Consumables/Accessories</HoverableFormLabel>
            <Controller
              name="consumablesAccessories"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() => setHoveredLabel("Consumables/Accessories")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              )}
            />
            {errors?.consumablesAccessories && (
              <Text color="red.500" fontSize="sm">
                {errors?.consumablesAccessories?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.softwareMalfunction)}>
            <HoverableFormLabel>Software Malfunction</HoverableFormLabel>
            <Controller
              name="softwareMalfunction"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() => setHoveredLabel("Software Malfunction")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              )}
            />
            {errors?.softwareMalfunction && (
              <Text color="red.500" fontSize="sm">
                {errors?.softwareMalfunction?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.temporaryReplacement)}>
            <HoverableFormLabel>Temporary Replacement</HoverableFormLabel>
            <Controller
              name="temporaryReplacement"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() => setHoveredLabel("Temporary Replacement")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              )}
            />
            {errors?.temporaryReplacement && (
              <Text color="red.500" fontSize="sm">
                {errors?.temporaryReplacement?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.installationDamage)}>
            <HoverableFormLabel>Installation Damage</HoverableFormLabel>
            <Controller
              name="installationDamage"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() => setHoveredLabel("Installation Damage")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              )}
            />
            {errors?.installationDamage && (
              <Text color="red.500" fontSize="sm">
                {errors?.installationDamage?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.coverageLocation)}>
            <HoverableFormLabel>Coverage Location</HoverableFormLabel>
            <Controller
              name="coverageLocation"
              control={control}
              rules={{ required: "This is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  bg="white"
                  borderRadius="21px"
                  onFocus={() => setHoveredLabel("Coverage Location")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="Pan India">Pan India</option>
                  <option value="Other">Other</option>
                </Select>
              )}
            />
            {errors?.coverageLocation && (
              <Text color="red.500" fontSize="sm">
                {errors?.coverageLocation?.message}
              </Text>
            )}
          </FormControl>
          {watch("coverageLocation") === "Other" && (
            <FormControl isInvalid={Boolean(errors?.coverageLocation_Other)}>
              <HoverableFormLabel>Other Location*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...register("coverageLocation_Other", {
                  required: "Please specify other location",
                })}
              />
              {errors?.coverageLocation_Other && (
                <Text color="red.500" fontSize="sm">
                  {errors?.coverageLocation_Other?.message}
                </Text>
              )}
            </FormControl>
          )}
        </Grid>
        <Flex w="100%" justify="space-between" align="center" mt="20px">
          <Button type="button" borderRadius="21px" onClick={handleBack}>
            Previous
          </Button>
          <Button type="submit" borderRadius="21px" colorScheme="green">
            Next
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default ElectronicStep2;
