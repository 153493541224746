import { create } from "zustand";
import useFetch from "../partials/utils/Interceptor/Interceptor";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import Cookies from "js-cookie";
export type CompanyType = {
  id: number;
  c_p_name: string;
  c_name: string;
  business_type: string;
  business_type_name: string;
  c_gstin: string;
  c_contact: string;
  c_email: string;
  country: string;
  c_description: string;
};
interface StepStore {
  user: any;
  setUser: (newData: any) => void;
  step: number;
  setStep: (newStep: number) => void;
  selectedCategories: string[];
  setSelectedCategories: (categories: string[]) => void;
  handleRemoveCategory: any;
  countries: any[]; // Assuming countries can be any type, adjust as needed
  setCountries: (country: any[]) => void;
  companyType: any; // Example of a any type, adjust as per your requirement
  setCompanyType: (type: any) => void;
  businessType: any[];
  setBusinessType: (type: any) => void;
  occupancy: any[];
  setOccupancy: (type: any) => void;
  product: any[];
  setProduct: (products: any[]) => void;
  selectedBid: any;
  setSelectedBid: (selectedBid: any) => void;
  selectedCompany: string | number;
  setSelectedCompany: (selectedCompany: string | number) => void;
  userCompanies: CompanyType[];
  setUserCompanies: (userCompanies: CompanyType[]) => void;
  fetchUserCompanies: () => Promise<void>;
  bidderCompanies: { broker_companies: []; insurance_companies: [] };
  fetchBidderCompanies: () => Promise<void>;
  selectedQuotesList: any;
  setSelectedQuotesList: (value: any) => void;
  quoteComparisonBidId: number;
  setQoteComparisonBidId: (value: number) => void;
  hoveredLabel: any;
  setHoveredLabel: (value: any) => void;
  resetHoveredLabel: () => void;
}
const company = Cookies.get("company");
const cookiesUser = Cookies.get("user");
const parsedUser = cookiesUser ? JSON.parse(cookiesUser) : null;
export const useStore = create<StepStore>((set: any) => ({
  user: parsedUser,
  setUser: (newData: any) => set({ user: newData }),
  step: 1,
  setStep: (newStep: number) => set({ step: newStep }),
  selectedCategories: [],
  setSelectedCategories: (categories) =>
    set({ selectedCategories: categories }),
  handleRemoveCategory: (category: any) =>
    set((state: any) => ({
      selectedCategories: state.selectedCategories.filter(
        (item: any) => item !== category
      ),
    })),
  countries: [],
  setCountries: (country) => set({ countries: country }),
  companyType: [],
  setCompanyType: (type) => set({ companyType: type }),
  businessType: [],
  setBusinessType: (type) => set({ businessType: type }),
  occupancy: [],
  setOccupancy: (type) => set({ occupancy: type }),
  product: [],
  setProduct: (products) => set({ product: products }),
  selectedBid: {},
  setSelectedBid: (newData: any) => set({ selectedBid: newData }),
  selectedCompany: company ? JSON.parse(company) : "",
  setSelectedCompany: (value: number | string) =>
    set({ selectedCompany: value }),
  userCompanies: [],
  setUserCompanies: (value) => set({ userCompanies: value }),
  fetchUserCompanies: async () => {
    try {
      const response = await useFetch.get("api/company/");
      set({ userCompanies: response?.data || [] });
    } catch (error) {
      console.log("ERROR", error);
    }
  },
  bidderCompanies: { broker_companies: [], insurance_companies: [] },
  fetchBidderCompanies: async () => {
    try {
      const response = await useFetch.get("bidder-companies/");
      const data = {
        broker_companies: response.data?.broker_companies,
        insurance_companies: response.data?.insurance_companies,
      };
      set({ bidderCompanies: data });
    } catch (error) {
      console.log("ERROR", error);
    }
  },
  selectedQuotesList: [],
  setSelectedQuotesList: (value) => set({ selectedQuotesList: value }),
  quoteComparisonBidId: 0,
  setQoteComparisonBidId: (value) => set({ quoteComparisonBidId: value }),
  hoveredLabel: null,
  setHoveredLabel: (label: string) => set({ hoveredLabel: label }),
  resetHoveredLabel: () => set({ hoveredLabel: null }),
}));
