import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Avatar,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
  Text,
  useToast,
} from "@chakra-ui/react";
import Messages from "../../components/Messages";
import { AiOutlineSend } from "react-icons/ai";
import Cookies from "js-cookie";
import { productMapping } from "../../partials/utils/Contants";
import { CgAttachment } from "react-icons/cg";
import { MdOutlineCancel } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";

const SocketChat = ({
  user,
  ws,
  clientId,
  bidderId,
  messages,
  setMessages,
  inputMessage,
  setInputMessage,
  fileInput,
  setFileInput,
  setSelectedBidder,
  // fileInputRef,
  bid,
}: any) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const wsBaseUrl = process.env.REACT_APP_WS_BASE_URL;
  const token = Cookies.get("jwt");

  const wsUrl = useMemo(
    () => `${wsBaseUrl}/${bid?.bid_id}/${clientId}/${bidderId}/?token=${token}`,
    [clientId, bidderId, bid?.bid_id]
  );

  useEffect(() => {
    setIsLoading(true);
    ws.current = new WebSocket(wsUrl);

    ws.current.onopen = () => {
      console.log("WebSocket connection opened");
      setIsLoading(false);
    };

    ws.current.onmessage = (event: any) => {
      const messageData = JSON.parse(event.data);
      setMessages((prevMessages: any) => [...prevMessages, messageData]);
    };
    ws.current.onerror = (error: any) => {
      console.error("WebSocket error:", error);
      setIsLoading(false);
    };
    ws.current.onclose = (msg: any) => {
      console.log("WebSocket connection closed");
      setIsLoading(false);
    };
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [wsUrl]);

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      const payload = {
        message: inputMessage,
        file: null,
        file_name: null,
      };
      ws.current?.send(JSON.stringify(payload));
      setInputMessage("");
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

      if (file.size > MAX_FILE_SIZE) {
        toast({
          title: "File Size Exceeded",
          description: "Please select a file smaller than 5MB.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      setFileInput(file);
    }
  };

  const handleUploadFile = async () => {
    if (!fileInput) return;
    const fileData = await toBase64(fileInput);

    const payload = {
      message: "",
      file: fileData,
      file_name: fileInput.name,
    };
    ws.current?.send(JSON.stringify(payload));

    setFileInput(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the input field
    }
  };

  const toBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };
  const product = productMapping[bid?.product] || bid?.product;
  return (
    <Flex
      direction="column"
      padding={bid?.bid_id ? "10px 20px" : "40px 25px 20px"}
      flex={1}
      bg="black"
      gap="40px"
      maxW="460px"
      height="100vh"
      justify="space-between"
    >
      <Flex position="absolute" right="15px" top="15px">
        <RxCross1
          color="white"
          onClick={() =>
            setSelectedBidder({
              id: null,
              bidData: {},
            })
          }
          cursor="pointer"
        />
      </Flex>
      <Flex align="center" w="100%" gap="12px">
        <Avatar src="" name="" />
        <Text color="white">
          {bid?.bidder_name || bid?.client_name || product}
        </Text>
      </Flex>
      <Flex
        direction="column"
        // justify="end"
        h="75vh"
        overflowY="auto"
        className="hide-scrollbar"
      >
        {isLoading ? (
          [1, 2, 3, 4, 5].map(() => <Skeleton height="50px" w="90%" />)
        ) : (
          <Messages messages={messages} isInsight={false} user={user} />
        )}
      </Flex>
      <Flex direction="column">
        {fileInput?.name && (
          <Flex
            align="center"
            gap="12px"
            padding="2"
            w="100%"
            borderRadius="6px"
            bg="#dadada"
            justify="space-between"
          >
            <Text color="black" fontSize="12px">
              <strong>File:</strong> {fileInput?.name}
            </Text>
            <Icon
              cursor="pointer"
              as={MdOutlineCancel}
              color="black"
              onClick={() => setFileInput(null)}
            />
          </Flex>
        )}
        <Flex align="center" gap="12px" padding="2" w="100%">
          <Input
            // boxShadow="0 0 10px #6f6e6e"
            placeholder="Type your message..."
            border="1px solid rgba(227, 228, 232, 1)"
            borderRadius="5px"
            //   outline={"none"}
            color="white"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (fileInput) {
                  handleUploadFile();
                } else {
                  handleSendMessage();
                }
              }
            }}
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
          />
          {/* <Flex
          align="center"
          //  onClick={() => fileInputRef.current?.click()}
        > */}
          <Icon
            fontSize="20px"
            cursor="pointer"
            as={CgAttachment}
            color="rgba(227, 228, 232, 1)"
            onClick={() => fileInputRef.current?.click()}
            title="Maximum file size: 5MB"
          />
          <input
            style={{ display: "none" }}
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
          />
          {/* </Flex> */}
          <AiOutlineSend
            cursor="pointer"
            fontSize="20px"
            color="rgba(227, 228, 232, 1)"
            onClick={() => {
              if (fileInput) {
                handleUploadFile();
              } else {
                handleSendMessage();
              }
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SocketChat;
