import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  Grid,
  Text,
  HStack,
  Button,
  Divider,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { useStore } from "../../../store/store";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import "../form.css";
import {
  BEProductMap,
  convertProductName,
  flattenObject,
} from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
import { changeSattusToDraft } from "../Forms";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";

type FormValues = {
  Nature_of_work: string;
  Structure_Category: string;
  Structure_Category_name_other?: string;
  policytype: string;
  insurer: string;
  comp_location: string;
  Location_Type: string;
  Project_Location: string;
  Project_Timeline: number;
  Project_Complete: number;
  Project_Initiate_On: string;
  Security_Services: string;
  Risk_Terrain: string;
  Material_Storage: string;
  Project_Claim: string;
  Client_Experience: string;
  Client_Claim_Experience: string;
  Contract_Price: number;
  Material_Supply_value: number;
  Labour_Cost: number;
  Glass_Item_value: number;
  Other_Item_value?: string;
  Earthquake_Sublimit?: number;
  Terrorism?: number;
  Owners_Surrounding_Property?: number;
  Debris_Removal_limit?: number;
  Maintenance_Cover?: number;
  Machinery_plants_and_equipment?: string;
  start_date: string;
  end_date: string;
  policy_start_date?: string;
  policy_end_date?: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer?: string;
  previous_policy_expiry?: string;
};

const ContractosAllRisks = ({
  product,
  editData,
  mode,
  selectedCompany,
  setCreatedBid,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { step, setStep, setHoveredLabel, resetHoveredLabel } = useStore();
  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      Project_Complete: 0,
      Contract_Price: 0,
      Material_Supply_value: 0,
      Labour_Cost: 0,
      Glass_Item_value: 0,
      Earthquake_Sublimit: 0,
      Terrorism: 0,
      Owners_Surrounding_Property: 0,
      Debris_Removal_limit: 0,
      Maintenance_Cover: 0,
      Machinery_plants_and_equipment: " upto 5% or Rs.25 Lakhs ",
      expected_premium: 0,
      sum_insured: 0,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
    },
  });

  const toast = useToast();
  const onSubmit = (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    formData.append("comp_id", selectedCompany.toString() || "");
    const parsedProduct = BEProductMap[product];

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }

    formData.append("form_properties", JSON.stringify({ ...data }));
    if (editData?.id) {
      setIsSubmitting(true);
      useFetch
        .put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData
        )
        .then((res) => {
          setCreatedBid(res.data?.data?.id);
          toast({
            title: `Bid updated successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      setIsSubmitting(true);
      useFetch
        .post(`/api/${selectedCompany}/store-bid/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setCreatedBid(res.data?.id);
          toast({
            title: `Bid created successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    }
  };
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormValues, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);
  const MaterialSupplyvalue = +watch("Material_Supply_value");
  const LabourCost = +watch("Labour_Cost");
  const GlassItemValue = +watch("Glass_Item_value");
  const OtherItemValue = +(watch("Other_Item_value") || 0);

  useEffect(() => {
    const sum =
      MaterialSupplyvalue + LabourCost + GlassItemValue + OtherItemValue;
    setValue("sum_insured", sum);
  }, [MaterialSupplyvalue, LabourCost, GlassItemValue, OtherItemValue]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", flexDirection: "column", gap: "20px" }}
    >
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <Controller
          name="Nature_of_work"
          rules={{ required: "Nature of work is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Nature_of_work)}>
              <HoverableFormLabel>Nature of Project</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...field}
                id="Nature_of_work"
                onFocus={() => setHoveredLabel("Nature of Project")}
                onBlur={() => resetHoveredLabel()}
              />
              {errors?.Nature_of_work && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Nature_of_work?.message}
                </Text>
              )}
            </FormControl>
          )}
        />
        <HStack>
          <Controller
            name="Structure_Category"
            control={control}
            rules={{ required: "Structure Category is required" }}
            render={({ field }) => (
              <FormControl isInvalid={Boolean(errors?.Structure_Category)}>
                <HoverableFormLabel>Structure Category</HoverableFormLabel>
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  id="Structure_Category"
                  onFocus={() => setHoveredLabel("Structure Category")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="" disabled selected>
                    Select Structure Category
                  </option>
                  <option value="Acquaculture project laying of HDPE pipes/accessories to be rated as works in water">
                    Acquaculture project laying of HDPE pipes/accessories to be
                    rated as works in water
                  </option>
                  <option value="Factory sheds, warehouses, cold storages, hangars">
                    Factory sheds, warehouses, cold storages, hangars
                  </option>
                  <option value="Flyovers on land">Flyovers on land</option>
                  <option value="Ground leveling and reclamation work">
                    Ground leveling and reclamation work
                  </option>
                  <option value="Interior decoration">
                    Interior decoration
                  </option>
                  <option value="Residential and office buildings">
                    Residential and office buildings
                  </option>
                  <option value="Hospitals, schools, and theaters">
                    Hospitals, schools, and theaters
                  </option>
                  <option value="Roads, railways, bridges, tunnels, airports, dams, weirs, irrigation and drainage facilities, canals, and ports">
                    Roads, railways, bridges, tunnels, airports, dams, weirs,
                    irrigation and drainage facilities, canals, and ports
                  </option>
                  <option value="Power plants and other energy infrastructure">
                    Power plants and other energy infrastructure
                  </option>
                  <option value="Others type of structure">
                    Others type of structure
                  </option>
                </Select>
                {errors?.Structure_Category && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.Structure_Category?.message}
                  </Text>
                )}
              </FormControl>
            )}
          />
          {watch("Structure_Category") === "Others type of structure" && (
            <FormControl
              isInvalid={Boolean(errors?.Structure_Category_name_other)}
            >
              <HoverableFormLabel>Structure Category Name*</HoverableFormLabel>
              <Input
                {...register("Structure_Category_name_other", {
                  required: "Structure Category Name is required",
                })}
                borderRadius="16px"
                background="white"
              />
              {errors?.Structure_Category_name_other && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Structure_Category_name_other?.message}
                </Text>
              )}
            </FormControl>
          )}
        </HStack>
        <Controller
          name="policytype"
          control={control}
          rules={{ required: "Please Select Policy type" }}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.policytype)}>
              <HoverableFormLabel>Policy Type*</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...field}
                id="policytype"
                onFocus={() => setHoveredLabel("Policy Type")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="" disabled selected>
                  Select policy type
                </option>
                <option value="New">New</option>
                <option value="Renewal">Renewal</option>
              </Select>
              {errors?.policytype && (
                <Text color="red.500" fontSize="sm">
                  {errors?.policytype?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="insurer"
          rules={{ required: "This field is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.insurer)}>
              <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...field}
                id="insurer"
                onFocus={() => setHoveredLabel("Bidder Preference")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="" disabled selected>
                  Preference
                </option>
                <option value="Both">Both</option>
                <option value="Brokers">Brokers</option>
                <option value="INS_COMP">Insurance Company</option>
              </Select>
              {errors?.insurer && (
                <Text color="red.500" fontSize="sm">
                  {errors?.insurer?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="comp_location"
          rules={{ required: "Company Location is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.comp_location)}>
              <HoverableFormLabel>
                Where is your company located?*
              </HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...field}
                id="comp_location"
                onFocus={() =>
                  setHoveredLabel("Where is your company located?")
                }
                onBlur={() => resetHoveredLabel()}
              />
              {errors?.comp_location && (
                <Text color="red.500" fontSize="sm">
                  {errors?.comp_location?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Location_Type"
          rules={{ required: "This field is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Location_Type)}>
              <HoverableFormLabel>Location Type*</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...field}
                id="Location_Type"
                onFocus={() => setHoveredLabel("Location Type")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="" disabled selected>
                  Select Location Type
                </option>
                <option value="Single">Single</option>
                <option value="Multiple">Multiple</option>
              </Select>
              {errors?.Location_Type && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Location_Type?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Project_Location"
          rules={{ required: "This field is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Project_Location)}>
              <HoverableFormLabel>Project Location*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...field}
                id="Project_Location"
                onFocus={() => setHoveredLabel("Project Location")}
                onBlur={() => resetHoveredLabel()}
              />
              {errors?.Project_Location && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Project_Location?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Project_Timeline"
          rules={{ required: "This field is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Project_Timeline)}>
              <HoverableFormLabel>
                Project Timeline (In months)*
              </HoverableFormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  borderRadius="21px"
                  bg="white"
                  {...field}
                  id="Project_Timeline"
                  onFocus={() =>
                    setHoveredLabel("Project Timeline (In months)")
                  }
                  onBlur={() => resetHoveredLabel()}
                />
              </NumberInput>
              {errors?.Project_Timeline && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Project_Timeline?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Project_Complete"
          rules={{ required: "This field is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Project_Complete)}>
              <HoverableFormLabel>Project Complete (%)*</HoverableFormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  borderRadius="21px"
                  bg="white"
                  {...field}
                  id="Project_Complete"
                  onFocus={() => setHoveredLabel("Project Complete (%)")}
                  onBlur={() => resetHoveredLabel()}
                />
              </NumberInput>
              {errors?.Project_Complete && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Project_Complete?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Project_Initiate_On"
          rules={{ required: "This field is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Project_Initiate_On)}>
              <HoverableFormLabel>Project Initiate On</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...field}
                id="Project_Initiate_On"
                onFocus={() => setHoveredLabel("Project Initiate On")}
                onBlur={() => resetHoveredLabel()}
              />
              {errors?.Project_Initiate_On && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Project_Initiate_On?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Security_Services"
          rules={{ required: "This field is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Security_Services)}>
              <HoverableFormLabel>Security Services</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...field}
                id="Security_Services"
                onFocus={() => setHoveredLabel("Security Services")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="" disabled selected>
                  Select Security Services
                </option>
                <option value="Fenced Compound">Fenced Compound</option>
                <option value="Fenced Compound +24 Hours watch and Ward Security">
                  Fenced Compound +24 Hours watch and Ward Security
                </option>
                <option value="Fenced Compound +24 Hours watch and Ward Security + site lightning for hours of darkness">
                  Fenced Compound +24 Hours watch and Ward Security + site
                  lightning for hours of darkness
                </option>
              </Select>
              {errors?.Security_Services && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Security_Services?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Risk_Terrain"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Risk_Terrain)}>
              <Flex align="start">
                <HoverableFormLabel>Risk Terrain</HoverableFormLabel>
                {mode === "Guided" && (
                  <FaInfoCircle
                    fontSize="11px"
                    color="gray.500"
                    title="Identifying and mapping areas with heightened risks or vulnerabilities"
                  />
                )}
              </Flex>
              <Select
                bg="white"
                borderRadius="21px"
                {...field}
                id="Risk_Terrain"
                onFocus={() => setHoveredLabel("Risk Terrain")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="" disabled selected>
                  Select Risk Terrain
                </option>
                <option value="Low Lying">Low Lying</option>
                <option value="Flat">Flat</option>
                <option value="Hilly">Hilly</option>
              </Select>
              {errors?.Risk_Terrain && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Risk_Terrain?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Material_Storage"
          control={control}
          render={({ field }) => (
            <FormControl>
              <HoverableFormLabel>Material Storage</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...field}
                id="Material_Storage"
                onFocus={() => setHoveredLabel("Material Storage")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="" disabled selected>
                  Select Material Storage
                </option>
                <option value="Open Storage - Less than 10 meters from Erection / construction Site">
                  Open Storage - Less than 10 meters from Erection /
                  construction Site
                </option>
                <option value="In sheds/ Closed Premises - Less than 10 meters from Erection / Construction Site">
                  In sheds/ Closed Premises - Less than 10 meters from Erection
                  / Construction Site
                </option>
                <option value="In sheds/ Closed Premises - More than 10 meters from Erection / Construction Site">
                  In sheds/ Closed Premises - More than 10 meters from Erection
                  / Construction Site
                </option>
              </Select>
            </FormControl>
          )}
        />

        <Controller
          name="Project_Claim"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Project_Claim)}>
              <HoverableFormLabel>Claim Occurred in Project</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...field}
                id="Project_Claim"
                onFocus={() => setHoveredLabel("Claim Occurred in Project")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="" disabled selected>
                  Select Project Claim
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
              {errors?.Project_Claim && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Project_Claim?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Client_Experience"
          rules={{ required: "This field is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Client_Experience)}>
              <HoverableFormLabel>
                Client Experience in Similar Project
              </HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...field}
                id="Client_Experience"
                onFocus={() =>
                  setHoveredLabel("Client Experience in Similar Project")
                }
                onBlur={() => resetHoveredLabel()}
              >
                <option value="" disabled selected>
                  Select Client Experience
                </option>
                <option value="Below 10 Years">Below 10 Years</option>
                <option value="Above 10 Years">Above 10 Years</option>
              </Select>
              {errors?.Client_Experience && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Client_Experience?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Client_Claim_Experience"
          rules={{ required: "This field is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Client_Claim_Experience)}>
              <HoverableFormLabel>Client Claim Experience*</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...field}
                id="Client_Claim_Experience"
                onFocus={() => setHoveredLabel("Client Claim Experience")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="" disabled selected>
                  Select Client Claim Experience
                </option>
                <option value="More than 5 claim in 5 year">
                  More than 5 claim in 5 year
                </option>
                <option value="More than 5 claim in 5 year">
                  Less than 5 claim in 5 year
                </option>
                <option value="No Claim in last 5 years">
                  No Claim in last 5 years
                </option>
              </Select>
              {errors?.Client_Claim_Experience && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Client_Claim_Experience?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Contract_Price"
          rules={{ required: "This field is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Contract_Price)}>
              <HoverableFormLabel>Contract Price*</HoverableFormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  borderRadius="21px"
                  bg="white"
                  {...field}
                  id="Contract_Price"
                  onFocus={() => setHoveredLabel("Contract Price")}
                  onBlur={() => resetHoveredLabel()}
                />
              </NumberInput>
              {errors?.Contract_Price && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Contract_Price?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Material_Supply_value"
          rules={{ required: "This field is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Material_Supply_value)}>
              <HoverableFormLabel>Material Supply Value*</HoverableFormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  borderRadius="21px"
                  bg="white"
                  {...field}
                  id="Material_Supply_value"
                  onFocus={() => setHoveredLabel("Material Supply Value")}
                  onBlur={() => resetHoveredLabel()}
                />
              </NumberInput>
              {errors?.Material_Supply_value && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Material_Supply_value?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Labour_Cost"
          control={control}
          rules={{ required: "This field is required" }}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Labour_Cost)}>
              <HoverableFormLabel>Labour Cost*</HoverableFormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  borderRadius="21px"
                  bg="white"
                  {...field}
                  id="Labour_Cost"
                  onFocus={() => setHoveredLabel("Labour Cost")}
                  onBlur={() => resetHoveredLabel()}
                />
              </NumberInput>
              {errors?.Labour_Cost && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Labour_Cost?.message}
                </Text>
              )}
            </FormControl>
          )}
        />

        <Controller
          name="Glass_Item_value"
          rules={{ required: "This field is required" }}
          control={control}
          render={({ field }) => (
            <FormControl isInvalid={Boolean(errors?.Glass_Item_value)}>
              <HoverableFormLabel>Glass Item Value*</HoverableFormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  borderRadius="21px"
                  bg="white"
                  {...field}
                  id="Glass_Item_value"
                  onFocus={() => setHoveredLabel("Glass Item Value")}
                  onBlur={() => resetHoveredLabel()}
                />
              </NumberInput>
              {errors?.Glass_Item_value && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Glass_Item_value?.message}
                </Text>
              )}
            </FormControl>
          )}
        />
        <HStack gridColumn="span 2">
          <Controller
            name="Other_Item_value"
            control={control}
            render={({ field }) => (
              <FormControl>
                <HoverableFormLabel>Other Item Value</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  id="Other_Item_value"
                  onFocus={() => setHoveredLabel("Other Item Value")}
                  onBlur={() => resetHoveredLabel()}
                />
              </FormControl>
            )}
          />
        </HStack>
        <Divider borderColor="gray.800" gridColumn="span 2" />
        {/* Optional Coverages Section */}
        <Text
          gridColumn="span 2"
          color="darkorchid"
          fontWeight="600"
          fontSize="16px"
        >
          Optional Coverages{" "}
          <span style={{ fontSize: "11px", color: "gray" }}>(Sublimit)</span>
        </Text>
        <Controller
          name="Earthquake_Sublimit"
          control={control}
          render={({ field }) => (
            <FormControl>
              <HoverableFormLabel>Earthquake Sublimit</HoverableFormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  borderRadius="21px"
                  bg="white"
                  {...field}
                  id="Earthquake_Sublimit"
                  onFocus={() => setHoveredLabel("Earthquake Sublimit")}
                  onBlur={() => resetHoveredLabel()}
                />
              </NumberInput>
            </FormControl>
          )}
        />

        <Controller
          name="Terrorism"
          control={control}
          render={({ field }) => (
            <FormControl>
              <HoverableFormLabel>Terrorism</HoverableFormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  borderRadius="21px"
                  bg="white"
                  {...field}
                  id="Terrorism"
                  onFocus={() => setHoveredLabel("Terrorism")}
                  onBlur={() => resetHoveredLabel()}
                />
              </NumberInput>
            </FormControl>
          )}
        />

        <Controller
          name="Owners_Surrounding_Property"
          control={control}
          render={({ field }) => (
            <FormControl>
              <HoverableFormLabel>
                Owners Surrounding Property (Optional coverage)
              </HoverableFormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  borderRadius="21px"
                  bg="white"
                  {...field}
                  id="Owners_Surrounding_Property"
                  onFocus={() =>
                    setHoveredLabel(
                      "Owners Surrounding Property (Optional coverage)"
                    )
                  }
                  onBlur={() => resetHoveredLabel()}
                />
              </NumberInput>
            </FormControl>
          )}
        />

        <Controller
          name="Debris_Removal_limit"
          control={control}
          render={({ field }) => (
            <FormControl>
              <HoverableFormLabel>Debris Removal Limit</HoverableFormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  borderRadius="21px"
                  bg="white"
                  {...field}
                  id="Debris_Removal_limit"
                  onFocus={() => setHoveredLabel("Debris Removal Limit")}
                  onBlur={() => resetHoveredLabel()}
                />
              </NumberInput>
            </FormControl>
          )}
        />

        <Controller
          name="Maintenance_Cover"
          control={control}
          render={({ field }) => (
            <FormControl>
              <HoverableFormLabel>
                Maintenance Cover (Enter month)
              </HoverableFormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  borderRadius="21px"
                  bg="white"
                  {...field}
                  id="Maintenance_Cover"
                  onFocus={() =>
                    setHoveredLabel("Maintenance Cover (Enter month)")
                  }
                  onBlur={() => resetHoveredLabel()}
                />
              </NumberInput>
            </FormControl>
          )}
        />

        <Controller
          name="Machinery_plants_and_equipment"
          control={control}
          render={({ field }) => (
            <FormControl>
              <HoverableFormLabel>
                Machinery, Plants, and Equipment
              </HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...field}
                id="Machinery_plants_and_equipment"
                onFocus={() =>
                  setHoveredLabel("Machinery, Plants, and Equipment")
                }
                onBlur={() => resetHoveredLabel()}
              >
                <option value="" disabled selected>
                  Select Machinery, Plants, and Equipment
                </option>
                <option value="Company Supplied">Company Supplied</option>
                <option value="Client Supplied">Client Supplied</option>
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      <Divider borderColor="gray.800" />
      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors?.start_date)}>
          <HoverableFormLabel htmlFor="start_date">
            Bid's Start Date*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            id="start_date"
            type="date"
            min={todayDate}
            {...register("start_date", {
              required: "Bid's start date is required",
            })}
            onFocus={() => setHoveredLabel("Bid's Start Date")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.start_date && (
            <Text color="red.500" fontSize="sm">
              {errors.start_date.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.end_date)}>
          <HoverableFormLabel htmlFor="end_date">
            Bid's End Date*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            id="end_date"
            type="date"
            min={watch("start_date")}
            {...register("end_date", {
              required: "Bid's end date is required",
            })}
            onFocus={() => setHoveredLabel("Bid's End Date")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.end_date && (
            <Text color="red.500" fontSize="sm">
              {errors.end_date.message}
            </Text>
          )}
        </FormControl>
      </HStack>
      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors?.policy_start_date)}>
          <HoverableFormLabel htmlFor="policy_start_date">
            Shipment Start Date*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            id="policy_start_date"
            type="date"
            min={todayDate}
            {...register("policy_start_date", {
              required: "Shipment start date is required",
            })}
            onFocus={() => setHoveredLabel("Shipment Start Date")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.policy_start_date && (
            <Text color="red.500" fontSize="sm">
              {errors.policy_start_date.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.policy_end_date)}>
          <HoverableFormLabel htmlFor="policy_end_date">
            Shipment End Date*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            id="policy_end_date"
            type="date"
            min={watch("policy_start_date")}
            {...register("policy_end_date", {
              required: "Shipment end date is required",
            })}
            onFocus={() => setHoveredLabel("Shipment End Date")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.policy_end_date && (
            <Text color="red.500" fontSize="sm">
              {errors.policy_end_date.message}
            </Text>
          )}
        </FormControl>
      </HStack>
      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors?.expected_premium)}>
          <Flex align="start">
            <HoverableFormLabel>Anticipated premium*</HoverableFormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Anticipated premium for acquiring this policy."
              />
            )}
          </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            id="expected_premium"
            type="number"
            {...register("expected_premium", {
              required: "Anticipated premium is required",
            })}
            onFocus={() => setHoveredLabel("Anticipated premium")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.expected_premium && (
            <Text color="red.500" fontSize="sm">
              {errors.expected_premium.message}
            </Text>
          )}
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.sum_insured)}>
          <HoverableFormLabel htmlFor="sum_insured">
            Construction value*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            id="sum_insured"
            type="number"
            {...register("sum_insured", {
              required: "Construction value is required",
            })}
            onFocus={() => setHoveredLabel("Construction value")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.sum_insured && (
            <Text color="red.500" fontSize="sm">
              {errors.sum_insured.message}
            </Text>
          )}
        </FormControl>

        {/* <FormControl isInvalid={Boolean(errors?.Other_Details)}>
          <HoverableFormLabel htmlFor="Other_Details">Other Details</HoverableFormLabel>
          <Input bg="white"
              borderRadius="21px"
            id="Other_Details"
            {...register('Other_Details')}
          />
          {errors?.Other_Details && (
            <Text color="red.500" fontSize="sm">
              {errors.Other_Details.message}
            </Text>
          )}
        </FormControl> */}
      </HStack>
      {watch("policytype") === "Renewal" && (
        <HStack gap="4">
          <FormControl
            id="previous_insurer"
            isInvalid={Boolean(errors?.previous_insurer)}
          >
            <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("previous_insurer", {
                required: "Previous insurer is required",
              })}
              onFocus={() => setHoveredLabel("Previous Insurer")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.previous_insurer && (
              <Text color="red.500" fontSize="sm">
                {errors?.previous_insurer?.message}
              </Text>
            )}
          </FormControl>

          <FormControl
            id="previous_policy_expiry"
            isInvalid={Boolean(errors?.previous_policy_expiry)}
          >
            <HoverableFormLabel>Previous policy expiry*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              {...register("previous_policy_expiry", {
                required: "Previous policy expiry is required",
              })}
              onFocus={() => setHoveredLabel("Previous policy expiry")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.previous_policy_expiry && (
              <Text color="red.500" fontSize="sm">
                {errors?.previous_policy_expiry?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
      )}
      <HStack w="100%" justify="space-between" align="center" mt="20px">
        <Button
          type="button"
          borderRadius="21px"
          onClick={() => setStep(step - 1)}
        >
          Previous
        </Button>
        <Button
          type="submit"
          borderRadius="21px"
          colorScheme="green"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Next
        </Button>
      </HStack>
    </form>
  );
};
export default ContractosAllRisks;
