import {
  Box,
  Button,
  Flex,
  FormControl,
  Grid,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { FaInfoCircle } from "react-icons/fa";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";
import { useEffect } from "react";
import { useStore } from "../../../store/store";

type FormValues = {
  start_date: string;
  end_date: string;
  policy_start_date?: string;
  policy_end_date?: string;
  sum_insured: number;
  any_other_detail: string;
};
const ElectronicStep3 = ({
  mode,
  handleBack,
  handleNext,
  formValues,
  setFormValues,
  isSubmitting,
}: any) => {
  const { setHoveredLabel, resetHoveredLabel } = useStore();
  const currentDate = new Date();
  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() + 30);
  const formattedFutureDate = futureDate.toISOString().split("T")[0];
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      sum_insured: 0,
      start_date: new Date().toISOString().split("T")[0],
      end_date: formattedFutureDate,
    },
  });
  const onSubmit = (data: FormValues) => {
    const submitData = { ...formValues, ...data };
    setFormValues(submitData);
    setTimeout(() => {
      handleNext(submitData);
    }, 1000);
  };
  useEffect(() => {
    if (formValues) {
      reset({
        ...formValues,
      });
    }
  }, [formValues, reset]);

  useEffect(() => {
    let total = 0;
    formValues?.equipments?.forEach((d: any) => {
      total += d?.equipmentPrice;
    });
    setValue("sum_insured", total);
  }, [formValues?.equipments]);

  const startDate = watch("start_date");
  const endDate = watch("end_date");
  useEffect(() => {
    setValue("policy_start_date", startDate);
  }, [startDate]);
  useEffect(() => {
    setValue("policy_end_date", endDate);
  }, [endDate]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column">
        <Box borderBottom="1px solid" mt="3" mb="6" pb="3">
          <Text fontWeight="bold" fontSize="20px" color="darkorchid">
            Other Details
          </Text>
        </Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <FormControl isInvalid={Boolean(errors?.start_date)}>
            <HoverableFormLabel>Bid start date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("start_date", {
                required: "Bid start date is required",
              })}
              onFocus={() => setHoveredLabel("Bid start date")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.start_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.start_date?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.end_date)}>
            <HoverableFormLabel>Bid End date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch("start_date")}
              {...register("end_date", {
                required: "Bid end date is required",
              })}
              onFocus={() => setHoveredLabel("Bid End date")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.end_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.end_date?.message}
              </Text>
            )}
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Policy start date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("policy_start_date")}
              onFocus={() => setHoveredLabel("Policy start date")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Policy end date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch("policy_start_date")}
              {...register("policy_end_date")}
              onFocus={() => setHoveredLabel("Policy end date")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.sum_insured)}>
            <Flex align="start">
              <HoverableFormLabel>Sum Insured*</HoverableFormLabel>
              {/* {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Anticipated premium for acquiring this policy."
                />
              )} */}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("sum_insured", {
                required: "Sum Insured is required",
              })}
              min="0"
              onFocus={() => setHoveredLabel("Sum Insured")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.sum_insured && (
              <Text color="red.500" fontSize="sm">
                {errors?.sum_insured?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.any_other_detail)}>
            <HoverableFormLabel>Any other details</HoverableFormLabel>
            <Textarea
              bg="white"
              borderRadius="21px"
              resize="none"
              {...register("any_other_detail")}
              onFocus={() => setHoveredLabel("Any other details")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.any_other_detail && (
              <Text color="red.500" fontSize="sm">
                {errors?.any_other_detail?.message}
              </Text>
            )}
          </FormControl>
        </Grid>
        <Flex w="100%" justify="space-between" align="center" mt="20px">
          <Button
            type="button"
            borderRadius="21px"
            onClick={handleBack}
            isDisabled={isSubmitting}
          >
            Previous
          </Button>
          <Button
            type="submit"
            borderRadius="21px"
            colorScheme="green"
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default ElectronicStep3;
