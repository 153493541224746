import { useEffect, useMemo, useState } from "react";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { Flex, Image, Skeleton, Text } from "@chakra-ui/react";
import SanbotLogo from "../../../partials/assets/images/galaxy-logo.png";
import BulbIcon from "../../../partials/assets/icons/bulb-icon.png";
import { BEProductMap } from "../../../partials/utils/helper";
import { useStore } from "../../../store/store";
import "./formInsights.css";

type FormInsightsProps = {
  product: string;
};

const Card = ({ title, data }: any) => {
  return (
    <Flex
      direction="column"
      borderWidth="1px"
      borderRadius="lg"
      p="10px"
      boxShadow="lg"
      w="100%"
      bg="white"
      gap="10px"
      className={`card ${title ? "card-enter-active" : "card-exit-active"}`}
    >
      <Text fontWeight="600" fontSize="15px">
        {title}
      </Text>
      <Flex direction="column" gap={3}>
        {data &&
          Object.entries(data).map(([key, value]: any) => {
            if (typeof value !== "string" || value === "-") return;
            return (
              <Flex key={key} direction="column" gap="4px">
                <Flex align="center" gap="3px" justify="start">
                  <Image src={BulbIcon} height="21px" width="auto" ml="-8px" />
                  <Text
                    fontWeight="600"
                    fontSize="14px"
                    textTransform="capitalize"
                  >
                    {key.replace(/_/g, " ").replace(/([a-z])([A-Z])/g, "$1 $2")}
                  </Text>
                </Flex>
                <Text fontSize="12px">{value}</Text>
              </Flex>
            );
          })}
      </Flex>
    </Flex>
  );
};

const FormInsights = ({ product }: FormInsightsProps) => {
  const parsedProduct = BEProductMap[product];
  console.log("product", product);
  console.log("parsedProduct", parsedProduct);

  const { hoveredLabel } = useStore();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const isEquipmentOption = [
    "Construction and Heavy Equipment",
    "Energy and Power Equipment",
    "Material Handling and Transport Equipment",
    "Testing and Surveying Equipment",
    "Specialized Machines",
    "Miscellaneous and Supporting Equipment",
    "Medical and Healthcare Equipment",
    "Unspecified/Other Equipment",
  ]?.includes(hoveredLabel);
  const eqipmentsCategories = data?.["Equipment Category Options"]?.[0];
  const filteredData = useMemo(() => {
    if (!hoveredLabel || hoveredLabel === null || !data) {
      return {};
    }
    const lowerCaseHoveredLabel = hoveredLabel?.toLowerCase();
    if (isEquipmentOption) {
      if (eqipmentsCategories) {
        return {
          [hoveredLabel]: eqipmentsCategories?.[hoveredLabel],
        };
      }
    }
    const matchedKey =
      data &&
      Object.keys(data).find(
        (key) => key?.toLowerCase() === lowerCaseHoveredLabel
      );
    if (matchedKey) {
      return {
        [matchedKey]: data[matchedKey],
      };
    }

    return {};
  }, [data, hoveredLabel]);

  function fetchInsights() {
    setData({});
    setIsLoading(true);
    const encodedProductName = encodeURIComponent(parsedProduct);
    console.log("encodedProductName", encodedProductName);

    useFetch
      .get(`api/product-insights/?product_type=${encodedProductName}`)
      .then((res) => {
        setData(res.data?.details);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    if (parsedProduct) {
      fetchInsights();
    }
  }, [product, parsedProduct]);
  return (
    <Flex
      direction="column"
      padding="40px 25px 20px"
      flex={1}
      bg="black"
      gap="40px"
      maxW="460px"
      minH="100vh"
      height="100%"
    >
      <Flex align="center" gap="19px">
        <Image src={SanbotLogo} alt="sanbot" height="60px" width="60px" />
        <Flex direction="column" gap="5px">
          <Text fontSize="24px" fontWeight="500" color="white">
            SanBotAI:{" "}
          </Text>
          <Text
            fontSize="24px"
            fontWeight="500"
            color="rgba(246, 246, 246, 0.60)"
          >
            Bid Intelligence{" "}
          </Text>
        </Flex>
      </Flex>
      <Flex align="center" w="100%" gap="6px">
        <Text
          fontSize="16px"
          fontWeight="600"
          color="rgba(246, 246, 246, 0.60)"
        >
          Product:
        </Text>
        <Text fontSize="16px" fontWeight="500" color="white">
          {product}
        </Text>
      </Flex>
      {isLoading ? (
        <Skeleton w="260px" height="350px" borderRadius="16px" />
      ) : (
        <Flex direction="column" gap="20px">
          {filteredData &&
            Object.keys(filteredData).map((key) => (
              <Card
                key={key}
                title={key}
                data={isEquipmentOption ? eqipmentsCategories[key] : data[key]}
              />
            ))}
        </Flex>
      )}
    </Flex>
  );
};

export default FormInsights;
