import React, { useEffect, useState } from "react";
import ElectronicStep3 from "./ElectronicStep3";
import ElectronicStep2 from "./ElectronicStep2";
import ElectronicStep1 from "./ElectronicStep1";
import { useStore } from "../../../store/store";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { BEProductMap } from "../../../partials/utils/helper";
import { useToast } from "@chakra-ui/react";
import { changeSattusToDraft } from "../Forms";

const ElectronicEquipmentInsurance = ({
  product,
  editData,
  mode,
  selectedCompany,
  createdBid,
  setCreatedBid,
  formStep,
  setFormStep,
}: any) => {
  const toast = useToast();
  const { step, setStep } = useStore();
  const [formValues, setFormValues] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleBack = () => {
    if (formStep === 1) setStep(step - 1);
    else setFormStep(formStep - 1);
  };
  const handleNext = (data: any) => {
    if (formStep < 3) setFormStep(formStep + 1);
    else {
      setIsSubmitting(true);
      setTimeout(() => {
        const formData = new FormData();
        for (const [key, value] of Object.entries(data)) {
          if (value) {
            formData.append(key, value.toString());
          }
        }
        const parsedProduct = BEProductMap[product];

        if (parsedProduct) {
          formData.append("product", parsedProduct);
        } else {
          formData.append("product", product);
        }
        formData.append("comp_id", selectedCompany.toString() || "");

        formData.append("form_properties", JSON.stringify({ ...data }));
        if (editData?.id) {
          setIsSubmitting(true);
          useFetch
            .put(
              `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((res) => {
              setCreatedBid(res.data?.data?.id);
              toast({
                title: `Bid updated successfully`,
                description: "Bid will be in draft till confirmation",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              setStep(5);
            })
            .catch((error) => {
              console.log(error);
              toast({
                title: error.response.data?.detail || "Something Went wrong",
                description: "",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            })
            .finally(() => setIsSubmitting(false));
        } else {
          setIsSubmitting(true);
          useFetch
            .post(`/api/${selectedCompany}/store-bid/`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              setCreatedBid(res.data?.id);
              toast({
                title: `Bid created successfully`,
                description: "Bid will be in draft till confirmation",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              setStep(5);
            })
            .catch((error) => {
              console.log(error);
              toast({
                title: error.response.data?.detail || "Something Went wrong",
                description: "",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            })
            .finally(() => setIsSubmitting(false));
        }
      }, 1000);
    }
  };
  useEffect(() => {
    if (editData?.id) {
      setFormValues(editData);
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);

  return (
    <>
      {formStep === 1 && (
        <ElectronicStep1
          mode={mode}
          handleBack={handleBack}
          handleNext={handleNext}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      )}
      {formStep === 2 && (
        <ElectronicStep2
          mode={mode}
          handleBack={handleBack}
          handleNext={handleNext}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      )}
      {formStep === 3 && (
        <ElectronicStep3
          mode={mode}
          handleBack={handleBack}
          handleNext={handleNext}
          formValues={formValues}
          setFormValues={setFormValues}
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
};

export default ElectronicEquipmentInsurance;
