export const todayDate = new Date()
  .toLocaleDateString("en-CA")
  .replace(/\//g, "-");
export const theme = {
  color: {
    NAVY_BLUE: "rgba(62, 57, 84, 1)",
    GRAY_FONT: "rgb(131 128 128 / 81%)",
    GRAY_LABEL: "rgba(8, 8, 8, 0.50)",
    GREEN: "rgba(29, 191, 115, 1)",
    YELLOW: "#F7990B",
    WHITE: "rgba(246, 246, 246, 1)",
    INPUT_FONT: "#2C3C4B",
  },
};

export const ProductsList = [
  "Burglary",
  "Commercial General Liability Insurance",
  "Contractors All Risk",
  "Contractors Plant and Machinery",
  "Directors & Offficers Insurance",
  "Employees/Workmen Compensation",
  "Group Health Insurance",
  "Group Personal Accident",
  "Group Term Life",
  "Lift Liability",
  "Money",
  "Professional Indemnity Insurance",
  "Specific Marine Insurance",
  "Standard Fire",
  "Electronic Equipment Insurance",
  // "Standard Fire and Special Peril Policy - Annual",
];
export const productMapping: Record<string, string> = {
  GMC: "Group Mediclaim",
  GTL: "Group Term Life",
  GPA: "Group Personal Accident",
  GHI: "Group Health Insurance",
  "Standard Fire": "Standard Fire",
  Marine: "Specific Marine Insurance",
  "Lift Liability": "Lift Liability",
  "Employee Compensation": "Employees/Workmen Compensation",
  "Workmen Compensation": "Employees/Workmen Compensation",
  "Directors & Officers Insurance": "Directors & Offficers Insurance",
  "Contractor Plant and Machinery": "Contractors Plant and Machinery",
  "Contractors All Risk": "Contractors All Risk",
  "Commercial General Liability": "Commercial General Liability Insurance",
  Burglary: "Burglary",
  "Professional Indemnity": "Professional Indemnity Insurance",
  "Professional Indemnity Insurance": "Professional Indemnity Insurance",
  "Electronic Equipment": "Electronic Equipment Insurance",
};
export const reverseProductMaping: Record<string, string> = {
  "Group Term Life": "GTL",
  "Group Personal Accident": "GPA",
  "Standard Fire": "Standard Fire",
  "Specific Marine Insurance": "Marine",
  "Lift Liability": "Lift Liability",
  "Employees/Workmen Compensation": "Workmen Compensation",
  "Directors & Offficers Insurance": "Directors & Officers Insurance",
  "Contractors Plant and Machinery": "Contractor Plant and Machinery",
  "Contractors All Risk": "Contractors All Risk",
  "Commercial General Liability Insurance": "Commercial General Liability",
  Burglary: "Burglary",
  "Professional Indemnity Insurance": "Professional Indemnity",
  "Electronic Equipment Insurance": "Electronic Equipment Insurance",
};
