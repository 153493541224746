import {
  Flex,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ProductsList } from "../../partials/utils/Contants";
import MoneyForm from "./MoneyForm/MoneyForm";
import BurglaryForm from "./BurglaryForm/BurglaryForm";
import { useStore } from "../../store/store";
import GroupTermLife from "./GroupTermLife/GroupTermLife";
import GroupHealthInsurance from "./GroupHealthInsurance";
import MarineForm from "./MarineForm";
import { MarineList } from "../../partials/utils/BidCreation";

import ProfessionalIndemnity from "./ProfessionalIndemnity";
import GroupPersonalAccident from "./GroupPersonalAccident";
import LiftLiability from "./LiftLiability";
import StandardFire from "./StandardFire";
import WorkmenCompensation from "./WorkmenCompensation";
import DirectorsOfficerInsurance from "./DirectorsOfficersInsurance";
import ContractosAllRisks from "./ContractorsAllRisks";
import CommercialLiability from "./CommercialLiability";
import ContractorssPlantMachinery from "./ContractorsPlantMachinery";
import "./form.css";
import { ModeProps } from "../../pages/CreateBid";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import ElectronicEquipmentInsurance from "./ElectronicEquipmentInsurance";
type FormsProps = {
  editData?: any;
  mode?: ModeProps;
  createdBid: any;
  setCreatedBid: any;
  product: string;
  setProduct: (product: any) => void;
};
export const changeSattusToDraft = (
  selectedCompany: string | number,
  id: string | number
) => {
  useFetch
    .post(`api/${selectedCompany}/change-status/?bid_id=${id}`)
    .then((res) => {})
    .catch((error) => {
      console.log("error", error);
    });
};
const Forms = ({
  editData,
  mode,
  createdBid,
  setCreatedBid,
  product,
  setProduct,
}: FormsProps) => {
  const [formStep, setFormStep] = useState(1);
  const { selectedCategories, selectedCompany } = useStore();
  useEffect(() => {
    setProduct(selectedCategories[0]);
  }, [selectedCategories]);

  const renderProductForm = () => {
    const isMarineProduct = MarineList.some(
      (marine) => marine.title === product
    );

    if (isMarineProduct) {
      setProduct("Specific Marine Insurance");
      // return <MarineForm product={product} />;
    } else if (product === "Employees/Workmen Compensation Insurance") {
      setProduct("Employees/Workmen Compensation");
    } else if (product === "Commercial General Liability") {
      setProduct("Commercial General Liability Insurance");
    } else if (product === "Employees/Workmen Compensation Insurance") {
      setProduct("Employees/Workmen Compensation");
    } else if (product === "Commercial General Liability") {
      setProduct("Commercial General Liability Insurance");
    }
    switch (product) {
      case "Burglary":
        return (
          <BurglaryForm
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Commercial General Liability Insurance":
        return (
          <CommercialLiability
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Contractors All Risk":
        return (
          <ContractosAllRisks
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Contractors Plant and Machinery":
        return (
          <ContractorssPlantMachinery
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Directors & Offficers Insurance":
        return (
          <DirectorsOfficerInsurance
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Employees/Workmen Compensation":
        return (
          <WorkmenCompensation
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Group Health Insurance":
        return (
          <GroupHealthInsurance
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Group Personal Accident":
        return (
          <GroupPersonalAccident
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Group Term Life":
        return (
          <GroupTermLife
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Lift Liability":
        return (
          <LiftLiability
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Money":
        return (
          <MoneyForm
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Professional Indemnity Insurance":
        return (
          <ProfessionalIndemnity
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Specific Marine Insurance":
        return (
          <MarineForm
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Standard Fire":
        return (
          <StandardFire
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );
      case "Electronic Equipment Insurance":
        return (
          <ElectronicEquipmentInsurance
            formStep={formStep}
            setFormStep={setFormStep}
            product={product}
            editData={editData}
            mode={mode}
            selectedCompany={selectedCompany}
            createdBid={createdBid}
            setCreatedBid={setCreatedBid}
          />
        );

      default:
        return null;
    }
  };
  useEffect(() => {
    if (editData) {
      setProduct(editData?.product);
    }
  }, [editData]);

  return (
    <Flex
      direction="column"
      gap="20px"
      w="100%"
      height="100%"
      maxH="calc(80vh - 40px)"
      // maxH="100%"
      overflow="auto"
      className="hide-scrollbar"
    >
      {formStep === 1 && (
        <FormControl w="100%">
          <FormLabel me="3px" color="rgba(35,28,99,.7)" fontWeight="600">
            Product *
          </FormLabel>

          <Select
            bg="white"
            borderRadius="21px"
            value={product}
            onChange={(e) => setProduct(e.target.value)}
            w="100%"
          >
            {ProductsList.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </Select>
        </FormControl>
      )}
      {renderProductForm()}
    </Flex>
  );
};

export default Forms;
