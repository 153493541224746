import * as XLSX from "xlsx";
import {
  Box,
  FormControl,
  Input,
  Select,
  Radio,
  RadioGroup,
  Checkbox,
  Button,
  Text,
  VStack,
  HStack,
  Flex,
  useToast,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { FaCloudDownloadAlt, FaInfoCircle } from "react-icons/fa";
import { useStore } from "../../../store/store";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { useEffect, useState } from "react";
import { BEProductMap, flattenObject } from "../../../partials/utils/helper";
import { changeSattusToDraft } from "../Forms";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";
import "../form.css";
interface InsuranceFormValues {
  insured: string;
  Want_to_cover_parents: string;
  employeenumber: number;
  spousenumber: number;
  kidsnumber: number;
  policytype: string;
  insurer: string;
  comp_location: string;
  coverage_type: string;
  coverage_type_name_other?: string;
  sum_insured?: number;
  base_sum_insured: string | number;
  base_sum_insured_other?: string;
  Age_Limit: number;
  payment_mode: string;
  previous_insurer?: string; // Optional
  previous_policy_expiry?: string; // Optional
  Special_conditions?: string;
  Payout_Options: string;
  Earning_Spouse: any;
  Terminal_illness_benefit: any;
  v_a_sum_assured: any;
  Group_Term_in_lieu_of_EDLI: any;
  Single_rate_benefit: any;
  Sum_Assured_Reset_Benefit: any;
  Profit_sharing: any;
  start_date: string;
  end_date: string;
  policy_start_date: string;
  policy_end_date: string;
  expected_premium: number;
  claim_data: File;
  emp_data: FileList | File;
}

const GroupTermLife = ({
  product,
  editData,
  mode,
  selectedCompany,
  setCreatedBid,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toast = useToast();
  const { step, setStep, setHoveredLabel, resetHoveredLabel } = useStore();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<InsuranceFormValues>({
    defaultValues: {
      coverage_type: "Flat cover",
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
      Age_Limit: 60,
    },
  });
  const onSubmit = async (data: any) => {
    if (!watch("emp_data")) {
      setError("emp_data", { message: "Employee Information is required" });
      return;
    }
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });
    formData.append("comp_id", selectedCompany.toString() || "");
    const parsedProduct = BEProductMap[product];

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }

    formData.append("form_properties", JSON.stringify({ ...data }));
    try {
      setIsSubmitting(true);
      if (editData?.id) {
        const response = await useFetch.put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setCreatedBid(response.data?.data?.id);
        toast({
          title: `Bid updated successfully`,
          description: "Bid will be in draft till confirmation",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setStep(5);
      } else {
        const response = await useFetch.post(
          `/api/${selectedCompany}/store-bid/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setCreatedBid(response.data?.id);
        toast({
          title: `Bid ${editData?.id ? "updated" : "created"} successfully`,
          description: "Bid will be in draft till confirmation",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setStep(5);
      }
      // Use axios to post the FormData
    } catch (error: any) {
      console.error("Error:", error);

      toast({
        title: error?.response?.data?.detail || "Something Went wrong",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof InsuranceFormValues, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) return;
    setValue("emp_data", file);
    clearErrors("emp_data");
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>): void => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });

      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      let totalSumInsured = 0;
      let employeeCount = 0;

      // Loop through all rows in the JSON data
      jsonData.forEach((row: any) => {
        const relationShip = row?.Relationship;
        const sumInsured = row?.["Sum Insured"];

        if (relationShip === "Employee" && sumInsured && !isNaN(sumInsured)) {
          totalSumInsured += parseFloat(sumInsured);
          employeeCount++;
        }

        if (relationShip === "Employee") {
          setValue("insured", "Employee");
        } else if (relationShip === "Spouse") {
          setValue("insured", "Employee & Spouse");
        } else if (relationShip === "Kids") {
          setValue("insured", "Employee ,Spouse & Kids");
        }
      });
      setValue("coverage_type", "Graded Cover");
      setValue("employeenumber", employeeCount);
      setValue("base_sum_insured", totalSumInsured);
      // setValue("sum_insured", totalSumInsured);
    };

    reader.readAsArrayBuffer(file);
  };
  const baseSumInsured = +watch("base_sum_insured");
  const employeeNumber = +watch("employeenumber");
  useEffect(() => {
    if (!watch("emp_data") || watch("coverage_type") !== "Flat Cover") {
      const sum = baseSumInsured * employeeNumber;
      setValue("sum_insured", sum);
    }
  }, [baseSumInsured, employeeNumber]);
  console.log("wa", watch("emp_data"));

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack gap={4} align="stretch">
          {/* Insured */}
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <FormControl id="insured" isInvalid={Boolean(errors?.insured)}>
              <HoverableFormLabel>
                Whom do you want to insure?*
              </HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("insured", {
                  required: "Insured is required",
                })}
                placeholder="Whom do you want to insure ?"
                onFocus={() => setHoveredLabel("Whom do you want to insure?")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="Employee">Employee</option>
                <option value="Employee & Spouse">Employee & Spouse</option>
                <option value="Employee ,Spouse & Kids">
                  Employee ,Spouse & Kids
                </option>
              </Select>
              {errors?.insured && (
                <Text color="red.500" fontSize="sm">
                  {errors?.insured?.message}
                </Text>
              )}
            </FormControl>

            {/* Want to cover parents */}
            <FormControl
              id="Want_to_cover_parents"
              isInvalid={Boolean(errors?.Want_to_cover_parents)}
            >
              <HoverableFormLabel>Want to cover parents?*</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("Want_to_cover_parents", {
                  required: "This field is required",
                })}
                defaultValue="No"
                onFocus={() => setHoveredLabel("Want to cover parents?")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </Select>
              {errors?.Want_to_cover_parents && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Want_to_cover_parents?.message}
                </Text>
              )}
            </FormControl>
            {/* Employee Count */}
            {!!watch("insured") && (
              <FormControl
                id="employeenumber"
                isInvalid={Boolean(errors?.employeenumber)}
              >
                <HoverableFormLabel>Employee Count*</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min="0"
                  defaultValue="0"
                  {...register("employeenumber", {
                    required: "Employee count is required",
                  })}
                  onFocus={() => setHoveredLabel("Employee Count")}
                  onBlur={() => resetHoveredLabel()}
                />
                {errors?.employeenumber && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.employeenumber?.message}
                  </Text>
                )}
              </FormControl>
            )}

            {/* Spouse Count */}
            {(watch("insured") === "Employee & Spouse" ||
              watch("insured") === "Employee ,Spouse & Kids") && (
              <FormControl
                id="spousenumber"
                isInvalid={Boolean(errors?.spousenumber)}
              >
                <HoverableFormLabel>
                  {`Spouse Count
            ${
              watch("insured") === "Employee & Spouse" ||
              watch("insured") === "Employee ,Spouse & Kids"
                ? "*"
                : ""
            }`}
                </HoverableFormLabel>
                <Input
                  onFocus={() => setHoveredLabel("Spouse Count")}
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min="0"
                  defaultValue="0"
                  {...register("spousenumber", {
                    required:
                      watch("insured") === "Employee & Spouse" ||
                      watch("insured") === "Employee ,Spouse & Kids"
                        ? "Spouse count is required"
                        : false,
                  })}
                />
                {errors?.spousenumber && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.spousenumber?.message}
                  </Text>
                )}
              </FormControl>
            )}

            {/* Kids Count */}
            {watch("insured") === "Employee ,Spouse & Kids" && (
              <FormControl
                id="kidsnumber"
                isInvalid={Boolean(errors?.kidsnumber)}
              >
                <HoverableFormLabel>
                  {`Kids count${
                    watch("insured") === "Employee ,Spouse & Kids" ? "*" : ""
                  }`}
                </HoverableFormLabel>
                <Input
                  onFocus={() => setHoveredLabel("Kids Count")}
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min="0"
                  defaultValue="0"
                  {...register("kidsnumber")}
                  {...register("kidsnumber", {
                    required:
                      watch("insured") === "Employee ,Spouse & Kids"
                        ? "Spouse count is required"
                        : false,
                  })}
                />
                {errors?.kidsnumber && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.kidsnumber?.message}
                  </Text>
                )}
              </FormControl>
            )}
            {/* Policy Type */}
            <FormControl
              id="policytype"
              isInvalid={Boolean(errors?.policytype)}
            >
              <HoverableFormLabel>Policy Type*</HoverableFormLabel>
              <Select
                onFocus={() => setHoveredLabel("Policy Type")}
                bg="white"
                borderRadius="21px"
                {...register("policytype", {
                  required: "Policy type is required",
                })}
                placeholder="Select policy type"
              >
                <option value="New">New</option>
                <option value="Renewal">Renewal</option>
              </Select>
              {errors?.policytype && (
                <Text color="red.500" fontSize="sm">
                  {errors?.policytype?.message}
                </Text>
              )}
            </FormControl>

            {/* Bidder Preference */}
            <FormControl id="insurer" isInvalid={Boolean(errors?.insurer)}>
              <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("insurer", {
                  required: "Bidder preference is required",
                })}
                placeholder="Preference"
                onFocus={() => setHoveredLabel("Bidder Preference")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="Both">Both</option>
                <option value="Brokers">Brokers</option>
                <option value="INS_COMP">Insurance Company</option>
              </Select>
              {errors?.insurer && (
                <Text color="red.500" fontSize="sm">
                  {errors?.insurer?.message}
                </Text>
              )}
            </FormControl>
            {/* Company Location */}
            <FormControl
              id="comp_location"
              isInvalid={Boolean(errors?.comp_location)}
            >
              <HoverableFormLabel>
                Where is your company located?*
              </HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="text"
                {...register("comp_location", {
                  required: "Company location is required",
                })}
                onFocus={() =>
                  setHoveredLabel("Where is your company located?")
                }
                onBlur={() => resetHoveredLabel()}
              />
              {errors?.comp_location && (
                <Text color="red.500" fontSize="sm">
                  {errors?.comp_location?.message}
                </Text>
              )}
            </FormControl>
            {/* Benefits Section */}
            <GridItem colSpan={2} borderTop="1px solid" mt={6} pt={4}>
              <Text fontWeight="bold" color="darkorchid">
                Benefits
              </Text>
            </GridItem>

            {/* Coverage Type */}
            <FormControl
              id="coverage_type"
              isInvalid={Boolean(errors?.coverage_type)}
            >
              <HoverableFormLabel>Coverage Type*</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("coverage_type", {
                  required: "Coverage type is required",
                })}
                placeholder="Select coverage type"
                onFocus={() => setHoveredLabel("Coverage Type")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="Flat cover">Flat cover</option>
                <option value="Graded Cover">Graded Cover</option>
                <option value="Multiple of Salary">Multiple of Salary</option>
                <option value="Others">Others</option>
              </Select>
              {errors?.coverage_type && (
                <Text color="red.500" fontSize="sm">
                  {errors?.coverage_type?.message}
                </Text>
              )}
            </FormControl>
            {watch("coverage_type") === "Others" && (
              <FormControl
                id="coverage_type_name_other"
                isInvalid={Boolean(errors?.coverage_type)}
              >
                <HoverableFormLabel>Coverage Type Name*</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="text"
                  {...register("coverage_type_name_other", {
                    required: "Other name is required",
                  })}
                  onFocus={() => setHoveredLabel("Coverage Type Name")}
                  onBlur={() => resetHoveredLabel()}
                />

                {errors?.coverage_type_name_other && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.coverage_type_name_other?.message}
                  </Text>
                )}
              </FormControl>
            )}

            {/* Sum Insured Category */}
            <FormControl
              id="base_sum_insured"
              isInvalid={
                Boolean(errors?.base_sum_insured)
                // Boolean(errors?.sum_insured)
              }
            >
              <RadioGroup defaultValue="flat">
                <HStack gap={2}>
                  <Radio
                    value="flat"
                    fontSize="10px"
                    isDisabled
                    checked={watch("coverage_type") === "Flat cover"}
                  >
                    Sum insured
                  </Radio>
                  <Radio
                    value="specific"
                    isDisabled
                    fontSize="10px"
                    checked={watch("coverage_type") !== "Flat cover"}
                  >
                    Sum insured based on category
                  </Radio>
                </HStack>
              </RadioGroup>
              {watch("coverage_type") === "Flat cover" ? (
                <HStack>
                  <Select
                    bg="white"
                    borderRadius="21px"
                    mt={4}
                    placeholder="Select employee sum insured"
                    {...register("base_sum_insured", {
                      required: "Please Select the Base Sum insured",
                    })}
                  >
                    <option value="2500000">25 Lacs</option>
                    <option value="5000000">50 Lacs</option>
                    <option value="10000000">1 Cr</option>
                    <option value="20000000">2 Cr</option>
                    <option value="Others">Others</option>
                  </Select>
                  {watch("base_sum_insured") === "Others" && (
                    <FormControl
                      isInvalid={Boolean(errors?.base_sum_insured_other)}
                    >
                      <HoverableFormLabel>Value for Others*</HoverableFormLabel>
                      <Input
                        {...register("base_sum_insured_other", {
                          required: "This field is required",
                        })}
                        borderRadius="16px"
                        background="white"
                      />
                      {errors?.base_sum_insured_other && (
                        <Text color="red.500" fontSize="sm">
                          {errors?.base_sum_insured_other?.message}
                        </Text>
                      )}
                    </FormControl>
                  )}
                </HStack>
              ) : (
                <Input
                  bg="white"
                  borderRadius="21px"
                  mt={4}
                  type="number"
                  min="0"
                  // defaultValue="60"
                  {...register("base_sum_insured")}
                  // isDisabled={true}
                />
              )}
              {errors?.base_sum_insured && (
                <Text color="red.500" fontSize="sm">
                  {errors?.base_sum_insured?.message}
                </Text>
              )}
            </FormControl>

            {/* Age Limit */}
            <FormControl id="Age_Limit" isInvalid={Boolean(errors?.Age_Limit)}>
              <HoverableFormLabel>Age Limit*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                {...register("Age_Limit", {
                  required: "Age limit is required",
                })}
                onFocus={() => setHoveredLabel("Age Limit")}
                onBlur={() => resetHoveredLabel()}
              />
              {errors?.Age_Limit && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Age_Limit?.message}
                </Text>
              )}
            </FormControl>

            {/* Payment Mode */}
            <FormControl
              id="payment_mode"
              isInvalid={Boolean(errors?.payment_mode)}
            >
              <Flex align="start">
                <HoverableFormLabel>Premium Payment Mode*</HoverableFormLabel>
                {mode === "Guided" && (
                  <FaInfoCircle
                    fontSize="11px"
                    color="gray.500"
                    title="Pay the premium using one of the following payment options: monthly, quarterly, half-yearly, or annual."
                  />
                )}
              </Flex>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("payment_mode", {
                  required: "Payment mode is required",
                })}
                placeholder="Select payment mode"
                onFocus={() => setHoveredLabel("Premium Payment Mode")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="One-time">One-time</option>
                <option value="Annual">Annual</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Monthly">Monthly</option>
              </Select>
              {errors?.payment_mode && (
                <Text color="red.500" fontSize="sm">
                  {errors?.payment_mode?.message}
                </Text>
              )}
            </FormControl>

            {/* Payout Options */}
            <FormControl
              id="Payout_Options"
              isInvalid={Boolean(errors?.Payout_Options)}
            >
              <Flex align="start">
                <HoverableFormLabel>Payout Options*</HoverableFormLabel>
                {mode === "Guided" && (
                  <FaInfoCircle
                    fontSize="11px"
                    color="gray.500"
                    title="Return of premiums is referred to as a payout option. There are two typical ways to obtain the amount: an income option, in which the nominee receives payment over a specific time period, and a lump sum amount that is given to the nominee following the policyholder's death."
                  />
                )}
              </Flex>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("Payout_Options", {
                  required: "Payout option is required",
                })}
                placeholder="Select payout option"
                onFocus={() => setHoveredLabel("Payout Options")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="Lump sum Income">Lump sum Income</option>
                <option value="Income period (In Years)">
                  Income period (In Years)
                </option>
              </Select>
              {errors?.Payout_Options && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Payout_Options?.message}
                </Text>
              )}
            </FormControl>

            {/* Special Conditions */}
            <FormControl id="Special_conditions">
              <HoverableFormLabel>
                Special conditions, if any
              </HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="text"
                {...register("Special_conditions")}
                onFocus={() => setHoveredLabel("Special conditions, if any")}
                onBlur={() => resetHoveredLabel()}
              />
            </FormControl>

            {/* Optional Benefits */}
            <GridItem colSpan={2} borderTop="1px solid" mt={6} pt={4}>
              <Text fontWeight="bold" color="darkorchid" mb="4">
                Optional Benefits
              </Text>
            </GridItem>
            <GridItem colSpan={2}>
              <HStack justify="space-evenly">
                {/* Voluntary Additional Sum Assured */}
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    {...register("v_a_sum_assured")}
                    value="Voluntary Additional Sum Assured benefit"
                  >
                    <Flex align="start">
                      <HoverableFormLabel mb="0" fontWeight="500">
                        Emplo
                      </HoverableFormLabel>
                      {mode === "Guided" && (
                        <FaInfoCircle
                          fontSize="11px"
                          color="gray.500"
                          title="Every Member covered by the Master Policy has the option to upgrade their benefits and raise their Sum Assured above what the Employer offers."
                        />
                      )}
                    </Flex>
                  </Checkbox>
                </FormControl>

                {/* Terminal Illness Benefit */}
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    {...register("Terminal_illness_benefit")}
                    value="Terminal illness benefit"
                  >
                    <Flex align="start">
                      <HoverableFormLabel mb="0" fontWeight="500">
                        Terminal illness
                      </HoverableFormLabel>
                      {mode === "Guided" && (
                        <FaInfoCircle
                          fontSize="11px"
                          color="gray.500"
                          title="When a terminal illness is diagnosed, this benefit expedites the entire Sum Assured (end stage of life)."
                        />
                      )}
                    </Flex>
                  </Checkbox>
                </FormControl>

                {/* Earning Spouse */}
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    {...register("Earning_Spouse")}
                    value="Earning Spouse"
                  >
                    <Flex align="start">
                      <HoverableFormLabel mb="0" fontWeight="500">
                        Earning Spouse
                      </HoverableFormLabel>
                      {mode === "Guided" && (
                        <FaInfoCircle
                          fontSize="11px"
                          color="gray.500"
                          title="Subject to insurable interest, the Company will extend Cover to the Member's Earning Guardian and Earning Spouse."
                        />
                      )}
                    </Flex>
                  </Checkbox>
                </FormControl>
              </HStack>
            </GridItem>
            <GridItem colSpan={2}>
              <HStack justify="space-evenly" my="3">
                {/* Group Term in lieu of EDLI */}
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    {...register("Group_Term_in_lieu_of_EDLI")}
                    value="Group Term in lieu of EDLI"
                  >
                    <Flex align="start">
                      <HoverableFormLabel mb="0" fontWeight="500">
                        Group Term in lieu of EDLI
                      </HoverableFormLabel>
                      {mode === "Guided" && (
                        <FaInfoCircle
                          fontSize="11px"
                          color="gray.500"
                          title="EDLI: The Employees Deposit Linked Insurance Scheme, also known as EDLI, is an insurance plan offered to salaried employees in the private sector who are EPFO members by the Employees Provident Fund Organization."
                        />
                      )}
                    </Flex>
                  </Checkbox>
                </FormControl>

                {/* Single Rate Benefit */}
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    {...register("Single_rate_benefit")}
                    value="Single rate benefit"
                  >
                    <Flex align="start">
                      <HoverableFormLabel mb="0" fontWeight="500">
                        Single rate
                      </HoverableFormLabel>
                      {mode === "Guided" && (
                        <FaInfoCircle
                          fontSize="11px"
                          color="gray.500"
                          title="All new members who join the group prior to the next renewal date of the policy. There is no additional fee / charge for this facility."
                        />
                      )}
                    </Flex>
                  </Checkbox>
                </FormControl>

                {/* Sum Assured Reset Benefit */}
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    {...register("Sum_Assured_Reset_Benefit")}
                    value="Sum Assured Reset Benefit"
                  >
                    <Flex align="start">
                      <HoverableFormLabel mb="0" fontWeight="500">
                        Sum Assured Reset
                      </HoverableFormLabel>
                      {mode === "Guided" && (
                        <FaInfoCircle
                          fontSize="11px"
                          color="gray.500"
                          title="Throughout the duration of the Master Policy, the Master Policyholder may raise or lower the Sum Assured for each Member."
                        />
                      )}
                    </Flex>
                  </Checkbox>
                </FormControl>

                {/* Profit Sharing */}
              </HStack>
            </GridItem>
            <FormControl display="flex" alignItems="center">
              <Checkbox {...register("Profit_sharing")} value="Profit sharing">
                <HoverableFormLabel mb="0" fontWeight="500">
                  Profit sharing
                </HoverableFormLabel>
              </Checkbox>
            </FormControl>

            <GridItem colSpan={2} borderTop="1px solid" mt={6} pt={4} />
            {/* Bid Dates */}

            {/* Bid Start Date */}
            <FormControl
              id="start_date"
              isInvalid={Boolean(errors?.start_date)}
            >
              <HoverableFormLabel>Bid's Start Date*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                min={todayDate}
                {...register("start_date", {
                  required: "Bid's start date is required",
                })}
              />
              {errors?.start_date && (
                <Text color="red.500" fontSize="sm">
                  {errors?.start_date?.message}
                </Text>
              )}
            </FormControl>

            {/* Bid End Date */}
            <FormControl id="end_date" isInvalid={Boolean(errors?.end_date)}>
              <HoverableFormLabel>Bid's End Date*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                min={watch("start_date")}
                {...register("end_date", {
                  required: "Bid's end date is required",
                })}
              />
              {errors?.end_date && (
                <Text color="red.500" fontSize="sm">
                  {errors?.end_date?.message}
                </Text>
              )}
            </FormControl>

            {/* Policy Start Date */}
            <FormControl id="policy_start_date">
              <HoverableFormLabel>Policy Start Date</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                min={todayDate}
                {...register("policy_start_date")}
              />
            </FormControl>

            {/* Policy End Date */}
            <FormControl id="policy_end_date">
              <HoverableFormLabel>Policy End Date</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                min={watch("policy_start_date")}
                {...register("policy_end_date")}
              />
            </FormControl>

            {/* Anticipated Premium */}
            <FormControl
              id="expected_premium"
              isInvalid={Boolean(errors?.expected_premium)}
            >
              <Flex align="start">
                <HoverableFormLabel> Anticipated premium*</HoverableFormLabel>
                {mode === "Guided" && (
                  <FaInfoCircle
                    fontSize="11px"
                    color="gray.500"
                    title="Anticipated premium for acquiring this policy."
                  />
                )}
              </Flex>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                defaultValue="0"
                {...register("expected_premium", {
                  required: "Anticipated premium is required",
                })}
              />
              {errors?.expected_premium && (
                <Text color="red.500" fontSize="sm">
                  {errors?.expected_premium?.message}
                </Text>
              )}
            </FormControl>

            {/* Total Coverages */}
            <FormControl
              id="sum_insured"
              isInvalid={Boolean(errors?.sum_insured)}
            >
              <HoverableFormLabel>Total coverages*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                defaultValue="0"
                {...register("sum_insured", {
                  required: "Total coverages is required",
                })}
              />
              {errors?.sum_insured && (
                <Text color="red.500" fontSize="sm">
                  {errors?.sum_insured?.message}
                </Text>
              )}
            </FormControl>

            {/* Claim Data */}
            <Flex align="center" gap={2}>
              <FormControl
                id="claim_data"
                flex={1}
                isInvalid={!!errors?.claim_data}
              >
                <HoverableFormLabel>{`Claim Data of last year${
                  watch("policytype") === "Renewal" ? "*" : ""
                }`}</HoverableFormLabel>
                <Controller
                  control={control}
                  name="claim_data"
                  rules={
                    watch("policytype") === "Renewal"
                      ? { required: "Claim data file is required" }
                      : {}
                  }
                  render={({ field: { value, onChange, ...field } }) => (
                    <Input
                      {...field}
                      type="file"
                      id="claim_data"
                      borderRadius="21px"
                      onChange={(event) => {
                        const file = event.target.files
                          ? event.target.files[0]
                          : null;
                        onChange(file);
                      }}
                    />
                  )}
                />
                {errors?.claim_data && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.claim_data?.message}
                  </Text>
                )}
              </FormControl>
              <Flex flex={1}>
                <Button
                  as="a"
                  href="https://d1un9qwa1edhdg.cloudfront.net/claim_data/Claim+format+(1).xlsx"
                  download
                  leftIcon={<FaCloudDownloadAlt />}
                  colorScheme="green"
                  bg="green.500"
                  borderRadius="21px"
                  size="sm"
                >
                  <Text>Sample File</Text>
                </Button>
              </Flex>
            </Flex>

            {/* Employee Data */}
            <Flex align="center" gap={2}>
              <FormControl
                id="emp_data"
                flex={2}
                isInvalid={!!errors?.emp_data}
              >
                <HoverableFormLabel>Employee Information*</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="file"
                  pt="5px"
                  accept=".xlsx, .xls, .csv"
                  onFocus={() => setHoveredLabel("Employee Information")}
                  onChange={handleFileUpload}
                />
                {errors?.emp_data && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.emp_data?.message}
                  </Text>
                )}
              </FormControl>
              <FormControl flex={1}>
                <Button
                  as="a"
                  href="https://d1un9qwa1edhdg.cloudfront.net/member-data-template/MemberData.xlsx"
                  download
                  leftIcon={<FaCloudDownloadAlt />}
                  colorScheme="green"
                  bg="green.500"
                  borderRadius="21px"
                  size="sm"
                >
                  <Text>Sample File</Text>
                </Button>
              </FormControl>
            </Flex>

            {/* Previous Insurer and Policy Expiry */}
            {watch("policytype") === "Renewal" && (
              <>
                {/* Previous Insurer */}
                <FormControl
                  id="previous_insurer"
                  isInvalid={Boolean(errors?.previous_insurer)}
                >
                  <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
                  <Input
                    bg="white"
                    borderRadius="21px"
                    {...register("previous_insurer", {
                      required: "Previous insurer is required",
                    })}
                  />
                  {errors?.previous_insurer && (
                    <Text color="red.500" fontSize="sm">
                      {errors?.previous_insurer?.message}
                    </Text>
                  )}
                </FormControl>

                {/* Previous Policy Expiry */}
                <FormControl
                  id="previous_policy_expiry"
                  isInvalid={Boolean(errors?.previous_policy_expiry)}
                >
                  <HoverableFormLabel>
                    Previous policy expiry*
                  </HoverableFormLabel>
                  <Input
                    bg="white"
                    borderRadius="21px"
                    type="date"
                    {...register("previous_policy_expiry", {
                      required: "Previous policy expiry is required",
                    })}
                  />
                  {errors?.previous_policy_expiry && (
                    <Text color="red.500" fontSize="sm">
                      {errors?.previous_policy_expiry?.message}
                    </Text>
                  )}
                </FormControl>
              </>
            )}
          </Grid>
          <Flex w="100%" justify="space-between" align="center" mt="20px">
            <Button
              type="button"
              borderRadius="21px"
              onClick={() => setStep(step - 1)}
            >
              Previous
            </Button>
            <Button
              type="submit"
              borderRadius="21px"
              colorScheme="green"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Next
            </Button>
          </Flex>
        </VStack>
      </form>
    </Box>
  );
};

export default GroupTermLife;
