import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import {
  Box,
  Button,
  FormControl,
  Input,
  Select,
  Radio,
  RadioGroup,
  Stack,
  Text,
  HStack,
  Flex,
  useToast,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { useStore } from "../../../store/store";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { BEProductMap, flattenObject } from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
import { changeSattusToDraft } from "../Forms";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";
interface FormValues {
  insured: string;
  Want_to_cover_parents: string;
  employeenumber: number;
  spousenumber: number;
  kidsnumber: number;
  policytype: string;
  insurer: string;
  comp_location: string;
  sum_insured_category: string;
  base_sum_insured: string;
  Maternity_Benefits: string;
  Maternity_Benefits_other?: string;
  Pre_defined_room_rent_charges: string;
  room_rent_charges_other?: string;
  Cover_Pre_existing_Diseases: string;
  Cover_Pre_existing_Diseases_other?: string;
  Diseases_wise_capping: string;
  Ambulance_Charges: number;
  Age_Band: string;
  Age_Band_start: string;
  Age_Band_end: string;
  waiting_period: string;
  Hospitalisation_Expenses: string;
  New_born_baby: string;
  Day_care_procedure: string;
  Terrorism: string;
  Domiciliary_Hospitalisation: string;
  AYUSH_Treatment: string;
  Treatment_for_correction_of_eyesight: string;
  Pre_and_Post_Natal_expenses: string;
  Cataract_Surgery: string;
  Service_Category: string;
  start_date: string;
  end_date: string;
  policy_start_date?: string;
  policy_end_date?: string;
  expected_premium: number;
  sum_insured: number;
  claim_data?: File;
  emp_data: FileList | File;
  Deductible_Amount: number;
  previous_insurer?: string;
  previous_policy_expiry?: string;
}

const GroupHealthInsurance = ({
  product,
  editData,
  mode,
  selectedCompany,
  setCreatedBid,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { step, setStep, setHoveredLabel, resetHoveredLabel } = useStore();
  const toast = useToast();
  const {
    handleSubmit,
    control,
    watch,
    register,
    setValue,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      Age_Band_start: "0",
      Age_Band_end: "18",
      waiting_period: "Waived Off",
      Hospitalisation_Expenses: "Covered for 30 & 60 days respectively",
      New_born_baby: "Covered from day 1",
      Day_care_procedure: "Covered as per the standard list",
      Terrorism: "Covered under the policy",
      Domiciliary_Hospitalisation: "Included",
      AYUSH_Treatment: "Covered under the policy",
      Treatment_for_correction_of_eyesight:
        "if power of eye is above +/- 7.5, is payable",
      Pre_and_Post_Natal_expenses: "Covered within the maternity limit",
      Cataract_Surgery: "Up to 25% of Sum Insured, Per eye",
      Service_Category: "Both Cashless & Reimbursement",
      Ambulance_Charges: 1000,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
    },
  });

  const onSubmit = async (data: any) => {
    if (!watch("emp_data")) {
      setError("emp_data", { message: "Employee Information is required" });
      return;
    }
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });
    formData.append("comp_id", selectedCompany.toString() || "");
    const parsedProduct = BEProductMap[product];

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }

    // formData.append("form_properties", JSON.stringify({ ...data }));
    const { emp_data, Age_Band_start, Age_Band_end, ...restData } = data;
    const formProperties = {
      ...restData,
      Age_Band: `${Age_Band_start}-${Age_Band_end}`,
      emp_data: emp_data
        ? {
            name: emp_data.name,
            type: emp_data.type,
            size: emp_data.size,
          }
        : undefined,
    };

    formData.append("form_properties", JSON.stringify(formProperties));
    try {
      setIsSubmitting(true);
      if (editData?.id) {
        const response = await useFetch.put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setCreatedBid(response.data?.data?.id);
        toast({
          title: `Bid updated successfully`,
          description: "Bid will be in draft till confirmation",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setStep(5);
      } else {
        const response = await useFetch.post(
          `/api/${selectedCompany}/store-bid/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setCreatedBid(response.data?.id);
        toast({
          title: `Bid ${editData?.id ? "updated" : "created"} successfully`,
          description: "Bid will be in draft till confirmation",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setStep(5);
      }
    } catch (error: any) {
      console.error("Error:", error);

      toast({
        title: error?.response?.data?.detail || "Something Went wrong",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      if (flattenedData.Age_Band) {
        const [Age_Band_start, Age_Band_end] =
          flattenedData.Age_Band.split("-");
        setValue("Age_Band_start", Age_Band_start);
        setValue("Age_Band_end", Age_Band_end);
      }
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormValues, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);
  const baseSumInsured = +watch("base_sum_insured");
  const employeeNumber = +watch("employeenumber");

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files ? e.target.files[0] : null;

    if (!file) return;
    setValue("emp_data", file);
    clearErrors("emp_data");
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>): void => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });

      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      let totalSumInsured = 0;
      let employeeCount = 0;

      jsonData.forEach((row: any) => {
        const relationShip = row?.Relationship;
        const sumInsured = row?.["Sum Insured"];

        if (relationShip === "Employee" && sumInsured && !isNaN(sumInsured)) {
          totalSumInsured += parseFloat(sumInsured);
          employeeCount++;
        }

        if (relationShip === "Employee") {
          setValue("insured", "Employee");
        } else if (relationShip === "Spouse") {
          setValue("insured", "Employee & Spouse");
        } else if (relationShip === "Kids") {
          setValue("insured", "Employee ,Spouse & Kids");
        }
      });
      setValue("sum_insured_category", "specific");
      setValue("employeenumber", employeeCount);
      setValue("base_sum_insured", "0");
      setValue("sum_insured", totalSumInsured);
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (!watch("emp_data") || watch("sum_insured_category") !== "specific") {
      const sum = baseSumInsured * employeeNumber;
      setValue("sum_insured", sum);
    }
  }, [baseSumInsured, employeeNumber]);
  useEffect(() => {
    setValue("base_sum_insured", "0");
  }, [watch("sum_insured_category")]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", flexDirection: "column", gap: "10px" }}
    >
      {/* Insured */}
      <Flex direction="column">
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <FormControl id="insured" isInvalid={Boolean(errors?.insured)}>
            <HoverableFormLabel>
              Whom do you want to insure?*
            </HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("insured", {
                required: "Insured is required",
              })}
              placeholder="Whom do you want to insure ?"
              onFocus={() => setHoveredLabel("Whom do you want to insure?")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="Employee">Employee</option>
              <option value="Employee & Spouse">Employee & Spouse</option>
              <option value="Employee ,Spouse & Kids">
                Employee ,Spouse & Kids
              </option>
            </Select>
            {errors?.insured && (
              <Text color="red.500" fontSize="sm">
                {errors?.insured?.message}
              </Text>
            )}
          </FormControl>

          {/* Want to cover parents */}
          <FormControl
            id="Want_to_cover_parents"
            isInvalid={Boolean(errors?.Want_to_cover_parents)}
          >
            <HoverableFormLabel>Want to cover parents?*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("Want_to_cover_parents", {
                required: "This field is required",
              })}
              defaultValue="No"
              onFocus={() => setHoveredLabel("Want to cover parents?")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </Select>
            {errors?.Want_to_cover_parents && (
              <Text color="red.500" fontSize="sm">
                {errors?.Want_to_cover_parents?.message}
              </Text>
            )}
          </FormControl>

          {/* Employee Count */}
          {!!watch("insured") && (
            <FormControl
              id="employeenumber"
              isInvalid={Boolean(errors?.employeenumber)}
            >
              <HoverableFormLabel>Employee Count*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                defaultValue="0"
                {...register("employeenumber", {
                  required: "Employee count is required",
                })}
                onFocus={() => setHoveredLabel("Employee Count")}
                onBlur={() => resetHoveredLabel()}
              />
              {errors?.employeenumber && (
                <Text color="red.500" fontSize="sm">
                  {errors?.employeenumber?.message}
                </Text>
              )}
            </FormControl>
          )}

          {/* Spouse Count */}
          {(watch("insured") === "Employee & Spouse" ||
            watch("insured") === "Employee ,Spouse & Kids") && (
            <FormControl
              id="spousenumber"
              isInvalid={Boolean(errors?.spousenumber)}
            >
              <HoverableFormLabel>
                {`Spouse Count
            ${
              watch("insured") === "Employee & Spouse" ||
              watch("insured") === "Employee ,Spouse & Kids"
                ? "*"
                : ""
            }`}
              </HoverableFormLabel>
              <Input
                onFocus={() => setHoveredLabel("Spouse Count")}
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                defaultValue="0"
                {...register("spousenumber", {
                  required:
                    watch("insured") === "Employee & Spouse" ||
                    watch("insured") === "Employee ,Spouse & Kids"
                      ? "Spouse count is required"
                      : false,
                })}
              />
              {errors?.spousenumber && (
                <Text color="red.500" fontSize="sm">
                  {errors?.spousenumber?.message}
                </Text>
              )}
            </FormControl>
          )}

          {/* Kids Count */}
          {watch("insured") === "Employee ,Spouse & Kids" && (
            <FormControl
              id="kidsnumber"
              isInvalid={Boolean(errors?.kidsnumber)}
            >
              <HoverableFormLabel>
                {`Kids count${
                  watch("insured") === "Employee ,Spouse & Kids" ? "*" : ""
                }`}
              </HoverableFormLabel>
              <Input
                onFocus={() => setHoveredLabel("Kids Count")}
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                defaultValue="0"
                {...register("kidsnumber")}
                {...register("kidsnumber", {
                  required:
                    watch("insured") === "Employee ,Spouse & Kids"
                      ? "Spouse count is required"
                      : false,
                })}
              />
              {errors?.kidsnumber && (
                <Text color="red.500" fontSize="sm">
                  {errors?.kidsnumber?.message}
                </Text>
              )}
            </FormControl>
          )}
          {/* Policy Type */}
          <FormControl id="policytype" isInvalid={Boolean(errors?.policytype)}>
            <HoverableFormLabel>Policy Type*</HoverableFormLabel>
            <Select
              onFocus={() => setHoveredLabel("Policy Type")}
              bg="white"
              borderRadius="21px"
              {...register("policytype", {
                required: "Policy type is required",
              })}
              placeholder="Select policy type"
            >
              <option value="New">New</option>
              <option value="Renewal">Renewal</option>
            </Select>
            {errors?.policytype && (
              <Text color="red.500" fontSize="sm">
                {errors?.policytype?.message}
              </Text>
            )}
          </FormControl>

          {/* Bidder Preference */}
          <FormControl id="insurer" isInvalid={Boolean(errors?.insurer)}>
            <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
            <Select
              onFocus={() => setHoveredLabel("Bidder Preference")}
              bg="white"
              borderRadius="21px"
              {...register("insurer", {
                required: "Bidder preference is required",
              })}
              placeholder="Preference"
            >
              <option value="Both">Both</option>
              <option value="Brokers">Brokers</option>
              <option value="INS_COMP">Insurance Company</option>
            </Select>
            {errors?.insurer && (
              <Text color="red.500" fontSize="sm">
                {errors?.insurer?.message}
              </Text>
            )}
          </FormControl>
          {/* Company Location */}
          <FormControl
            id="comp_location"
            isInvalid={Boolean(errors?.comp_location)}
          >
            <HoverableFormLabel>
              Where is your company located?*
            </HoverableFormLabel>
            <Input
              onFocus={() => setHoveredLabel("Where is your company located?")}
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("comp_location", {
                required: "Company location is required",
              })}
            />
            {errors?.comp_location && (
              <Text color="red.500" fontSize="sm">
                {errors?.comp_location?.message}
              </Text>
            )}
          </FormControl>
          <GridItem colSpan={2} borderTop="1px solid" mt={6} pt={4}>
            <Text fontWeight="bold" color="darkorchid">
              Optional coverage
            </Text>
          </GridItem>
          <FormControl isInvalid={Boolean()}>
            <HoverableFormLabel>Sum insured</HoverableFormLabel>
            <Controller
              name="sum_insured_category"
              control={control}
              rules={{ required: "This field is required" }}
              defaultValue="flat"
              render={({ field }) => (
                <RadioGroup {...field}>
                  <Stack direction="row">
                    <Radio value="flat">Sum insured</Radio>
                    <Radio value="specific">
                      Sum insured based on category
                    </Radio>
                  </Stack>
                </RadioGroup>
              )}
            />
            {watch("sum_insured_category") === "specific" ? (
              <Box style={{ marginTop: "7px" }}>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min={0}
                  defaultValue={0}
                  {...register("base_sum_insured")}
                  isDisabled={true}
                />
                {errors?.base_sum_insured && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.base_sum_insured?.message}
                  </Text>
                )}
              </Box>
            ) : (
              <Box style={{ marginTop: "7px" }}>
                <Controller
                  name="base_sum_insured"
                  rules={{ required: "This field is required" }}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      bg="white"
                      borderRadius="21px"
                      {...field}
                      isDisabled={watch("sum_insured_category") === "specific"}
                    >
                      <option value="" disabled>
                        Select employee sum insured
                      </option>
                      <option value="100000">100000</option>
                      <option value="200000">200000</option>
                      <option value="300000">300000</option>
                      <option value="400000">400000</option>
                      <option value="500000">500000</option>
                    </Select>
                  )}
                />
                {errors?.base_sum_insured && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.base_sum_insured?.message}
                  </Text>
                )}
              </Box>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Maternity_Benefits)}>
            <Flex align="start">
              <HoverableFormLabel>Maternity Benefits*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="It covers the costs of childbirth-related procedures and treatments (including miscarriages and complications after delivery)."
                />
              )}
            </Flex>
            <Controller
              name="Maternity_Benefits"
              control={control}
              rules={{ required: "This field is required" }}
              defaultValue=""
              render={({ field }) => (
                <Select
                  onFocus={() => setHoveredLabel("Maternity Benefits")}
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Select Medical expenses for normal & C-section deliveries"
                >
                  <option value="No Maternity benefit">
                    No Maternity benefit
                  </option>
                  <option value="For normal Rs.25,000, C section Rs.35,000">
                    For normal Rs.25,000, C section Rs.35,000
                  </option>
                  <option value="For normal Rs.35,000, C section Rs.50,000">
                    For normal Rs.35,000, C section Rs.50,000
                  </option>
                  <option value="For normal Rs.50,000 for both">
                    For normal Rs.50,000 for both
                  </option>
                  <option value="Others">Others</option>
                </Select>
              )}
            />
            {errors?.Maternity_Benefits && (
              <Text color="red.500" fontSize="sm">
                {errors?.Maternity_Benefits?.message}
              </Text>
            )}
          </FormControl>
          {watch("Maternity_Benefits") === "Others" && (
            <FormControl isInvalid={Boolean(errors?.Maternity_Benefits_other)}>
              <HoverableFormLabel>Value for Others*</HoverableFormLabel>
              <Input
                {...register("Maternity_Benefits_other", {
                  required: "This field is required",
                })}
                borderRadius="16px"
                background="white"
              />
              {errors?.Maternity_Benefits_other && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Maternity_Benefits_other?.message}
                </Text>
              )}
            </FormControl>
          )}

          <FormControl
            isInvalid={Boolean(errors?.Pre_defined_room_rent_charges)}
          >
            <Flex align="start">
              <HoverableFormLabel>Room rent*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Room Rent charges mean expenses incurred on accommodation in a Hospital Room during the course of treatment for a Disease or Injury."
                />
              )}
            </Flex>

            <Controller
              name="Pre_defined_room_rent_charges"
              control={control}
              rules={{ required: "This field is required" }}
              defaultValue=""
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  onFocus={() => setHoveredLabel("Room rent")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="" disabled>
                    Select Room rent limit
                  </option>
                  <option value="1% of Sum insured">1% of Sum insured</option>
                  <option value="2% of Sum insured">2% of Sum insured</option>
                  <option value="Others">Others</option>
                </Select>
              )}
            />
            {errors?.Pre_defined_room_rent_charges && (
              <Text color="red.500" fontSize="sm">
                {errors?.Pre_defined_room_rent_charges?.message}
              </Text>
            )}
          </FormControl>
          {watch("Pre_defined_room_rent_charges") === "Others" && (
            <FormControl isInvalid={Boolean(errors?.room_rent_charges_other)}>
              <HoverableFormLabel>Value for Others*</HoverableFormLabel>
              <Input
                {...register("room_rent_charges_other", {
                  required: "This field is required",
                })}
                borderRadius="16px"
                background="white"
              />
              {errors?.room_rent_charges_other && (
                <Text color="red.500" fontSize="sm">
                  {errors?.room_rent_charges_other?.message}
                </Text>
              )}
            </FormControl>
          )}
          <FormControl isInvalid={Boolean(errors?.Cover_Pre_existing_Diseases)}>
            <Flex align="start">
              <HoverableFormLabel>
                Cover Pre-existing Diseases*
              </HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Pre-existing Disease means any condition, ailment, injury or disease that is diagnosed prior to issuing health insurance policy."
                />
              )}
            </Flex>
            <Controller
              name="Cover_Pre_existing_Diseases"
              control={control}
              rules={{ required: "This field is required" }}
              defaultValue=""
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Select Pre-existing Disease Coverage"
                  onFocus={() => setHoveredLabel("Cover Pre-existing Diseases")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="Cover from day 1">Cover from day 1</option>
                  <option value="Do not cover">Do not cover </option>
                  <option value="Others">Others</option>
                </Select>
              )}
            />
            {errors?.Cover_Pre_existing_Diseases && (
              <Text color="red.500" fontSize="sm">
                {errors?.Cover_Pre_existing_Diseases?.message}
              </Text>
            )}
          </FormControl>
          {watch("Cover_Pre_existing_Diseases") === "Others" && (
            <FormControl
              isInvalid={Boolean(errors?.Cover_Pre_existing_Diseases_other)}
            >
              <HoverableFormLabel>Value for Others*</HoverableFormLabel>
              <Input
                {...register("Cover_Pre_existing_Diseases_other", {
                  required: "This field is required",
                })}
                borderRadius="16px"
                background="white"
              />
              {errors?.Cover_Pre_existing_Diseases_other && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Cover_Pre_existing_Diseases_other?.message}
                </Text>
              )}
            </FormControl>
          )}

          <FormControl isInvalid={Boolean(errors?.Diseases_wise_capping)}>
            <HoverableFormLabel>Disease-wise capping*</HoverableFormLabel>
            <Controller
              name="Diseases_wise_capping"
              control={control}
              rules={{ required: "This field is required" }}
              defaultValue=""
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Select Diseases capping"
                  onFocus={() => setHoveredLabel("Disease-wise capping")}
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="No capping">No capping</option>
                  <option value="Capping">Capping </option>
                </Select>
              )}
            />
            {errors?.Diseases_wise_capping && (
              <Text color="red.500" fontSize="sm">
                {errors?.Diseases_wise_capping?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Ambulance_Charges)}>
            <HoverableFormLabel>
              Ambulance Charges (Per person)*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Ambulance_Charges", {
                required: "Ambulance Charges is required",
              })}
              onFocus={() => setHoveredLabel("Ambulance Charges (Per person)")}
              onBlur={() => resetHoveredLabel()}
            />

            {/* <Controller
           rules={{ required: "This field is required" }}
            name="Ambulance_Charges"
            control={control}
            render={({ field }) => (
              <NumberInput min={0} bg='white' borderRadius='21px'>
                <NumberInputField {...field}  borderRadius='21px'/>
              </NumberInput>
            )}
          /> */}
            {errors?.Ambulance_Charges && (
              <Text color="red.500" fontSize="sm">
                {errors?.Ambulance_Charges?.message}
              </Text>
            )}
          </FormControl>

          <GridItem colSpan={2} borderTop="1px solid" mt={6} pt={4}>
            <Text fontWeight="bold" color="darkorchid">
              Base coverage
            </Text>
          </GridItem>

          <FormControl
            isInvalid={!!(errors?.Age_Band_start || errors?.Age_Band_end)}
          >
            <Flex align="start">
              <HoverableFormLabel>Age Band*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Coverage for parents and children of employees upto the age limit."
                />
              )}
            </Flex>
            <Flex align="center">
              <Flex direction="column">
                <Input
                  type="number"
                  placeholder="Start Age"
                  maxWidth="120px"
                  bg="white"
                  borderRadius="21px"
                  {...register("Age_Band_start", {
                    required: "Start age is required",
                  })}
                  onFocus={() => setHoveredLabel("Age Band")}
                  onBlur={() => resetHoveredLabel()}
                />
                {errors?.Age_Band_start && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.Age_Band_start?.message}
                  </Text>
                )}
              </Flex>
              <Text mx="2" fontSize="lg">
                -
              </Text>
              <Flex direction="column">
                <Input
                  type="number"
                  placeholder="End Age"
                  maxWidth="120px"
                  bg="white"
                  borderRadius="21px"
                  {...register("Age_Band_end", {
                    required: "End age is required",
                  })}
                  onFocus={() => setHoveredLabel("Age Band")}
                  onBlur={() => resetHoveredLabel()}
                />
                {errors?.Age_Band_end && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.Age_Band_end?.message}
                  </Text>
                )}
              </Flex>
            </Flex>
          </FormControl>
          <FormControl isInvalid={Boolean(errors?.waiting_period)}>
            <HoverableFormLabel>
              30 Days, 9 months waiting period*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("waiting_period", {
                required: "Waiting period is required",
              })}
              onFocus={() =>
                setHoveredLabel("30 Days, 9 months waiting period")
              }
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.waiting_period && (
              <Text color="red.500" fontSize="sm">
                {errors?.waiting_period?.message}
              </Text>
            )}
          </FormControl>
          <FormControl isInvalid={Boolean(errors?.Hospitalisation_Expenses)}>
            <HoverableFormLabel>
              Pre & Post-Hospitalisation Expenses*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Hospitalisation_Expenses", {
                required: "Hospitalisation Expenses is required",
              })}
              onFocus={() =>
                setHoveredLabel("Pre & Post-Hospitalisation Expenses")
              }
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.Hospitalisation_Expenses && (
              <Text color="red.500" fontSize="sm">
                {errors?.Hospitalisation_Expenses?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.New_born_baby)}>
            <HoverableFormLabel>Newborn Baby</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("New_born_baby", {
                required: "New born baby cover is required",
              })}
              onFocus={() => setHoveredLabel("Newborn Baby")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.New_born_baby && (
              <Text color="red.500" fontSize="sm">
                {errors?.New_born_baby?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Day_care_procedure)}>
            <Flex align="start">
              <HoverableFormLabel> Day care procedure</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Medical procedures which finished in less than twenty-four hours"
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Day_care_procedure", {
                required: "Day care procedure is required",
              })}
              onFocus={() => setHoveredLabel("Day care procedure")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.Day_care_procedure && (
              <Text color="red.500" fontSize="sm">
                {errors?.Day_care_procedure?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Terrorism)}>
            <HoverableFormLabel>Terrorism*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Terrorism", {
                required: "This field is required",
              })}
              onFocus={() => setHoveredLabel("Terrorism")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.Terrorism && (
              <Text color="red.500" fontSize="sm">
                {errors?.Terrorism?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Domiciliary_Hospitalisation)}>
            <Flex align="start">
              <HoverableFormLabel>
                Domiciliary Hospitalisation
              </HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Medical treatment receives at home due to the non-availability of nearby hospital."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Domiciliary_Hospitalisation", {
                required: "Domiciliary Hospitalisation is required",
              })}
              onFocus={() => setHoveredLabel("Domiciliary Hospitalisation")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.Domiciliary_Hospitalisation && (
              <Text color="red.500" fontSize="sm">
                {errors?.Domiciliary_Hospitalisation?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.AYUSH_Treatment)}>
            <HoverableFormLabel>AYUSH Treatment*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("AYUSH_Treatment", {
                required: "Day care procedure is required",
              })}
              onFocus={() => setHoveredLabel("AYUSH Treatment")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.AYUSH_Treatment && (
              <Text color="red.500" fontSize="sm">
                {errors?.AYUSH_Treatment?.message}
              </Text>
            )}
          </FormControl>

          <FormControl
            isInvalid={Boolean(errors?.Treatment_for_correction_of_eyesight)}
          >
            <HoverableFormLabel>
              Treatment for correction of eyesight*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Treatment_for_correction_of_eyesight", {
                required: "This field is required",
              })}
              onFocus={() =>
                setHoveredLabel("Treatment for correction of eyesight")
              }
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.Treatment_for_correction_of_eyesight && (
              <Text color="red.500" fontSize="sm">
                {errors?.Treatment_for_correction_of_eyesight?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Pre_and_Post_Natal_expenses)}>
            <HoverableFormLabel>
              Pre and Post Natal expenses*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Pre_and_Post_Natal_expenses", {
                required: "Pre and Post Natal expenses is required",
              })}
              onFocus={() => setHoveredLabel("Pre and Post Natal expenses")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.Pre_and_Post_Natal_expenses && (
              <Text color="red.500" fontSize="sm">
                {errors?.Pre_and_Post_Natal_expenses?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Cataract_Surgery)}>
            <HoverableFormLabel>Cataract Surgery*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Cataract_Surgery", {
                required: "Pre and Post Natal expenses is required",
              })}
              onFocus={() => setHoveredLabel("Cataract Surgery")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.Cataract_Surgery && (
              <Text color="red.500" fontSize="sm">
                {errors?.Cataract_Surgery?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Service_Category)}>
            <HoverableFormLabel>Service Category*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Service_Category", {
                required: "Pre and Post Natal expenses is required",
              })}
              onFocus={() => setHoveredLabel("Service Category")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.Service_Category && (
              <Text color="red.500" fontSize="sm">
                {errors?.Service_Category?.message}
              </Text>
            )}
          </FormControl>

          <GridItem colSpan={2} borderTop="1px solid" mt={6} pt={4} />
          {/* Bid Dates */}

          {/* Bid Start Date */}
          <FormControl id="start_date" isInvalid={Boolean(errors?.start_date)}>
            <HoverableFormLabel>Bid's Start Date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("start_date", {
                required: "Bid's start date is required",
              })}
              onFocus={() => setHoveredLabel("Bid's Start Date")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.start_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.start_date?.message}
              </Text>
            )}
          </FormControl>

          {/* Bid End Date */}
          <FormControl id="end_date" isInvalid={Boolean(errors?.end_date)}>
            <HoverableFormLabel>Bid's End Date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch("start_date")}
              {...register("end_date", {
                required: "Bid's end date is required",
              })}
              onFocus={() => setHoveredLabel("Bid's End Date")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.end_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.end_date?.message}
              </Text>
            )}
          </FormControl>

          {/* Policy Dates */}

          {/* Policy Start Date */}
          <FormControl id="policy_start_date">
            <HoverableFormLabel>Policy Start Date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("policy_start_date")}
              onFocus={() => setHoveredLabel("Policy Start Date")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>

          {/* Policy End Date */}
          <FormControl id="policy_end_date">
            <HoverableFormLabel>Policy End Date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch("policy_start_date")}
              {...register("policy_end_date")}
              onFocus={() => setHoveredLabel("Policy End Date")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>

          {/* Anticipated Premium */}
          <FormControl
            id="expected_premium"
            isInvalid={Boolean(errors?.expected_premium)}
          >
            <Flex align="start">
              <HoverableFormLabel> Anticipated premium*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Anticipated premium for acquiring this policy."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              defaultValue="0"
              {...register("expected_premium", {
                required: "Anticipated premium is required",
              })}
              onFocus={() => setHoveredLabel("Anticipated premium")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.expected_premium && (
              <Text color="red.500" fontSize="sm">
                {errors?.expected_premium?.message}
              </Text>
            )}
          </FormControl>

          {/* Total Coverages */}
          <FormControl
            id="sum_insured"
            isInvalid={Boolean(errors?.sum_insured)}
          >
            <HoverableFormLabel>Total coverages*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              defaultValue="0"
              {...register("sum_insured", {
                required: "Total coverages is required",
              })}
              onFocus={() => setHoveredLabel("Total coverages")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.sum_insured && (
              <Text color="red.500" fontSize="sm">
                {errors?.sum_insured?.message}
              </Text>
            )}
          </FormControl>

          {/* File Uploads */}

          {/* Claim Data */}
          <Flex align="center" gap={2}>
            <FormControl
              id="claim_data"
              flex={1}
              isInvalid={!!errors?.claim_data}
            >
              <HoverableFormLabel>{`Claim Data of last year${
                watch("policytype") === "Renewal" ? "*" : ""
              }`}</HoverableFormLabel>
              <Controller
                control={control}
                name="claim_data"
                rules={
                  watch("policytype") === "Renewal"
                    ? { required: "Claim data file is required" }
                    : {}
                }
                render={({ field: { value, onChange, ...field } }) => (
                  <Input
                    {...field}
                    type="file"
                    id="claim_data"
                    borderRadius="21px"
                    onChange={(event) => {
                      const file = event.target.files
                        ? event.target.files[0]
                        : null;
                      onChange(file);
                    }}
                  />
                )}
              />
              {errors?.claim_data && (
                <Text color="red.500" fontSize="sm">
                  {errors?.claim_data?.message}
                </Text>
              )}
            </FormControl>
            <Flex flex={1}>
              <Button
                as="a"
                href="https://d1un9qwa1edhdg.cloudfront.net/claim_data/Claim+format+(1).xlsx"
                download
                leftIcon={<FaCloudDownloadAlt />}
                colorScheme="green"
                bg="green.500"
                borderRadius="21px"
                size="sm"
              >
                <Text>Sample File</Text>
              </Button>
            </Flex>
          </Flex>
          {/* Employee Data */}
          <Flex align="center" gap={2}>
            <FormControl id="emp_data" flex={2} isInvalid={!!errors?.emp_data}>
              <HoverableFormLabel>Employee Information*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="file"
                pt="5px"
                accept=".xlsx, .xls, .csv"
                onFocus={() => setHoveredLabel("Employee Information")}
                onChange={handleFileUpload}
              />
              {errors?.emp_data && (
                <Text color="red.500" fontSize="sm">
                  {errors?.emp_data?.message}
                </Text>
              )}
            </FormControl>
            <Flex flex={1}>
              <Button
                as="a"
                href="https://d1un9qwa1edhdg.cloudfront.net/member-data-template/MemberData.xlsx"
                download
                leftIcon={<FaCloudDownloadAlt />}
                colorScheme="green"
                bg="green.500"
                borderRadius="21px"
                size="sm"
              >
                <Text>Sample File</Text>
              </Button>
            </Flex>
          </Flex>

          {/* Previous Insurer and Policy Expiry */}
          {watch("policytype") === "Renewal" && (
            <>
              <FormControl
                id="previous_insurer"
                isInvalid={Boolean(errors?.previous_insurer)}
              >
                <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  {...register("previous_insurer", {
                    required: "Previous insurer is required",
                  })}
                  onFocus={() => setHoveredLabel("Previous Insurer")}
                  onBlur={() => resetHoveredLabel()}
                />
                {errors?.previous_insurer && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.previous_insurer?.message}
                  </Text>
                )}
              </FormControl>

              {/* Previous Policy Expiry */}
              <FormControl
                id="previous_policy_expiry"
                isInvalid={Boolean(errors?.previous_policy_expiry)}
              >
                <HoverableFormLabel>Previous policy expiry*</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="date"
                  {...register("previous_policy_expiry", {
                    required: "Previous policy expiry is required",
                  })}
                  onFocus={() => setHoveredLabel("Previous policy expiry")}
                  onBlur={() => resetHoveredLabel()}
                />
                {errors?.previous_policy_expiry && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.previous_policy_expiry?.message}
                  </Text>
                )}
              </FormControl>
            </>
          )}
        </Grid>

        <Flex w="100%" justify="space-between" align="center" mt="20px">
          <Button
            type="button"
            borderRadius="21px"
            onClick={() => setStep(step - 1)}
          >
            Previous
          </Button>
          <Button
            type="submit"
            borderRadius="21px"
            colorScheme="green"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default GroupHealthInsurance;
