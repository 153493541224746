import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LinkItemsBidder } from "../../../routes";
import Sidebar from "../../../components/Sidebar";
import { useFetchtoken, useFetchUser } from "../../../hooks/User";
import { useEffect, useMemo, useState } from "react";
import LoadingScreen from "../../../pages/LoadingScreen";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import Cookies from "js-cookie";

const PrivateRoutes = () => {
  const { pathname } = useLocation();
  const token = useFetchtoken();
  const user = useFetchUser();
  const isProfileComplete = useMemo(() => {
    if (token) {
      const bidderProfiling = localStorage.getItem("bidder_profiling");
      if (bidderProfiling) {
        const complete =
          JSON.parse(bidderProfiling) === "False" ||
          JSON.parse(bidderProfiling) === false
            ? false
            : true;
        return complete;
      }
    } else {
      return true;
    }
    return null;
  }, [token, pathname]);

  function fetchUerData() {
    useFetch
      .get(`api/bidder-details/`)
      .then((res) => {
        Cookies.set("user", JSON.stringify({ ...user, ...res.data?.bidder }));
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    if (!user?.company_name) {
      fetchUerData();
    }
  }, [user]);

  if (token === null) {
    return <Navigate to="/login" />;
  }

  if (isProfileComplete === null) {
    return <LoadingScreen />;
  }

  return token ? (
    isProfileComplete ? (
      <Sidebar sidebaroptions={LinkItemsBidder}>
        <Outlet />
      </Sidebar>
    ) : (
      <Navigate to="/setupProfile" />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
