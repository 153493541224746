import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { CiSearch } from "react-icons/ci";
import SocketChat from "../../components/SocketChat";
import ConversationCard from "./ConversationCard";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { useFetchUser } from "../../hooks/User";
import { productMapping } from "../../partials/utils/Contants";

export type ClientMessageData = {
  sender: string;
  message: string | null;
  file_url?: string;
  file_name?: string;
};

const Conversations = () => {
  const user = useFetchUser();
  const ws = React.useRef<WebSocket | null>(null);

  const [searchText, setSearchText] = useState("");
  const [messages, setMessages] = useState<ClientMessageData[]>([]);
  const [inputMessage, setInputMessage] = useState<string>("Hi");
  const [fileInput, setFileInput] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedBidder, setSelectedBidder] = useState<any>({
    id: null,
    bidData: {},
  });
  const [bidList, setBidList] = useState<any>([]);
  const [isFetchingBids, setIsFetchingBids] = useState(false);

  const filteredData = useMemo(
    () =>
      searchText === ""
        ? [...bidList]
        : bidList.filter((bid: any) => {
            const product = productMapping[bid?.product] || bid?.product;

            return product?.toLowerCase().includes(searchText.toLowerCase());
          }),
    [bidList, searchText]
  );

  function fetchBidList() {
    setIsFetchingBids(true);
    useFetch
      .get(`api/chat-client/`)
      .then((response) => {
        setBidList(response?.data?.bids);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsFetchingBids(false));
  }
  useEffect(() => {
    fetchBidList();
  }, []);
  useEffect(() => {
    setMessages([]);
  }, [selectedBidder]);

  return (
    <Flex height="100%" width="100%">
      <Flex
        direction="column"
        padding="30px 60px 30px 30px"
        flex={2}
        gap="10px"
      >
        <Flex align="center" w="100%" justify="space-between">
          <Text as="h2" fontSize="24px" fontWeight="600">
            Conversation
          </Text>
        </Flex>
        <InputGroup>
          <InputLeftElement>
            <CiSearch />
          </InputLeftElement>
          <Input
            borderRadius="16px"
            type="text"
            w="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            placeholder="Search"
            fontSize="12px"
            fontWeight="500"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </InputGroup>
        <Flex
          direction="column"
          w="100%"
          align="center"
          gap="12px"
          justify="center"
          minH="200px"
        >
          {isFetchingBids ? (
            <Skeleton height="110px" w="100%" borderRadius="16px" />
          ) : (
            <Flex
              direction="column"
              w="100%"
              align="center"
              gap="12px"
              overflowY="auto"
              height="calc(100dvh - 160px)"
              className="hide-scrollbar"
            >
              {filteredData?.length ? (
                filteredData?.map((item: any, index: number) => (
                  <ConversationCard
                    key={index}
                    data={item}
                    selectedBidder={selectedBidder}
                    setSelectedBidder={setSelectedBidder}
                    type="client"
                  />
                ))
              ) : (
                <>
                  <Flex minH="40vh" justify="center" align="center" w="100%">
                    <Text fontSize="16px" fontWeight="500">
                      No matching record found
                    </Text>
                  </Flex>
                </>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
      {selectedBidder?.id && (
        <SocketChat
          setSelectedBidder={setSelectedBidder}
          ws={ws}
          clientId={user?.user_id}
          bidderId={selectedBidder?.id}
          messages={messages}
          bid={selectedBidder?.bidData}
          setMessages={setMessages}
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          fileInput={fileInput}
          setFileInput={setFileInput}
          user={user}
          fileInputRef={fileInputRef}
        />
      )}
    </Flex>
  );
};

export default Conversations;
