import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  FormControl,
  Select as ChakraSelect,
  Text,
  Input,
  Button,
  Box,
  VStack,
  useDisclosure,
  Grid,
  Flex,
  GridItem,
} from "@chakra-ui/react";
import Select from "react-select";
import * as XLSX from "xlsx";

import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { useStore } from "../../../store/store";
import { FaCloudDownloadAlt } from "react-icons/fa";

type FormData = {
  clientProfession: string;
  clientProfession_other?: string;
  policytype: string;
  insurer: string;
  equipment_category: string;
  equipment_category_other?: string;
  equipment_use: string;
  equipmentList?: FileList;
  equipments: {
    equipmentMake: string;
    equipmentPrice: string;
  }[];
  isEquipmentManufacturedInIndia: string;
  repairAndMaintenanceInIndia: string;
  equipmentAge: string;
  equipmentType: string;
  previousClaim: string;
  previous_policy?: string;
  previous_insurance_company_name?: string;
};

const ElectronicStep1 = ({
  handleBack,
  handleNext,
  formValues,
  setFormValues,
}: any) => {
  const { setHoveredLabel, resetHoveredLabel } = useStore();
  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      insurer: "Both",
      policytype: "New",
      equipments: [{ equipmentMake: "", equipmentPrice: "10" }],
      previousClaim: "no",
    },
  });

  const [deviceList, setDeviceList] = useState<any>([
    { label: "3 ShapeTrios 3 Wired", value: "3 ShapeTrios 3 Wired" },
    { label: "3 ShapeTrios 4 Wireless", value: "3 ShapeTrios 4 Wireless" },
    { label: "3 ShapeTrios 5 Wireless", value: "3 ShapeTrios 5 Wireless" },
    { label: "3 ShapeE2", value: "3 ShapeE2" },
    { label: "3 ShapeE3", value: "3 ShapeE3" },
    { label: "3 ShapeE4", value: "3 ShapeE4" },
    { label: "3 ShapeF8", value: "3 ShapeF8" },
  ]);

  const handleCreateOption = (inputValue: string) => {
    if (
      inputValue &&
      !deviceList.some((option: any) => option.value === inputValue)
    ) {
      const newOption = { label: inputValue, value: inputValue };
      setDeviceList([...deviceList, newOption]);
    }
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: "equipments",
  });

  const onSubmit = (data: FormData) => {
    setFormValues({ ...formValues, ...data });
    handleNext();
  };
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files ? e.target.files[0] : null;

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>): void => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });

      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      let totalSumInsured = 0;
      let employeeCount = 0;

      jsonData.forEach((row: any) => {
        console.log("row", row);
        const equipmentMake = row?.["Make & Model"];
        if (!deviceList.some((device: any) => device.value === equipmentMake)) {
          setDeviceList((prevList: any[]) => [
            ...prevList,
            { label: equipmentMake, value: equipmentMake },
          ]);
        }
        append({
          equipmentMake: equipmentMake,
          equipmentPrice: row.Cost || 0,
        });
      });
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (formValues) {
      reset({
        ...formValues,
      });
      formValues.equipments?.forEach((equipment: any) => {
        if (
          equipment.equipmentMake &&
          !deviceList.some(
            (option: any) => option.value === equipment.equipmentMake
          )
        ) {
          setDeviceList((prevList: any) => [
            ...prevList,
            { label: equipment.equipmentMake, value: equipment.equipmentMake },
          ]);
        }
      });
    }
  }, [formValues, reset]);
  const equipmentCategory = watch("equipment_category");
  useEffect(() => {
    setHoveredLabel(equipmentCategory);
  }, [equipmentCategory]);
  return (
    <form
      onKeyDown={(e) => {
        if (e.key === "Enter") return;
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex direction="column" w="100%">
        <Box borderBottom="1px solid" mt="2px" mb="3" pb="3">
          <Text fontWeight="bold" fontSize="20px" color="darkorchid">
            Basic Info
          </Text>
        </Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          {/* Client Profession */}
          <FormControl isInvalid={Boolean(errors?.clientProfession)}>
            <HoverableFormLabel>Client Profession*</HoverableFormLabel>
            <ChakraSelect
              bg="white"
              borderRadius="21px"
              {...register("clientProfession", {
                required: "Client Profession is required",
              })}
              placeholder="Select Client Profession"
              onFocus={() => setHoveredLabel("Client Profession")}
              onBlur={() => resetHoveredLabel()}
            >
              {[
                "Doctors",
                "Medical Establishment",
                "Engineers",
                "Architechs",
                "Charted accountents",
                "Lawyers",
                "Other",
              ].map((o) => (
                <option value={o}>{o}</option>
              ))}
            </ChakraSelect>
            {errors?.clientProfession && (
              <Text color="red.500" fontSize="sm">
                {errors?.clientProfession?.message}
              </Text>
            )}
          </FormControl>
          {watch("clientProfession") === "Other" && (
            <FormControl isInvalid={Boolean(errors?.clientProfession_other)}>
              <HoverableFormLabel>Other Profession*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...register("clientProfession_other", {
                  required: "Please specify other profession",
                })}
              />
              {errors?.clientProfession_other && (
                <Text color="red.500" fontSize="sm">
                  {errors?.clientProfession_other?.message}
                </Text>
              )}
            </FormControl>
          )}

          {/* Policy Type */}
          <FormControl isInvalid={Boolean(errors?.policytype)}>
            <HoverableFormLabel>Policy Type*</HoverableFormLabel>
            <ChakraSelect
              bg="white"
              borderRadius="21px"
              {...register("policytype", {
                required: "Policy type is required",
              })}
              placeholder="Select Policy Type"
              onFocus={() => setHoveredLabel("Policy Type")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="New">New</option>
              <option value="Renewal">Renewal</option>
            </ChakraSelect>
            {errors?.policytype && (
              <Text color="red.500" fontSize="sm">
                {errors?.policytype?.message}
              </Text>
            )}
          </FormControl>
          {watch("policytype") === "Renewal" && (
            <>
              <FormControl
                id="previous_insurer"
                isInvalid={Boolean(errors?.previous_policy)}
              >
                <HoverableFormLabel>Previous Policy Number*</HoverableFormLabel>
                <Input
                  bg="white"
                  placeholder="Enter previous policy number"
                  type="number"
                  borderRadius="21px"
                  {...register("previous_policy", {
                    required: "Previous policy number is required",
                  })}
                  onFocus={() => setHoveredLabel("Previous Policy Number")}
                  onBlur={() => resetHoveredLabel()}
                />
                {errors?.previous_policy && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.previous_policy?.message}
                  </Text>
                )}
              </FormControl>

              {/* Previous Policy Expiry */}
              <FormControl
                id="previous_policy_expiry"
                isInvalid={Boolean(errors?.previous_insurance_company_name)}
              >
                <HoverableFormLabel>Previous Policy Expiry*</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="date"
                  {...register("previous_insurance_company_name", {
                    required: "Previous insurance company_name is required",
                  })}
                  onFocus={() => setHoveredLabel("Previous Policy Expiry")}
                  onBlur={() => resetHoveredLabel()}
                />
                {errors?.previous_insurance_company_name && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.previous_insurance_company_name?.message}
                  </Text>
                )}
              </FormControl>
            </>
          )}
          <FormControl isInvalid={Boolean(errors?.insurer)}>
            <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
            <ChakraSelect
              bg="white"
              borderRadius="21px"
              {...register("insurer", {
                required: "Bidder preference is required",
              })}
              placeholder="Preference"
              onFocus={() => setHoveredLabel("Bidder Preference")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="Both">Both</option>
              <option value="Brokers">Brokers</option>
              <option value="INS_COMP">Insurance Company</option>
            </ChakraSelect>
            {errors?.insurer && (
              <Text color="red.500" fontSize="sm">
                {errors?.insurer?.message}
              </Text>
            )}
          </FormControl>

          {/* Upload Equipment List */}
          {/* <FormControl>
            <HoverableFormLabel>Upload Equipment List</HoverableFormLabel>
            <Input
              type="file"
              bg="white"
              borderRadius="21px"
              {...register("equipmentList")}
            />
          </FormControl> */}
          <FormControl isInvalid={Boolean(errors?.equipment_category)}>
            <HoverableFormLabel>Equipment Category*</HoverableFormLabel>
            <ChakraSelect
              bg="white"
              borderRadius="21px"
              {...register("equipment_category", {
                required: "Equipment Category is required",
              })}
              placeholder="Category"
              onFocus={() => setHoveredLabel("Equipment Category")}
              onBlur={() => resetHoveredLabel()}
            >
              {[
                "Construction and Heavy Equipment",
                "Energy and Power Equipment",
                "Material Handling and Transport Equipment",
                "Testing and Surveying Equipment",
                "Specialized Machines",
                "Miscellaneous and Supporting Equipment",
                "Medical and Healthcare Equipment",
                "Unspecified/Other Equipment",
              ].map((o) => (
                <option value={o}>{o}</option>
              ))}
            </ChakraSelect>
            {errors?.equipment_category && (
              <Text color="red.500" fontSize="sm">
                {errors?.equipment_category?.message}
              </Text>
            )}
          </FormControl>
          {watch("equipment_category") === "Unspecified/Other Equipment" && (
            <FormControl isInvalid={Boolean(errors?.equipment_category_other)}>
              <HoverableFormLabel>Other Equipment Category*</HoverableFormLabel>
              <Input
                type="text"
                bg="white"
                borderRadius="21px"
                {...register("equipment_category_other", {
                  required: "Please specify other Equipment Category",
                })}
              />
              {errors?.equipment_category_other && (
                <Text color="red.500" fontSize="sm">
                  {errors?.equipment_category_other?.message}
                </Text>
              )}
            </FormControl>
          )}
          <FormControl isInvalid={Boolean(errors?.equipment_use)}>
            <HoverableFormLabel>Equipment Use*</HoverableFormLabel>
            <Input
              bg="white"
              type="text"
              borderRadius="21px"
              {...register("equipment_use", {
                required: "Equipment use is required",
              })}
              placeholder="Enter Equipment use"
              onFocus={() => setHoveredLabel("Equipment Use")}
              onBlur={() => resetHoveredLabel()}
            />

            {errors?.equipment_use && (
              <Text color="red.500" fontSize="sm">
                {errors?.equipment_use?.message}
              </Text>
            )}
          </FormControl>
          {/* <GridItem colSpan={2}>
            <Flex align="center" gap="10px" w="100%">
              <FormControl id="emp_data" w="50%">
                <HoverableFormLabel>Upload Equipment List</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="file"
                  pt="5px"
                  accept=".xlsx, .xls, .csv"
                  onChange={handleFileUpload}
                  onFocus={() => setHoveredLabel("Upload Equipment List")}
                  onBlur={() => resetHoveredLabel()}
                />
              </FormControl>
              <Flex align="center" gap="10px" w="50%">
                <Button
                  as="a"
                  href="https://d1un9qwa1edhdg.cloudfront.net/device/Excel+format+of+device.xlsx"
                  download
                  leftIcon={<FaCloudDownloadAlt />}
                  colorScheme="green"
                  bg="green.500"
                  borderRadius="21px"
                  size="sm"
                  minW="120px"
                >
                  <Text>Sample File</Text>
                </Button>
                <Text fontSize="10px" color="gray.500">
                  ⓘ You can either upload an Excel file with the device list or
                  manually enter the details.
                </Text>
              </Flex>
            </Flex>
          </GridItem> */}
          {/* Equipment Make */}
          <GridItem colSpan={2}>
            <Flex direction="column" w="100%">
              {fields.map((item, index) => (
                <Flex key={item.id} w="100%" gap="6" align="center">
                  <FormControl
                    isInvalid={Boolean(
                      errors?.equipments?.[index]?.equipmentMake
                    )}
                    onFocus={() => setHoveredLabel("Equipment Make")}
                    onBlur={() => resetHoveredLabel()}
                  >
                    <HoverableFormLabel>Equipment Make*</HoverableFormLabel>
                    <Controller
                      name={`equipments.${index}.equipmentMake`}
                      control={control}
                      defaultValue={item.equipmentMake}
                      rules={{
                        required: "Equipment Make is required",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={deviceList}
                          placeholder="Type and press Enter to add a new option..."
                          value={deviceList.find(
                            (option: any) => option.value === field.value
                          )}
                          onChange={(selected) =>
                            field.onChange(selected ? selected.value : "")
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              e.stopPropagation();
                              const inputValue = (e.target as HTMLInputElement)
                                .value;
                              handleCreateOption(inputValue);
                            }
                          }}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              borderRadius: "21px",
                              borderColor: errors?.equipments?.[index]
                                ?.equipmentMake
                                ? "red"
                                : provided.borderColor,
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              textAlign: "start",
                            }),
                            menu: (provided) => ({
                              ...provided,
                              borderRadius: "21px",
                              background: "white",
                              textAlign: "start",
                            }),
                            menuList: (provided) => ({
                              ...provided,
                              padding: "5px",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              fontSize: "14px",
                              textAlign: "start",
                            }),
                            option: (provided) => ({
                              ...provided,
                              fontSize: "13px",
                              padding: "5px",
                              textAlign: "start",
                            }),
                          }}
                        />
                      )}
                    />
                    {errors?.equipments?.[index]?.equipmentMake && (
                      <Text color="red.500" fontSize="sm">
                        {errors?.equipments?.[index]?.equipmentMake?.message}
                      </Text>
                    )}
                  </FormControl>

                  <Flex w="100%" align="end" gap="2">
                    <FormControl
                      isInvalid={Boolean(
                        errors?.equipments?.[index]?.equipmentPrice
                      )}
                    >
                      <HoverableFormLabel>Equipment Price*</HoverableFormLabel>
                      <Input
                        // type="number"
                        type="text"
                        min={1}
                        bg="white"
                        borderRadius="21px"
                        {...register(`equipments.${index}.equipmentPrice`, {
                          required: "Equipment Price is required",
                          valueAsNumber: true, // This ensures the value is treated as a number
                        })}
                        placeholder="Equipment Price"
                        onFocus={() => setHoveredLabel("Equipment Price")}
                        onBlur={() => resetHoveredLabel()}
                      />
                      {errors?.equipments?.[index]?.equipmentPrice && (
                        <Text color="red.500" fontSize="sm">
                          {errors?.equipments?.[index]?.equipmentPrice?.message}
                        </Text>
                      )}
                    </FormControl>
                    <Button
                      onClick={() =>
                        fields?.length - 1 === index
                          ? append({ equipmentMake: "", equipmentPrice: "10" })
                          : remove(index)
                      }
                      colorScheme={
                        fields?.length - 1 === index ? "green" : "red"
                      }
                      size="sm"
                      borderRadius="10px"
                    >
                      {fields?.length - 1 === index ? "+" : "-"}
                    </Button>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </GridItem>
          {/* Is Device Manufactured in India */}
          <FormControl
            isInvalid={Boolean(errors?.isEquipmentManufacturedInIndia)}
          >
            <HoverableFormLabel>
              Is Equipment manufacture in India ?*
            </HoverableFormLabel>
            <ChakraSelect
              bg="white"
              borderRadius="21px"
              {...register("isEquipmentManufacturedInIndia", {
                required: "This is required",
              })}
              placeholder="Select"
              onFocus={() =>
                setHoveredLabel("Is Equipment manufacture in India ?")
              }
              onBlur={() => resetHoveredLabel()}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </ChakraSelect>
            {errors?.isEquipmentManufacturedInIndia && (
              <Text color="red.500" fontSize="sm">
                {errors?.isEquipmentManufacturedInIndia?.message}
              </Text>
            )}
          </FormControl>

          {/* Repair and Maintenance Availability in India */}
          <FormControl isInvalid={Boolean(errors?.repairAndMaintenanceInIndia)}>
            <HoverableFormLabel>
              Repair and Maintenance Availability in India*
            </HoverableFormLabel>
            <ChakraSelect
              bg="white"
              borderRadius="21px"
              {...register("repairAndMaintenanceInIndia", {
                required: "This is required",
              })}
              placeholder="Select"
              onFocus={() =>
                setHoveredLabel("Repair and Maintenance Availability in India")
              }
              onBlur={() => resetHoveredLabel()}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </ChakraSelect>
            {errors?.repairAndMaintenanceInIndia && (
              <Text color="red.500" fontSize="sm">
                {errors?.repairAndMaintenanceInIndia?.message}
              </Text>
            )}
          </FormControl>

          {/* Device Age */}
          <FormControl isInvalid={Boolean(errors?.equipmentAge)}>
            <HoverableFormLabel>Equipment Age (Yrs)*</HoverableFormLabel>
            <ChakraSelect
              bg="white"
              borderRadius="21px"
              {...register("equipmentAge", {
                required: "Equipment Age is required",
              })}
              placeholder="Select Equipment Age"
              onFocus={() => setHoveredLabel("Equipment Age (Yrs)")}
              onBlur={() => resetHoveredLabel()}
            >
              {["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"].map(
                (age) => (
                  <option key={age} value={age}>
                    {age}
                  </option>
                )
              )}
            </ChakraSelect>
            {errors?.equipmentAge && (
              <Text color="red.500" fontSize="sm">
                {errors?.equipmentAge?.message}
              </Text>
            )}
          </FormControl>

          {/* Device Type */}
          <FormControl isInvalid={Boolean(errors?.equipmentType)}>
            <HoverableFormLabel>Equipment Type*</HoverableFormLabel>
            <ChakraSelect
              bg="white"
              borderRadius="21px"
              {...register("equipmentType", {
                required: "Equipment Type is required",
              })}
              placeholder="Select Equipment Type"
              onFocus={() => setHoveredLabel("Equipment Type")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="Portable">Portable</option>
              <option value="Fixed">Fixed</option>
            </ChakraSelect>
            {errors?.equipmentType && (
              <Text color="red.500" fontSize="sm">
                {errors?.equipmentType?.message}
              </Text>
            )}
          </FormControl>

          {/* Previous Claim */}
          <FormControl isInvalid={Boolean(errors?.previousClaim)}>
            <HoverableFormLabel>Previous Claim*</HoverableFormLabel>
            <ChakraSelect
              bg="white"
              borderRadius="21px"
              {...register("previousClaim", {
                required: "Previous Claim is required",
              })}
              placeholder="Select Previous Claim"
              onFocus={() => setHoveredLabel("Previous Claim")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </ChakraSelect>
            {errors?.previousClaim && (
              <Text color="red.500" fontSize="sm">
                {errors?.previousClaim?.message}
              </Text>
            )}
          </FormControl>
        </Grid>
        <Flex w="100%" justify="space-between" align="center" mt="20px">
          <Button type="button" borderRadius="21px" onClick={handleBack}>
            Previous
          </Button>
          <Button type="submit" borderRadius="21px" colorScheme="green">
            Next
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default ElectronicStep1;
