import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  VStack,
  FormControl,
  Input,
  Select,
  Flex,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useStore } from "../../../store/store";
import "../form.css";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import {
  BEProductMap,
  convertProductName,
  flattenObject,
} from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
import { changeSattusToDraft } from "../Forms";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";

type FormValues = {
  Type_of_Company: string;
  Company_name_other?: string;
  company_Nature: string;
  policytype: string;
  insurer: string;
  comp_location: string;
  Lifts_Address: string;
  Lift_usage_type: string;
  Dimension: string;
  Maker_name: string;
  Motive_power?: string;
  HP: string;
  Carrying_Capacity: number;
  Method_of_contract: string;
  Operated_by_Employee: string;
  Number_of_floor_sevred: number;
  Date_of_Installation: string;
  Has_any_company_decline: string;
  contract_for_Maintanance_and_Inspection: string;
  intervals_do_engineers: string;
  breakdown_occurred?: string;
  Claim_Experience: string;
  AOA: number;
  AOY: number;
  start_date: string;
  end_date: string;
  policy_start_date?: string;
  policy_end_date?: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer?: string;
  previous_policy_expiry?: string;
};

const LiftLiability = ({
  product,
  editData,
  mode,
  selectedCompany,
  setCreatedBid,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { step, setStep, setHoveredLabel, resetHoveredLabel } = useStore();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      Carrying_Capacity: 0,
      Number_of_floor_sevred: 0,
      AOA: 0,
      AOY: 0,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
      expected_premium: 0,
      sum_insured: 0,
    },
  });

  const onSubmit = (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    formData.append("comp_id", selectedCompany.toString() || "");
    const parsedProduct = BEProductMap[product];

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }

    formData.append("form_properties", JSON.stringify({ ...data }));
    if (editData?.id) {
      setIsSubmitting(true);
      useFetch
        .put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData
        )
        .then((res) => {
          setCreatedBid(res.data?.data?.id);
          toast({
            title: `Bid updated successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      setIsSubmitting(true);
      useFetch
        .post(`/api/${selectedCompany}/store-bid/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setCreatedBid(res.data?.id);
          toast({
            title: `Bid created successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    }
  };
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormValues, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);
  const AOA = +watch("AOA");
  const AOY = +watch("AOY");

  useEffect(() => {
    const sum = AOA + AOY;
    setValue("sum_insured", sum);
  }, [AOA, AOY]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.Type_of_Company)}>
          <HoverableFormLabel>Type of Company*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Select Company Type"
            {...register("Type_of_Company", {
              required: "Type of Company is required",
            })}
            onFocus={() => setHoveredLabel("Type of Company")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Private">Private</option>
            <option value="Public">Public</option>
            <option value="Government">Government</option>
            <option value="Non-Profit">Non-Profit</option>
            <option value="Others">Others</option>
          </Select>
          {errors?.Type_of_Company && (
            <Text color="red.500" fontSize="sm">
              {errors?.Type_of_Company?.message}
            </Text>
          )}
        </FormControl>

        {watch("Type_of_Company") === "Others" && (
          <FormControl isInvalid={Boolean(errors.Company_name_other)}>
            <HoverableFormLabel>Company Type Name*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Company_name_other", {
                required: "Please specify the name",
              })}
              onFocus={() => setHoveredLabel("Company Type Name")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.Company_name_other && (
              <Text color="red.500" fontSize="sm">
                {errors?.Company_name_other?.message}
              </Text>
            )}
          </FormControl>
        )}
        <FormControl isInvalid={Boolean(errors.company_Nature)}>
          <HoverableFormLabel>
            Nature of operation of the company*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("company_Nature", {
              required: "Nature of operation is required",
            })}
            onFocus={() =>
              setHoveredLabel("Nature of operation of the company")
            }
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.company_Nature && (
            <Text color="red.500" fontSize="sm">
              {errors?.company_Nature?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.policytype)}>
          <HoverableFormLabel>Policy Type*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Select policy type"
            {...register("policytype", {
              required: "Policy Type is required",
            })}
            onFocus={() => setHoveredLabel("Policy Type")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="New">New</option>
            <option value="Renewal">Renewal</option>
          </Select>
          {errors?.policytype && (
            <Text color="red.500" fontSize="sm">
              {errors?.policytype?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors.insurer)}>
          <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Preference"
            {...register("insurer", {
              required: "Bidder Preference is required",
            })}
            onFocus={() => setHoveredLabel("Bidder Preference")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Both">Both</option>
            <option value="Brokers">Brokers</option>
            <option value="INS_COMP">Insurance Company</option>
          </Select>
          {errors?.insurer && (
            <Text color="red.500" fontSize="sm">
              {errors?.insurer?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.comp_location)}>
          <HoverableFormLabel>
            Where is your company located?*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("comp_location", {
              required: "Company location is required",
            })}
            onFocus={() => setHoveredLabel("Where is your company located?")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.comp_location && (
            <Text color="red.500" fontSize="sm">
              {errors?.comp_location?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Lifts_Address)}>
          <HoverableFormLabel>
            Address where Lifts are situated*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Lifts_Address", {
              required: "Lifts Address is required",
            })}
            onFocus={() => setHoveredLabel("Address where Lifts are situated")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.Lifts_Address && (
            <Text color="red.500" fontSize="sm">
              {errors?.Lifts_Address?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <Box borderTop="1px solid " mt={4} pt={4}>
        <Text fontWeight="bold" color="darkorchid" mb="4">
          Optional Benefits
        </Text>
      </Box>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.Lift_usage_type)}>
          <HoverableFormLabel>Lift usage type*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Lift usage type"
            {...register("Lift_usage_type", {
              required: "Lift usage type is required",
            })}
            onFocus={() => setHoveredLabel("Lift usage type")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Passengers">Passengers</option>
            <option value="Goods">Goods</option>
          </Select>
          {errors?.Lift_usage_type && (
            <Text color="red.500" fontSize="sm">
              {errors?.Lift_usage_type?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Dimension)}>
          <HoverableFormLabel>Dimension*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Dimension", {
              required: "Dimension is required",
            })}
            onFocus={() => setHoveredLabel("Dimension")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.Dimension && (
            <Text color="red.500" fontSize="sm">
              {errors?.Dimension?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.Maker_name)}>
          <HoverableFormLabel>Maker’s Name*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Maker_name", {
              required: "Maker's name is required",
            })}
            onFocus={() => setHoveredLabel("Maker’s Name")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.Maker_name && (
            <Text color="red.500" fontSize="sm">
              {errors?.Maker_name?.message}
            </Text>
          )}
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Motive power</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Motive_power")}
            onFocus={() => setHoveredLabel("Motive power")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.HP)}>
          <HoverableFormLabel>If electric, H.P. of motor*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("HP", {
              required: "H.P. of motor is required",
            })}
            onFocus={() => setHoveredLabel("If electric, H.P. of motor")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.HP && (
            <Text color="red.500" fontSize="sm">
              {errors?.HP?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Carrying_Capacity)}>
          <HoverableFormLabel>Carrying Capacity*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min="0"
            {...register("Carrying_Capacity", {
              required: "Carrying Capacity is required",
            })}
            onFocus={() => setHoveredLabel("Carrying Capacity")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.Carrying_Capacity && (
            <Text color="red.500" fontSize="sm">
              {errors?.Carrying_Capacity?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <Box borderTop="1px solid " mt={4} pt={4}>
        <Text fontWeight="bold" color="darkorchid" mb="4">
          Special Conditions
        </Text>
      </Box>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.Method_of_contract)}>
          <Flex align="start">
            <HoverableFormLabel>Method of contract*</HoverableFormLabel>

            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Operation and maintenance of the lift"
              />
            )}
          </Flex>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Method of contract"
            {...register("Method_of_contract", {
              required: "Method of contract is required",
            })}
            onFocus={() => setHoveredLabel("Method of contract")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Rope">Rope</option>
            <option value="Switch">Switch</option>
            <option value="Button">Button</option>
            <option value="lever">Lever</option>
            <option value="Other">Other</option>
          </Select>
          {errors?.Method_of_contract && (
            <Text color="red.500" fontSize="sm">
              {errors?.Method_of_contract?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Operated_by_Employee)}>
          <HoverableFormLabel>Operated by Employee*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Operated by Employee"
            {...register("Operated_by_Employee", {
              required: "Operated by Employee is required",
            })}
            onFocus={() => setHoveredLabel("Operated by Employee")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
          {errors?.Operated_by_Employee && (
            <Text color="red.500" fontSize="sm">
              {errors?.Operated_by_Employee?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.Number_of_floor_sevred)}>
          <HoverableFormLabel>Number of floors served*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min="0"
            {...register("Number_of_floor_sevred", {
              required: "Number of floors served is required",
            })}
            onFocus={() => setHoveredLabel("Number of floors served")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.Number_of_floor_sevred && (
            <Text color="red.500" fontSize="sm">
              {errors?.Number_of_floor_sevred?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Date_of_Installation)}>
          <HoverableFormLabel>Date of Installation*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            {...register("Date_of_Installation", {
              required: "Date of Installation is required",
            })}
            onFocus={() => setHoveredLabel("Date of Installation")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.Date_of_Installation && (
            <Text color="red.500" fontSize="sm">
              {errors?.Date_of_Installation?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.Has_any_company_decline)}>
          <HoverableFormLabel>
            Has any company declined to Insure*
          </HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Has any company decline to Insure"
            {...register("Has_any_company_decline", {
              required: "This field is required",
            })}
            onFocus={() =>
              setHoveredLabel("Has any company declined to Insure")
            }
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
          {errors?.Has_any_company_decline && (
            <Text color="red.500" fontSize="sm">
              {errors?.Has_any_company_decline?.message}
            </Text>
          )}
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.contract_for_Maintanance_and_Inspection)}
        >
          <HoverableFormLabel>
            Contract for maintenance and inspection*
          </HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Contract for maintenance and inspection"
            {...register("contract_for_Maintanance_and_Inspection", {
              required: "This field is required",
            })}
            onFocus={() =>
              setHoveredLabel("Contract for maintenance and inspection")
            }
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
          {errors?.contract_for_Maintanance_and_Inspection && (
            <Text color="red.500" fontSize="sm">
              {errors?.contract_for_Maintanance_and_Inspection?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.intervals_do_engineers)}>
          <HoverableFormLabel>
            At what intervals do engineers carry out inspections?*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            placeholder="In number of days"
            {...register("intervals_do_engineers", {
              required: "This field is required",
            })}
            onFocus={() =>
              setHoveredLabel(
                "At what intervals do engineers carry out inspections?"
              )
            }
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.intervals_do_engineers && (
            <Text color="red.500" fontSize="sm">
              {errors?.intervals_do_engineers?.message}
            </Text>
          )}
        </FormControl>

        <FormControl>
          <HoverableFormLabel>
            Has any breakdown occurred? If so, give details
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("breakdown_occurred")}
            onFocus={() =>
              setHoveredLabel("Has any breakdown occurred? If so, give details")
            }
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.Claim_Experience)} w="50%">
          <HoverableFormLabel>
            Claim Experience for past 3 years*
          </HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Claim Experience"
            {...register("Claim_Experience", {
              required: "This field is required",
            })}
            onFocus={() => setHoveredLabel("Claim Experience for past 3 years")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
          {errors?.Claim_Experience && (
            <Text color="red.500" fontSize="sm">
              {errors?.Claim_Experience?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <Box borderTop="1px solid" mt={4} pt={4}>
        <FormControl>
          <HoverableFormLabel color="darkorchid">
            Limit of liability
          </HoverableFormLabel>
        </FormControl>
      </Box>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.AOA)}>
          <Flex align="start">
            <HoverableFormLabel>AOA (Any One Accident)*</HoverableFormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Maximum payment amount for a single policy-covered an accident or incident"
              />
            )}
          </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min="0"
            {...register("AOA", {
              required: "AOA is required",
            })}
            onFocus={() => setHoveredLabel("AOA (Any One Accident)")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.AOA && (
            <Text color="red.500" fontSize="sm">
              {errors?.AOA?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors.AOY)}>
          <Flex align="start">
            <HoverableFormLabel>AOY (Any One Year)*</HoverableFormLabel>

            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Maximum payout amount that the insurance provider will make for claims filed in a calendar year"
              />
            )}
          </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min="0"
            {...register("AOY", {
              required: "AOY is required",
            })}
            onFocus={() => setHoveredLabel("AOY (Any One Year)")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.AOY && (
            <Text color="red.500" fontSize="sm">
              {errors?.AOY?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.start_date)}>
          <HoverableFormLabel>Bid's Start Date*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={todayDate}
            {...register("start_date", {
              required: "Start Date is required",
            })}
            onFocus={() => setHoveredLabel("Bid's Start Date")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl isInvalid={Boolean(errors.end_date)}>
          <HoverableFormLabel>Bid's End Date*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={watch("start_date")}
            {...register("end_date", {
              required: "End Date is required",
            })}
            onFocus={() => setHoveredLabel("Bid's End Date")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl>
          <HoverableFormLabel>Policy Start Date</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={todayDate}
            {...register("policy_start_date")}
            onFocus={() => setHoveredLabel("Policy Start Date")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Policy End Date</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={watch("policy_start_date")}
            {...register("policy_end_date")}
            onFocus={() => setHoveredLabel("Policy End Date")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors.expected_premium)}>
          <Flex align="start">
            <HoverableFormLabel>Anticipated premium*</HoverableFormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Anticipated premium for acquiring this policy."
              />
            )}
          </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min="0"
            {...register("expected_premium", {
              required: "Expected premium is required",
            })}
            onFocus={() => setHoveredLabel("Anticipated premium")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl isInvalid={Boolean(errors.sum_insured)}>
          <HoverableFormLabel>Limit of Liability*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min="0"
            {...register("sum_insured", {
              required: "Limit of Liability is required",
            })}
            onFocus={() => setHoveredLabel("Limit of Liability")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>
      </HStack>

      {watch("policytype") === "Renewal" && (
        <HStack gap={4}>
          {/* Previous Insurer */}
          <FormControl
            id="previous_insurer"
            isInvalid={Boolean(errors?.previous_insurer)}
          >
            <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("previous_insurer", {
                required: "Previous insurer is required",
              })}
              onFocus={() => setHoveredLabel("Previous Insurer")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.previous_insurer && (
              <Text color="red.500" fontSize="sm">
                {errors?.previous_insurer?.message}
              </Text>
            )}
          </FormControl>

          {/* Previous Policy Expiry */}
          <FormControl
            id="previous_policy_expiry"
            isInvalid={Boolean(errors?.previous_policy_expiry)}
          >
            <HoverableFormLabel>Previous policy expiry*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              {...register("previous_policy_expiry", {
                required: "Previous policy expiry is required",
              })}
              onFocus={() => setHoveredLabel("Previous policy expiry")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.previous_policy_expiry && (
              <Text color="red.500" fontSize="sm">
                {errors?.previous_policy_expiry?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
      )}

      <Flex w="100%" justify="space-between" align="center" mt="20px">
        <Button
          type="button"
          borderRadius="21px"
          onClick={() => setStep(step - 1)}
        >
          Previous
        </Button>
        <Button
          type="submit"
          borderRadius="21px"
          colorScheme="green"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Next
        </Button>
      </Flex>
    </form>
  );
};

export default LiftLiability;
