import { useEffect, useRef, useState } from "react";
import { IoCloudDownloadOutline } from "react-icons/io5";
import {
  Button,
  Flex,
  InputGroup,
  Input,
  InputLeftElement,
  SimpleGrid,
  Text,
  Spinner,
} from "@chakra-ui/react";
import DocumentThumbnail from "../../components/Thumbnails/DocumentThumbnail";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { useLocation, useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";

import { productMapping } from "../../partials/utils/Contants";
import { useStore } from "../../store/store";
import { BEProductMap } from "../../partials/utils/helper";
import BackButtton from "../../components/BackButton";
import toast from "react-hot-toast";

const DefaultVaultListing = () => {
  const { selectedCompany } = useStore();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const fetchData = useFetch;
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [id, setId] = useState<any>();
  const [name, setName] = useState<any>("");
  const [searchQuery, setSearchQuery] = useState("");

  const [defaultList, setDefaultList] = useState<any[]>([]);

  useEffect(() => {
    const fetchListing = async () => {
      setIsLoading(true);
      const searchParams = new URLSearchParams(search);
      const idValue = searchParams.get("id");
      setId(idValue);
      const nameURL = searchParams.get("name");
      setName(nameURL);
      if (nameURL) {
        fetchListingData(nameURL);
      }
    };

    fetchListing();
  }, [search, selectedCompany]);
  function fetchListingData(name: string) {
    const product = encodeURIComponent(BEProductMap[name] || name);

    fetchData(`api/${selectedCompany}/folder-details/?product=${product}`)
      .then((response) => {
        setIsLoading(false);
        setDefaultList(response?.data?.bid_folders);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };
  const handleClick = (id: string, name: string) => {
    if (
      name?.toLocaleLowerCase() === "mandate" ||
      name?.toLocaleLowerCase() === "rfq"
    ) {
      const toastId = toast.loading("Downloading...");
      setIsDownloading(true);
      fetchData
        .post(`api/${selectedCompany}/download-folder/?folder_id=${id}`)
        .then((res) => {
          const downloadUrl = res.data?.download_url;
          const link = document.createElement("a");
          link.href = downloadUrl;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          toast.success("Downloaded Successfully", { id: toastId });
        })
        .catch((error) => {
          console.log("error", error.response.data);
          toast.error(error.response.data?.error || "Something Went wrong", {
            id: toastId,
          });
        })
        .finally(() => setIsDownloading(false));
    } else {
      navigate(
        `/document-vault/default-files?id=${id}&name=${name.replace("_", " ")}`
      );
    }
  };
  const handleDownloadBidFolder = async (id: number) => {
    setIsDownloading(true);
    const toastId = toast.loading("Downloading...");

    useFetch
      .post(`api/${selectedCompany}/download-folder/?bid_id=${id}`)
      .then((res) => {
        const downloadUrl = res.data?.download_url;
        const link = document.createElement("a");
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success("Downloaded Successfully", { id: toastId });
      })
      .catch((error) => {
        console.log("error", error.response.data);

        toast.error(error.response.data?.error || "Something Went wrong", {
          id: toastId,
        });
      })
      .finally(() => setIsDownloading(false));
  };
  const handleDownloadZipFolder = (id: string, name?: string) => {
    setIsDownloading(true);
    const toastId = toast.loading("Downloading...");

    useFetch
      .post(`api/${selectedCompany}/download-folder/?folder_id=${id}`)
      .then((res) => {
        const downloadUrl = res.data?.download_url;
        const link = document.createElement("a");
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success("Downloaded Successfully", { id: toastId });
      })
      .catch((error) => {
        console.log("error", error.response.data);
        toast.error(error.response.data?.error || "Something Went wrong", {
          id: toastId,
        });
      })
      .finally(() => setIsDownloading(false));
  };
  const handleDownloadZipAll = (name: string) => {
    const toastId = toast.loading("Downloading...");
    setIsDownloading(true);
    const product = BEProductMap[name] || name;

    useFetch
      .get(
        `api/${selectedCompany}/download-all-folder/?product_name=${product}`
      )
      .then((res) => {
        const downloadUrl = res.data?.download_url;
        const link = document.createElement("a");
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        toast.success("Downloaded Successfully", { id: toastId });
      })
      .catch((error) => {
        console.log("error", error.response.data);
        toast.error(error.response.data?.error || "Something Went wrong", {
          id: toastId,
        });
      })
      .finally(() => setIsDownloading(false));
  };
  const handleDelete = (id: number, file: string) => {
    const toastId = toast.loading("Deleting Document...");

    const body = {
      id,
      file,
    };
    useFetch
      .post(`default-documets/delete`, body)
      .then((res) => {
        toast.success("Deleted Successfully", { id: toastId });
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.response.data?.error || "Something Went wrong", {
          id: toastId,
        });
      });
  };
  const DownloadAndShare = async (id: number) => {
    setIsDownloading(true);
    try {
      const response = await useFetch.post(
        `api/${selectedCompany}/download-folder/?folder_id=${id}`
      );
      const downloadUrl = response.data?.download_url;
      const fileResponse = await fetch(downloadUrl);
      const fileBlob = await fileResponse.blob();
      const file = new File([fileBlob], "folder.zip", {
        type: "application/zip",
      });
      localStorage.setItem("zipFolder", JSON.stringify(file));
      const fileExist = localStorage.getItem("zipFolder");

      if (fileExist) {
        const fileBlob = new Blob([JSON.parse(fileExist)], {
          type: "application/zip",
        });
        const fileUrl = URL.createObjectURL(fileBlob);
        const mailtoLink = `mailto:?subject=Shared ZIP Folder&body=Here is the ZIP folder you requested. Download it from this link: ${fileUrl}`;
        window.location.href = mailtoLink;
      } else {
        alert(
          "No folder available to share. Please download the folder first."
        );
      }
    } catch (error) {
      console.error("Download error:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const filteredDefaultList = defaultList
    ?.filter((d: any) => String(d.bid_id)?.includes(searchQuery))
    .sort((a, b) => b?.bid_id - a?.bid_id);

  return (
    <Flex
      direction="column"
      w="100%"
      gap="30px"
      padding="35px"
      pb="60px"
      overflow="auto"
      maxH="calc(100dvh - 20px)"
      height="100%"
    >
      <Flex w="100%" justify="space-between" align="center">
        <Flex align="center" gap="12px">
          <BackButtton />
          <Text fontSize="30px" fontWeight="500" textTransform="capitalize">
            {productMapping[name] || name}
          </Text>
        </Flex>
        <Flex align="center" gap="20px">
          <Button
            borderRadius="21px"
            bg="black"
            size="sm"
            color="white"
            colorScheme="black"
            _hover={{ filter: "opacity(0.75)" }}
            leftIcon={<IoCloudDownloadOutline />}
            //   onClick={onOpen}
            //   ref={btnRef}
            fontSize="0.9rem"
            fontWeight="500"
            onClick={() => navigate(`/claim-form/${name}`)}
          >
            Claim Form
          </Button>
          <Button
            borderRadius="21px"
            bg="black"
            size="sm"
            color="white"
            colorScheme="black"
            _hover={{ filter: "opacity(0.75)" }}
            leftIcon={<IoCloudDownloadOutline />}
            fontSize="0.9rem"
            fontWeight="500"
            onClick={() => handleDownloadZipAll(name)}
          >
            Zip all
          </Button>
        </Flex>
      </Flex>
      <Flex position="relative" w="100%">
        <InputGroup>
          <InputLeftElement>
            <CiSearch />
          </InputLeftElement>
          <Input
            borderRadius="16px"
            type="text"
            w="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            placeholder="Search using bid number..."
            fontSize="12px"
            fontWeight="500"
            value={searchQuery}
            onChange={handleSearch}
          />
        </InputGroup>
      </Flex>
      <Flex w="100%" justify="space-between">
        <Text fontSize="24px" fontWeight="500">
          Files
        </Text>
        {/* <Menu>
          <MenuButton
            as={Button}
            rightIcon={<IoIosArrowDown />}
            borderRadius="16px"
            bg="white"
            border="1px solid #dadada"
            _active={{
              bg: "black",
              color: "white",
              border: "1px solid black",
            }}
          >
            Filter
          </MenuButton>
          <MenuList
            bg="black"
            color="white"
            padding="25px 5px 15px 15px"
            gap="20px"
            borderRadius="16px"
          >
            <Text p="0px 10px 10px 15px">Filter by:</Text>
            {["By Entity", "By Policy", "By Bid"].map((item) => (
              <MenuItem
                bg="black"
                color="rgba(246, 246, 246, 0.50)"
                fontSize="14px"
                fontWeight="400"
              >
                <Flex align="center" justify="space-between" gap="8px" w="100%">
                  <Text fontSize="14px" fontWeight="500">
                    {item}
                  </Text>
                  <Icon as={IoIosArrowForward} fontSize="12px" />
                </Flex>
              </MenuItem>
            ))}
          </MenuList>
        </Menu> */}
      </Flex>
      {!isLoading && defaultList?.length ? (
        <>
          {filteredDefaultList ? (
            <Flex direction="column" w="100%" gap="20px">
              {filteredDefaultList?.map((d: any) => (
                <Flex direction="column" w="100%" gap="20px">
                  <Flex align="center" w="100%" justify="space-between">
                    <Text fontSize="20px" fontWeight="600" color="blue.800">
                      Request #{d?.bid_id}
                    </Text>
                    <Button
                      borderRadius="21px"
                      bg="black"
                      // size="sm"
                      color="white"
                      colorScheme="black"
                      _hover={{ filter: "opacity(0.75)" }}
                      leftIcon={<IoCloudDownloadOutline />}
                      fontSize="0.9rem"
                      fontWeight="500"
                      onClick={() => handleDownloadBidFolder(d?.bid_id)}
                    >
                      Zip
                    </Button>
                  </Flex>
                  <SimpleGrid minChildWidth="245px" gap="24px" w="100%">
                    {d?.folders?.map((item: any, index: number) => (
                      <DocumentThumbnail
                        key={index}
                        title={item?.folder_name?.replace(/_/g, " ")}
                        fileCount={item?.total_files}
                        data={item}
                        type="default-listing"
                        handleClick={handleClick}
                        handleDownload={handleDownloadZipFolder}
                        handleDelete={handleDelete}
                        id={item?.folder_id}
                        // handleShare={DownloadAndShare}
                      />
                    ))}
                    {/* <Flex
            w="244px"
            height="180px"
            borderRadius="12px"
            border="1px dotted #6E6E6E"
            padding="16px"
            gap="20px"
            direction="column"
            align="center"
            color={theme.color.WHITE}
            bg="black"
            onClick={handleUploadClick}
            cursor="pointer"
          >
            <input
              ref={fileInputRef}
              style={{ display: "none" }}
              type="file"
              onChange={handleFileChange}
              accept="*"
            />
            <Text fontWeight="500" fontSize="16px">
              Upload Files
            </Text>
            <Icon as={IoCloudDownloadOutline} fontSize="28px" />
            <Text fontWeight="400" fontSize="10px" textAlign="center">
              Click to browse or drag and drop your files here to upload. Max
              file size: 25 MB.{" "}
            </Text>
          </Flex> */}
                  </SimpleGrid>
                </Flex>
              ))}
            </Flex>
          ) : (
            <Flex height="30vh" align="end" justify="center">
              <Text>No matching record found</Text>
            </Flex>
          )}
        </>
      ) : (
        <Flex
          direction="column"
          w="100%"
          align="center"
          gap="12px"
          justify="center"
          minH="200px"
        >
          {isLoading ? <Spinner /> : <Text>No Folders Available</Text>}
        </Flex>
      )}
    </Flex>
  );
};

export default DefaultVaultListing;
