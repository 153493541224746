import Cookies from "js-cookie";
import { useMemo } from "react";

export const useFetchtoken = () => {
  return useMemo(() => {
    const token = Cookies.get("jwt");
    return token ? token : null;
  }, []);
};
export const useFetchUser = () => useMemo(() => {
    const user = Cookies.get("user");
    return user ? JSON.parse(user) : null;
  }, [Cookies.get("user")]);

