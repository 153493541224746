export const labelMapping: { [key: string]: string } = {
  business_type: "Business Type",
  business_type_name: "Business Type Name",
  company_contact: "Company Contact",
  c_contact: "Company Contact",
  company_country: "Company Country",
  c_description: "Company Description",
  company_description: "Company Description",
  c_email: "Company Email",
  company_email: "Company Email",
  c_gstin: "Company GSTIN",
  c_name: "Company Name",
  company_name: "Company Name",
  c_p_name: "Contact Person Name",
  c_address:"Company Address",
  c_link: "Company Website",
  country: "Country",
  Profession: "Profession",
  Profession_name_other: "Business Type Name",
  Specialization: "Specialization",
  policytype: "Policy Type",
  insurer: "Bidder Preference",
  previous_policy: "Previous Policy",
  previous_insurance_company_name: "Previous Insurance Company",
  Limit_of_Indemnity: "Limit of Indemnity Ratio (AOA:AOY)",
  medical_extension: "Medical Extension",
  periodofinsurance_startdate: "Policy Start Date",
  periodofinsurance_enddate: "Policy End Date",
  Retroactive_date: "Any Retroactive Date",
  territory: "Coverage Territory",
  jurisdiction: "Coverage Jurisdiction",
  aggregateloss: "Aggregate Loss Limit (INR)",
  deductible: "Deductible Limit (INR)",
  existingpolicy: "Existing Policy",
  RetentionType: "Insurer's Retention Type",
  Prior_claim: "Any Prior Insurance Claim",
  Years_in_business: "Number of Years in Business",
  totalemployees: "Total Number of Employees",
  kids_count: "Number of Kids",
  spouse_count: "Number of Spouses",
  grossincome: "Annual Gross Income",
  tax_dues: "Any Tax Dues",
  policycover_period: "Policy Cover Period",
  service_category: "Service Category",
  terrorism: "Terrorism Coverage",
  age_band: "Age Band",
  insured: "Insured",
  Want_to_cover_parents: "Want to cover parents?",
  employeenumber: "Employee Count",
  spousenumber: "Spouse count",
  kidsnumber: "Kids count",
  comp_location: "Location",
  coverage_type: "Coverage type",
  coverage_type_name_other: "Coverage Type Name",
  sum_insured_category: "Sum insured / Sum insured based on category",
  base_sum_insured: "Employee Sum Insured",
  base_sum_insured_other: "Other Sum Insured Value",
  Age_Limit: "Age Limit",
  payment_mode: "Premium payment mode",
  Payout_Options: "Payout Options",
  v_a_sum_assured: "Voluntary Additional Sum Assured",
  Top_up_sum_insured: "Top-up Sum Insured",
  Top_up_sum_insured_other: "Other Top-up Sum Insured Value",
  Catastrophe: "Catastrophe Cover",
  Voluntary_Fl_Premium: "Voluntary Additional Family Load Premium",
  GMB_Sum_Insured: "GMB Sum Insured",
  Term: "Term",
  Min_group_size: "Minimum group size",
  mediclaim_option: "Mediclaim option",
  Maternity_Benefit: "Maternity Benefit",
  maternity_limit: "Maternity Limit",
  Newborn_Cover: "Newborn Baby Cover",
  pre_post_hospitalisation: "Pre and Post Hospitalization",
  day_care_treatment: "Day Care Treatment",
  ambulance_charges: "Ambulance Charges",
  hospital_room_rent: "Hospital Room Rent",
  Corporate_buffer: "Corporate Buffer",
  Disease_limit: "Disease Limit",
  limit_per_member: "Limit Per Member",
  covid19_cover: "COVID-19 Cover",
  critical_illness_cover: "Critical Illness Cover",
  additional_critical_illness: "Additional Critical Illness",
  Waiver_Maternity: "Waiver of Maternity",
  Pre_existing_diseases: "Pre-existing Diseases",
  Medical_screening: "Medical Screening",
  maternity_waiting_period: "Maternity Waiting Period",
  pre_existing_waiting_period: "Pre-existing Disease Waiting Period",
  illness_hospitalisation: "Illness Hospitalization",
  Outpatient_care: "Outpatient Care",
  daycare_procedures: "Daycare Procedures",
  Eye_care: "Eye Care",
  dental_care: "Dental Care",
  non_allopathic_treatment: "Non-Allopathic Treatment",
  accidental_death_cover: "Accidental Death Cover",
  disability_cover: "Disability Cover",
  Weekly_compensation: "Weekly Compensation",
  hospital_daily_cash: "Hospital Daily Cash",
  OPD_limit: "OPD Limit",
  Health_Checkup: "Health Checkup",
  other_benefits: "Other Benefits",
  nominee_name: "Nominee Name",
  nominee_relationship: "Nominee Relationship",
  nominee_age: "Nominee Age",
  nominee_gender: "Nominee Gender",
  Occupation: "Occupation",
  Annual_Income: "Annual Income",
  Existing_Cover: "Existing Cover",
  Risk_Covered: "Risk Covered",
  Special_conditions: "Special Conditions",
  waiting_period: "Waiting Period",
  Waiver_Age_Limit: "Waiver of Age Limit",
  OPD_coverage: "OPD Coverage",
  Claim_processing: "Claim Processing",
  Plan_Enhancements: "Plan Enhancements",
  Plan_downgrades: "Plan Downgrades",
  Top_up: "Top-up",
  Rider_Cover: "Rider Cover",
  Life_Cover: "Life Cover",
  disease_management: "Disease Management",
  Health_assistance: "Health Assistance",
  diet_consultation: "Diet Consultation",
  Mental_Health_cover: "Mental Health Cover",
  Yoga_Ayurveda: "Yoga and Ayurveda",
  wellness_programs: "Wellness Programs",
  telemedicine: "Telemedicine",
  disease_screening: "Disease Screening",
  partner_network: "Partner Network",
  Health_Coach: "Health Coach",
  health_coaching_sessions: "Health Coaching Sessions",
  fitness_centers: "Fitness Centers",
  Weight_management: "Weight Management",
  OPD_Limit: "OPD Limit",
  NCB: "No Claim Bonus",
  OPD: "OPD",
  second_medical_opinion: "Second Medical Opinion",
  critical_illness_screening: "Critical Illness Screening",
  vector_borne_disease_cover: "Vector-borne Disease Cover",
  Outpatient_treatment: "Outpatient Treatment",
  in_patient_treatment: "In-patient Treatment",
  Wellness_Benefits: "Wellness Benefits",
  Base_Coverage: "Base Coverage",
  Optional_Coverage: "Optional Coverage",
  Nature_of_work: "Nature of work",
  Contractor_name: "Contractor name",
  Claim_Experience: "Claim Experience for past 3 years",
  Any_other_details: "Any other details need to share",
  Unskilled: "Unskilled",
  Wages_per_month1: "Wages per month",
  Semi_skilled: "Semi skilled",
  Wages_per_month2: "Wages per month",
  Skilled: "Skilled",
  Wages_per_month3: "Wages per month",
  Occupational_Diseases: "Occupational Diseases",
  Medical_Expenses: "Medical Expenses",
  Coverage_for_Contractors: "Coverage for Contractors and/or SubContractors",
  start_date: "Bid's Start Date",
  end_date: "Bid's End Date",
  policy_start_date: "Policy Start Date",
  policy_end_date: "Policy End Date",
  expected_premium: "Anticipated premium",
  // sum_insured: "Total coverages",
  sum_insured: "Sum Insured",
  previous_insurer: "Previous Insurer",
  previous_policy_expiry: "Previous policy expiry",
  Are_you_owner_or_rented: "Are you owner or rented ?",
  Occupancy: "Occupancy",
  Occupancy_name_other: "Business Type Name",
  Floater: "Floater",
  Location1: "Location 1",
  Location2: "Location 2",
  Building_Value: "Building Value",
  Content_Value: "Content Value",
  Stock_Value: "Stock Value",
  Plant_and_Machinery: "Plant and Machinery",
  Furniture_Fixture_Fittings: "Furniture, Fixture & Fittings",
  Other_Items_Value: "Other Items Value",
  Construction: "Construction",
  Fire_Protection_Install: "Fire Protection Install",
  Unit_Age: "Unit Age (In Year)",
  Distance_between_block:
    "Distance between block (In case of multiple locations In Meter)",
  Distance_between_Nearest: "Distance between Nearest fire brigade (In Meter)",
  Surrouding_Exposure: "Surrouding Exposure",
  Claim_experience: "Claim experience",
  Debris_Removal: "Debris Removal",
  Architects_Surveyors: "Architects, Surveyors and consulting Engineers Fees",
  Spontaneous_Combustion: "Spontaneous Combustion",
  Omission_extensions: "Omission extensions",
  Spoilage_Material: "Spoilage Material damage cover",
  Leakage_Contamination: "Leakage and Contamination cover",
  Loss_of_rent: "Loss of rent (Specify indemnity in month)",
  Loss_of_rent_limit: "Loss of rent limit",
  Temporary_Removal_of_Stocks: "Temporary Removal of Stocks",
  Forest_Fire: "Forest Fire",
  Earthquake: "Earthquake (Fire & Shock)",
  Impact_damage: "Impact Damage",
  Impact_damage_value: "Value",
  STFI: "STFI",
  Money_coverage: "Money coverage",
  Burglury: "Burglury",
  First_loss_Profit: "First loss Profit",
  Terrorism: "Terrorism",
  Commodity_Type: "Commodity Type",
  Commodity_name_other: "Commodity Type Name",
  What_type_of_Cover_you_want: "What type of Cover you want? ",
  Mode_of_Transport: "Mode of Transport",
  Mode_of_Transport_other: "Value for Others",
  Cargo_Descriptipns: "Cargo Descriptipns",
  Packaging: "Packaging",
  All_risk_benefit: "All risk benefit",
  Basic_Cover: "Basic Cover",
  Fire_and_lightning: "Fire and lightning",
  War: "War",
  SRCC: "SRCC",
  Theft_Pilferage_Non_Delivery: "Theft, Pilferage & Non Delivery",
  ICC_A: "ICC A",
  ICC_B: "ICC B",
  ICC_C: "ICC C",
  ITC_A: "ITC(A)",
  Waiver_of_Subrogation: "Waiver of Subrogation",
  Forms_of_Cargo: "Forms of Cargo",
  Machinery: "Machinery",
  Chemicals: "Chemical temperature",
  Prevent_Pollution: "Step to Prevent Pollution from transportation",
  cargo_carried: "How is the cargo carried?",
  Other_Details: "Other Details",
  Sum_Insured_Specific: "Sum Insured(Specific )",
  Sum_Insured_Annual: "Sum Insured( Annual )",
  Turnover_Annual_Policy: "Turnover (Annual Policy)",
  Type_of_Transport: "Type of Transport",
  Distance_of_carrying_money: "Distance of carrying money",
  Transit_details: "Transit details(Carrying money from where to where)",
  Security: "Security at premises out of business hours",
  Alarm_System: "Alarm System",
  CCTV: "CCTV",
  Security_of_doors: "Is Security of doors and windows",
  Money_In_Transit: "Money in Transit ",
  Money_In_Safe: "Money In safe ",
  Money_In_Counter: "Money In counter",
  Estimated_Annual_Turnover: "Estimated Annual Turnover",
  SRCC_Coverage: "SRCC Coverage",
  // General Information
  Type_of_Company: "Type of Company",
  Company_name_other: "Company Type Name",
  company_Nature: "Nature of operation of the company ",
  Lifts_Address: "Address where Lifts are situated",

  // Machine Descriptions
  Lift_usage_type: "Lift usage type",
  Dimension: "Dimension",
  Maker_name: "Maker's name",
  Motive_power: "Motive power",
  HP: "If electric, H.P. of motor",
  Carrying_Capacity: "Carrying Capacity",

  // Special Conditions
  Method_of_contract: "Method of contract",
  Operated_by_Employee: "Operated by Employee",
  Number_of_floor_sevred: "Number of floor served",
  Date_of_Installation: "Date of Installation",
  Has_any_company_decline: "Has any company decline to Insure",
  contract_for_Maintanance_and_Inspection:
    "Contract for maintenance and inspection",
  intervals_do_engineers:
    "At what intervals do engineers carry out inspections?",
  breakdown_occurred: "Has any breakdown occurred? If so, give details",

  // Limit of Liability
  AOA: "AOA (Any One Accident)",
  AOY: "AOY ( Any One Year)",
  Terminal_illness_benefit: "Terminal illness",
  Earning_Spouse: "Earning Spouse",
  Group_Term_in_lieu_of_EDLI: "Group Term in lieu of EDLI",
  Single_rate_benefit: "Single rate",
  Sum_Assured_Reset_Benefit: "Sum Assured Reset",
  Profit_sharing: "Profit sharing",
  coverage: "Employee Sum insured",
  TTD: "Temporary Total Disability (TTD)",
  Education_Fund_for_child: "Education Fund for child (Upto 2)",
  Ambulance_Charges: "Ambulance Charges",
  Accidenatal_burns: "Accidental Burns",
  Accidenatal_Broken_burns: "Accidental Broken Bones",
  Funeral_Expenses: "Funeral Expenses & Repatriation",
  Compassionate_visit: "Compassionate Visit",
  Jurisdiction: "Jurisdiction",
  AD: "Accidental Death (AD)",
  PTD: "Permanent Total Disability (PTD)",
  //new
  Type_of_Company_other: "Type Name",
  Nature_of_operation: "Nature of operation of the company and subsidiaries",
  Territorial_Scope: "Territorial Scope and Jurisdiction",
  AnyMergers: "Any Mergers, Acquisitions & Structural Change(if Yes)",
  acquisition:
    "Has the Company any acquisition, tender offers, or mergers pending or under consideration",
  Company_subsidiary: "Is the Company a subsidiary of another company",
  Company_subsidiary_USA: "Does the Company have any subsidiaries in the USA",
  total_gross_assets:
    "What are the total gross assets the Company in the USA ($)",
  ADR: "Does the Company or any of its subsidiaries have any American Depositary Receipts (ADR’s) on issue? ",
  Officers_Liability:
    "Has the Company's Directors & Officers Liability policy ever been subject to any unique terms or conditions?",
  Pending_Litigation: "Prior and Pending Litigation?",
  Prior_Acts_Exclusion: "Prior Acts Exclusion",
  Discovery_Period: "Discovery Period",
  Discovery_Period_Person: "Discovery Period for Retired Insured Person",
  Sales_Turnover: "Sales Turnover",
  OutsideDirectorshipLiability: "Outside Directorship Liability",
  FormerDirectorsOfficers: "Former Directors & Officers",
  CompanySecuritiesLiability: "Company Securities Liability",
  CounsellingCosts: "Counselling Costs",
  Civilfinespenalties: "Civil fines and penalties",
  CrisisMitigationCosts: "Crisis Mitigation Costs",
  Defenseexpensesbreakinglaws: "Defense expenses for breaking laws",
  DeprivationofAssetsCosts: "Deprivation of Assets Costs",
  AbductionResponseCosts: "Abduction Response Costs",
  EmergencyCosts: "Emergency Costs",
  ExtraditionCosts: "Extradition Costs",
  InvestigationCosts: "Investigation Costs",
  Machinery_category: "Machinery category",
  Machinery_category_other: "Machinery Name",
  equipements: "Imported equipments available in India",
  Location: "Risk Location type",
  Equipment_operating: "Equipment operating",
  Risk_Locations: "Risk Locations",
  Risk_location_address: "Risk location address",
  Machinery_Type: "Machinery Type",
  Security_Services: "Security Services",
  Risk_Terrain: "Risk Terrain",
  Equipment_Storage: "Equipment Storage",
  Claim_Occured: "Claim Occured",
  Make: "Make",
  Model: "Model",
  Manufacture_year: "Year of Manufacture",
  Price: "Price",
  Earthquake_Sublimit: "Earthquake Sublimit",
  Owners_Surrounding_Property: "Owners Surrounding Property",
  Debris_Removal_limit: "Debris Removal limit",
  Maintenance_Cover: "Maintenance Cover (Enter month)",
  Machinery_plants: "Machinery plants and equipment",
  Structure_Category: "Structure Category",
  Structure_Category_name_other: "Business Type Name",
  Location_Type: "Location Type",
  Project_Location: "Project Location",
  Project_Timeline: "Project Timeline (In months)",
  Project_Complete: "Project Complete (%)",
  Project_Initiate_On: "Project Initiate On",
  Material_Storage: "Material Storage",
  Project_Claim: "Claim Occured In Project",
  Client_Experience: "Client Experience In Similar Project",
  Client_Claim_Experience: "Client claim Experience",
  Contract_Price: "Contract Price",
  Material_Supply_value: "Material Supply value",
  Labour_Cost: "Labour Cost",
  Glass_Item_value: "Glass Item value",
  Other_Item_value: "Other Item value",
  Machinery_plants_and_equipment: "Machinery plants and equipment",
  Description_of_Operation: "Description of Operation",
  Territory: "Territory",
  Surrounding_areas:
    "Surrounding areas – Industrial/ Agricultural/ Residential",
  Nature_of_Work1: "Nature of Work",
  Address1: "Address",
  Surrounding_areas2:
    "Surrounding areas – Industrial/ Agricultural/ Residential",
  Nature_of_Work2: "Nature of Work",
  Address2: "Address",
  Type_of_construction: "Type of construction(Rcc, Tem, Sheds)",
  Age_of_Building: "Age of Building",
  Address3: "Address",
  Building_Heightin_meter: "Building Height in meter/ floor",
  Description_of_elevators:
    "Description of elevators/escalators with make & capacity",
  occupants_and_activities:
    "Details of other occupants and activities in the building",
  Description_of_the_Product: "Description of the Product",
  Business_Turn_Over: "Business Turn Over",
  Export: "Export",
  Destination: "Destination",
  Retroactive_Date: "Retroactive Date ",
  Annual_Projected: "Annual turnover In India (Projected)",
  Annual_Currently: "Annual turnover In India (Currently)",
  Annual_last_year: "Annual turnover In India (last year) ",
  Annual_Canada_Projected: "Annual turnover In USA Canada (Projected)",
  Annual_Canada_Currently: "Annual turnover In USA Canada (Currently)",
  Annual_USA_Canada_last_year: "Annual turnover In USA Canada (last year) ",
  Annual_Rest_of_the_world_Projected:
    "Annual turnover In Rest of the world (Projected)",
  Annual_Rest_of_the_world_Currently:
    "Annual turnover In Rest of the world (Currently)",
  Annual_Rest_of_the_world_last_year:
    "Annual turnover In Rest of the world (last year) ",
  Number_of_employees_in_India: "Number of employees in India ",
  Number_of_employees_Usa_Canada: "Number of employees in Usa Canada ",
  Number_of_employees_Rest_world: "Number of employees Rest of the world",
  Number_of_premises_In_India: "Number of premises In India",
  Number_of_premises_USA_Canada: "Number of premises In USA Canada",
  Security_type: "Security type",
  Watchman: "Watchman",
  Storage: "Storage",
  Heightened_boundary_walls: "Heightened boundary walls",
  watchman_available: "Is 24 hour watchman available",
  doors_and_windows: "Is Security of doors and windows",
  Content: "Content",
  Stock_value: "Stock value",
  OtherItemsvalue: "Other Items value",
  Item_description: "Item description",
  First_Loss_Sum_Insured: "First Loss Sum Insured at the total value at risk %",
  Absolute_Amount: "First Loss Basis in terms of Absolute Amount",
  Loss_of_money_Limit: "Loss of money Limit",
  Clearing_up_Expenses: "Clearing up Expenses",
  Temporary_Property_Protection: "Temporary Property Protection",
  Theft_Extension: "Theft Extension",
  Riot_Strike_Malicious: "Riot, Strike, Malicious Damage Cover",
  Injury_during_Burglary: "Injury during Burglary",
  //cyber_insurance
  prior_pending_litigation_date: "PRIOR AND PENDING LITIGATION DATE",
  retroactive_date: "RETROACTIVE DATE",
  company_turnover: "Company turnover",
  employee_background_verification:
    "Do you perform employee background verification?",
  security_system:
    "Do you have security system, firewall and audit logs in place?",
  business_continuity_plan:
    "Do you have a business continuity plan (BCP) in place?",
  cyber_attack_history:
    "Have you encountered any form of cyber attack (intrusion, data tampering, data loss or hacking) in past 5 years?",
  confidential_information_storage:
    "Do you store any confidential information in your system (Healthcare Information, Personal Information, Banking/Payment Information)?",
  critical_data_recovery: "Can you recover business critical data and systems?",
  outsourced_data_activity:
    "Have you outsourced data collection and data processing activity?",
  personal_information_processing:
    "Does the Company collect, maintain or process personal information of clients (If yes describe)",
  potential_loss_of_profit:
    "Company likely to incur a loss of profit as a result of an IT network compromise and a total system downtime?",
  security_privacy_liability: "Security & Privacy Liability",
  corporate_security_liability: "Corporate Security Liability",
  multimedia_liability: "Multimedia Liability",
  computer_system: "Computer System",
  unauthorized_disclosure: "Unauthorized Disclosure",
  credit_monitoring_costs: "Credit Monitoring Costs-",
  crisis_management_costs: "Crisis Management Costs",
  cyber_extortion_costs: "Cyber Extortion Costs",
  data_restoration_costs: "Data Restoration Costs",
  forensic_costs: "Forensic Costs",
  legal_representation_costs: "Legal Representation Costs",
  privacy_notification_costs: "Privacy Notification Costs",
  regulatory_fines_penalties: "Regulatory Fines and Penalties",
  business_interruption_waiting_period: "Business Interruption waiting period",
  business_interruption_loss_limit: "Business Interruption loss limit",
  extended_reporting_period: "Extended Reporting Period (In days)",
  court_attendance_costs: "Court Attendance Costs (In $)",
  emergency_professional_fees: "Emergency Professional Fees (in $)",
  pci_dss_loss_coverage: "PCI-DSS loss coverage (in $)",
  estimated_daily_loss_of_profit:
    "Estimated daily loss of profit for the Company, in case of non-scheduled shutdown (in $)",
  //Electornic insurance
  clientProfession: "Client Profession",
  clientProfession_other: "Client Profession (Other)",
  policyType: "Policy Type",
  equipmentList: "Equipment List",
  equipments: "Equipment Details",
  equipment_use: "Equipment Use",
  equipment_category: "Equipment Category",
  isEquipmentManufacturedInIndia: "Is Equipment manufacture in India ?",
  repairAndMaintenanceInIndia: "Repair and Maintenance in India",
  equipmentAge: "Equipment Age",
  equipmentType: "Equipment Type",
  equipmentMake: "Equipment Make",
  equipmentPrice: "Equipment Price",
  previousClaim: "Previous Claim",
  accidentalDamage: "Accidental Damage",
  mechanicalElectricalBreakdown: "Mechanical/Electrical Breakdown",
  theftBurglary: "Theft/Burglary",
  fireNaturalDisasters: "Fire/Natural Disasters",
  transitCoverage: "Transit Coverage",
  vandalism: "Vandalism",
  thirdPartyLiability: "Third-Party Liability",
  businessInterruption: "Business Interruption",
  consumablesAccessories: "Consumables/Accessories",
  softwareMalfunction: "Software Malfunction",
  temporaryReplacement: "Temporary Replacement",
  installationDamage: "Installation Damage",
  coverageLocation: "Coverage Location",
  any_other_detail: "Other Details",
};

export const fieldCategories: { [category: string]: string[] } = {
  "Company Details": [
    "Business Type",
    "Business Type Name",
    "Company Contact",
    "Company Description",
    "Company Email",
    "Company GSTIN",
    "Company Name",
    "Contact Person Name",
    "Company Country",
    "Company Address",
    "Company Website"
  ],
  "Policy Details": [
    "Product",
    "Insured",
    "Employee Count",
    "Spouse count",
    "Kids count",
    "Policy Type",
    "Bidder Preference",
    "Location",
    "Occupation",
    "Client type",
    "Occupancy",
    "Floater",
    "Location 1",
    "Location 2",
    "Type of Transport",
    "Type of Liablity",
    "Coverage Type",
    "Location Type",
    "Description of Operation",
    "Territory",
    "Surrounding areas",
    "Nature of Work",
    "Address",
    "Type of construction",
    "Age of Building",
    "Building Heightin meter/ floor",
    "Description of elevators/escalators",
    "Details of other occupants and activities in the building",
    "Description of the Product",
    "Business Turn Over",
    "Export",
    "Destination",
    "Nature of Project",
    "Structure Category",
    "Project Location",
    "Project Timeline",
    "Project Complete",
    "Project Initiate On",
    "Machinery category",
    "Imported equipment available in India",
    "Risk Location type",
    "Equipment operating",
    "Risk Locations",
    "Risk location address",
    "Claim Occurred",
    "Type of Company",
    "Nature of operation of the company and subsidiaries",
    "Claim Experience for past 3 years",
    "Any other details need to share",
    "Address where Lifts are situated",
    "Lift usage type",
    "Dimension",
    "Maker's name",
    "Motive power",
    "If electric, H.P. of motor",
    "Carrying Capacity",
    "Profession",
    "Limit of Indemnity Ratio (AOA:AOY)",
    "What is your communication address?",
    "Specialization",
    "Licence Obtain date",
    "Registration date",
    "Commodity Type",
    "What type of Cover you want?",
    "Mode of Transport",
    "Cargo Descriptions",
    "Packaging",
    "Transit From",
    "Transit To",
    //unsure
    "Want to cover parents?",
  ],
  "Base Coverage": [
    "Base cover",
    "Age Band",
    "30 Days, 9 months waiting period",
    "Pre & Post-Hospitalisation Expenses",
    "New born baby",
    "Day care procedure",
    "Terrorism",
    "Domiciliary Hospitalisation",
    "AYUSH Treatment",
    "Treatment for correction of eyesight",
    "Pre and Post Natal expenses",
    "Cataract Surgery",
    "Service Category",
    "Jurisdiction",
    "Accidental Death (AD)",
    "Permanent Total Disability (PTD)",
    "Sum insured",
    "Coverage type",
    "Age Limit",
    "Premium payment mode",
    "Payout Options",
    "Special conditions",
    "Construction",
    "Fire Protection Install",
    "Distance between block",
    "Distance between Nearest fire brigade",
    "Surrounding Exposure",
    "Claim experience",
    "Any other details related to claims and Perils",
    "Security type",
    "Watchman",
    "Alarm System",
    "CCTV Availability",
    "Storage",
    "Heightened boundary walls",
    "Is 24 hour watchman available",
    "Is Security of doors and windows",
    "Distance of carrying money In KM",
    "Transit details",
    "Security at premises out of business hours",
    "Retroactive Date",
    "AOA (Any One Accident)",
    "AOY (Any One Year)",
    "Annual turnover In India (Projected)",
    "Annual turnover In India (Currently)",
    "Annual turnover In India (last year)",
    "Annual turnover In USA Canada (Projected)",
    "Annual turnover In USA Canada (Currently)",
    "Annual turnover In USA Canada (last year)",
    "Annual turnover In Rest of the world (Projected)",
    "Annual turnover In Rest of the world (Currently)",
    "Annual turnover In Rest of the world (last year)",
    "Number of employees in India",
    "Number of employees in USA Canada",
    "Number of employees Rest of the world",
    "Number of premises In India",
    "Number of premises In USA Canada",
    "Number of premises In rest of the world",
    "Security Services",
    "Risk Terrain",
    "Material Storage",
    "Claim Occurred In Project",
    "Client Experience In Similar Project",
    "Client claim Experience",
    "Machinery Type",
    "Equipment Storage",
    "Make",
    "Model",
    "Year of Manufacture",
    "Price",
    "Any Mergers, Acquisitions & Structural Change",
    "Has the Company any acquisition, tender offers, or mergers pending or under consideration",
    "Is the Company a subsidiary of another company",
    "Does the Company have any subsidiaries in the USA",
    "What are the total gross assets the Company in the USA ($)",
    "Does the Company or any of its subsidiaries have any American Depositary Receipts (ADRs) on issue?",
    "Has the Company's Directors & Officers Liability policy ever been subject to any unique terms or conditions?",
    "Prior and Pending Litigation?",
    "Prior Acts Exclusion",
    "Discovery Period",
    "Discovery Period for Retired Insured Person",
    "Sales Turnover",
    "Claim Experience for past 3 years",
    "Any other details",
    "Unskilled",
    "Wages per month",
    "Semi skilled",
    "Wages per month",
    "Skilled",
    "Wages per month",
    "Method of contract",
    "Operated by Employee",
    "Number of floor served",
    "Date of Installation",
    "Has any company declined to Insure",
    "Contract for maintenance and inspection",
    "At what intervals do engineers carry out inspections?",
    "Has any breakdown occurred? If so, give details",
    "Forms of Cargo",
    "Machinery",
    "Chemical temperature",
    "Step to Prevent Pollution from transportation",
    "Sum Insured (Specific)",
    "Sum Insured (Annual)",
    "Turnover (Annual Policy)",
    "Per bottom Limit",
    "Limit Per Location",
    "Basis of Valuation",
    "Declare the value, if duty covered",
    "Incidental expense",
  ],
  "Optional Coverage": [
    "Sum insured",
    "Maternity Benefits",
    "Value for Others",
    "Room rent",
    "Cover Pre existing Diseases",
    "Diseases wise capping",
    "Ambulance Charges (Per person)",
    "Temporary Total Disability (TTD)",
    "Age Limit",
    "Medical Expenses",
    "Education Fund for child (Upto 2)",
    "Ambulance Charges",
    "Accidental burns",
    "Accidental Broken Bones",
    "Funeral Expenses & repatriation",
    "Compassionate visit",
    "Optional Benefits",
    "Voluntary Additional Sum Assured",
    "Group Term in lieu of EDLI",
    "Profit sharing",
    "Terminal illness",
    "Earning Spouse",
    "Single rate",
    "Sum Assured Reset",
    "Building Value",
    "Content Value",
    "Stock Value",
    "Plant and Machinery",
    "Furniture, Fixture & Fittings",
    "Other Items Value",
    "Debris Removal",
    "Architects, Surveyors and consulting Engineers Fees",
    "Spontaneous Combustion",
    "Omission extensions",
    "Spoilage Material damage cover",
    "Leakage and Contamination cover",
    "Loss of rent (Specify indemnity in month)",
    "Loss of rent limit",
    "Temporary Removal of Stocks",
    "Forest Fire",
    "Earthquake (Fire & Shock)",
    "Impact Damage - Value",
    "STFI",
    "Money coverage",
    "Burglary",
    "First loss Profit",
    "Terrorism",
    "Fidelity insurance Coverage",
    "Content",
    "Stock value",
    "Plant and Machinery",
    "Furniture, Fixture & Fittings",
    "Other Items value",
    "Item description",
    "First Loss Sum Insured at the total value at risk %",
    "First Loss Basis in terms of Absolute Amount",
    "Loss of money Limit",
    "Clearing up Expenses",
    "Temporary Property Protection",
    "Theft Extension",
    "Riot, Strike, Malicious Damage Cover",
    "Injury during Burglary",
    "Money in Transit",
    "Money In safe",
    "Money In counter",
    "Estimated Annual Turnover",
    "SRCC Coverage",
    "Terrorism",
    "Vendors Liability Extension",
    "Technical collaborators liability",
    "Accidental Pollution Extension",
    "Liability arising out of Transportation",
    "Act of God Extension",
    "Terrorism Extension",
    "Goods kept in Custody",
    "Food and Beverages Extension",
    "Swimming pools Extension",
    "Sports facilities extension",
    "Personal & Advertising Injury",
    "Defense Costs",
    "Legal liability arising out of performance of any renovation",
    "Lifts, Elevators & Escalators Liability Extension",
    "Contract Price",
    "Material Supply value",
    "Labour Cost",
    "Glass Item value",
    "Other Item value",
    "Earthquake Sublimit",
    "Terrorism",
    "Owners Surrounding Property",
    "Debris Removal limit",
    "Maintenance Cover (Enter month)",
    "Machinery plants and equipment",
    "Outside Directorship Liability",
    "Former Directors & Officers",
    "Company Securities Liability",
    "Counselling Costs",
    "Civil fines and penalties",
    "Crisis Mitigation Costs",
    "Defense expenses for breaking laws",
    "Deprivation of Assets Costs",
    "Abduction Response Costs",
    "Emergency Costs",
    "Extradition Costs",
    "Investigation Costs",
    "Occupational Diseases",
    "Coverage for Contractors and/or SubContractors",
    "AOA (Any One Accident)",
    "AOY (Any One Year)",
    "Limit of Indemnity Ratio (AOA:AOY)",
    "Opted Cover",
    "Optional Coverages",
  ],
  "Other Details": [
    "Bid's Start Date",
    "Bid's End Date",
    "Policy Start Date",
    "Policy End Date",
    "Anticipated premium",
    "Total coverages",
    "Claim Data of last year",
    "Employee Information",
    "Sum Insured",
    "Limit of Indemnity",
    "Construction value",
    "Limit of Liability",
    "Coverages",
    "Shipment Start Date",
    "Shipment End Date",
  ],
  "Basic Info": [
    "Client Profession",
    "Client Profession (Other)",
    "Policy Type",
    "Equipment List",
    "Equipment Details",
    "Is Equipment manufacture in India ?",
    "Repair and Maintenance in India",
    "Equipment Use",
    "Equipment Category",
    "Equipment Age",
    "Equipment Type",
    "Equipment Use",
    "Previous Claim",
  ],
  Coverages: [
    "Accidental Damage",
    "Mechanical/Electrical Breakdown",
    "Theft/Burglary",
    "Fire/Natural Disasters",
    "Transit Coverage",
    "Vandalism",
    "Third-Party Liability",
    "Business Interruption",
    "Consumables/Accessories",
    "Software Malfunction",
    "Temporary Replacement",
    "Installation Damage",
    "Coverage Location",
  ],
  "Equipment Details": ["Equipment Make", "Equipment Price"],
};
export const fieldsToExclude: string[] = [
  "user_id",
  "remember_token",
  "deleted_at",
  "policy_file",
  "c_claim_file",
  "c_emp_file",
  "pdf_file",
  "bid_status",
  "id",
  "user_id",
  "c_p_name",
  "c_name",
  "business_type",
  "c_gstin",
  "c_contact",
  "c_email",
  "country",
  "business_type_name",
  "c_description",
  "bid_status",
  "policy_file",
  "pdf_file",
  "remember_token",
  "deleted_at",
  "created_at",
  "updated_at",
  "company_id",
  // Add more fields as needed
];
export const fieldsToExcludeBidder: string[] = [
  "user_id",
  "remember_token",
  "deleted_at",
  "policy_file",
  "c_claim_file",
  "c_emp_file",
  "pdf_file",
  "bid_status",
  "id",
  "user_id",
  "bid_status",
  "policy_file",
  "pdf_file",
  "remember_token",
  "deleted_at",
  "created_at",
  "updated_at",
  "company_id",
];
