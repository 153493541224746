import React, { useEffect, useState } from "react";
import SanbotLogo from "../../../partials/assets/images/galaxy-logo.png";
import {
  Flex,
  Icon,
  Image,
  Text,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Button,
} from "@chakra-ui/react";
import { HiOutlineSparkles } from "react-icons/hi2";
import { theme } from "../../../partials/utils/Contants";
import BidDetails from "../../../pages/BidDetails";
import BidderBidDetails from "../Bidder-Bid-Detail";
import { RxCross1 } from "react-icons/rx";
const insightCardDummy = [
  "All 6 bidders have provided you with coverage's for your special requirement of maternity for your employee plus family group   policy. ",
  "However, Uniqus Broking has provided the best deal on maternity coverage with 100% of the sum insured amount per family which is 5 lakhs.",
  "However, Thames Broking gets the L1 position with lowest bid amount of ₹2,25,000 for the entire risk and is 12% more competitive than the nest best offer. ",
];
const BidderSanbotAI = ({ selectedBid, setSelectedBid }: any) => {
  const getDefaultTabIndex = () => {
    if (selectedBid?.action === "requirements") {
      return 0;
    }
    return 0;
  };

  const [tabIndex, setTabIndex] = useState(getDefaultTabIndex());

  useEffect(() => {
    setTabIndex(getDefaultTabIndex());
  }, [selectedBid?.id]);

  const InsightCard = ({ text, themeMode, width }: any) => {
    return (
      <Flex
        padding="16px 12px"
        gap="10px"
        backdropBlur="15px"
        bg={themeMode === "dark" ? "rgb(255 255 255 / 10%)" : "#FFF"}
        color={
          themeMode === "dark"
            ? theme.color.GRAY_FONT
            : "rgba(115, 115, 115, 1)"
        }
        borderRadius="16px"
        mt="10px"
        width={width || "100%"}
      >
        {themeMode === "dark" ? (
          <Icon as={HiOutlineSparkles} fontSize="18px" />
        ) : (
          <Image src={SanbotLogo} height="24px" w="24px" alt="sanbot" />
        )}
        <Text fontSize="12px" fontWeight="500" color={theme.color.GRAY_FONT}>
          {text}
        </Text>
      </Flex>
    );
  };
  return (
    <>
      {selectedBid?.id ? (
        <Flex
          direction="column"
          padding={selectedBid?.id ? "0px" : "40px 25px 20px"}
          flex={2}
          bg="black"
          gap="20px"
          maxW="460px"
          height="100%"
          minH="100vh"
          position="relative"
        >
          <Flex position="absolute" right="15px" top="15px">
            <RxCross1
              color="white"
              onClick={() =>
                setSelectedBid({
                  id: null,
                  bidId: null,
                  action: "",
                  data: {},
                })
              }
              cursor="pointer"
            />
          </Flex>
          <Flex
            align="center"
            gap="14px"
            m={selectedBid?.data ? "16px 12px" : "0px"}
          >
            <Image src={SanbotLogo} alt="sanbot" height="60px" width="60px" />
            <Flex direction="column" gap="5px">
              <Text fontSize="22px" fontWeight="500" color="white">
                SanBotAI:{" "}
              </Text>
              <Text
                fontSize="22px"
                fontWeight="500"
                color="rgba(246, 246, 246, 0.60)"
              >
                Bid Intelligence{" "}
              </Text>
            </Flex>
          </Flex>
          {!selectedBid?.id ? (
            // <InsightCard
            //   text="Click on a bid to view more details and get insights from SanbotAI"
            //   themeMode="dark"
            // />
            <></>
          ) : (
            <Tabs
              position="relative"
              index={tabIndex}
              onChange={(index) => setTabIndex(index)}
              variant="unstyled"
              width="100%"
            >
              <TabList
                gap="auto"
                p="0px"
                w="100%"
                justifyContent="space-evenly"
              >
                <Tab
                  height="47px"
                  fontSize="0.85rem"
                  fontWeight="500"
                  color="rgba(246, 246, 246, 0.50)"
                  _selected={{
                    color: "white",
                  }}
                >
                  Requirements
                </Tab>

                {/* <Tab
                  height="47px"
                  fontSize="0.85rem"
                  fontWeight="500"
                  color="rgba(246, 246, 246, 0.50)"
                  _selected={{
                    color: "white",
                  }}
                >
                  Comments
                </Tab> */}
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="white"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                     maxH="61vh"
                    // maxH="85%"
                    // flex={1}
                    overflowY="auto"
                    className="hide-scrollbar"
                  >
                    <BidderBidDetails id={selectedBid?.id} />
                  </Flex>
                </TabPanel>

                {/* <TabPanel>
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    // height="82vh"
                    overflowY="auto"
                  ></Flex>
                </TabPanel> */}
              </TabPanels>
            </Tabs>
          )}
        </Flex>
      ) : null}
    </>
  );
};

export default BidderSanbotAI;
