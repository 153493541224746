import { ReactComponent as HomeIcon } from "./partials/assets/icons/smart_home.svg";
import { ReactComponent as CreatBidIcon } from "./partials/assets/icons/ce__magic-wand.svg";
import { ReactComponent as BidCenterIcon } from "./partials/assets/icons/category-2.svg";
import { ReactComponent as DocumentIcon } from "./partials/assets/icons/ce__folders.svg";
import { ReactComponent as RolesIcon } from "./partials/assets/icons/accessible.svg";
import { ReactComponent as TeamsIcon } from "./partials/assets/icons/users.svg";
import { ReactComponent as ConversationIcon } from "./partials/assets/icons/brand-wechat.svg";
import { ReactComponent as OrchestrationIcon } from "./partials/assets/icons/Settings.svg";
import { MdOutlineSubscriptions } from "react-icons/md";

interface LinkItemProps {
  label: string;
  icon: any;
  link: string;
}
export const LinkItemsClient: Array<LinkItemProps> = [
  { label: "Home", icon: HomeIcon, link: "/home" },
  { label: "Create Bid", icon: CreatBidIcon, link: "/create-bid" },
  { label: "Bid Centre", icon: BidCenterIcon, link: "/bid-centre" },
  { label: "Conversations", icon: ConversationIcon, link: "/conversations" },
  // { label: "Orchestration", icon: OrchestrationIcon, link: "/orchestration" },
  { label: "Document Vault", icon: DocumentIcon, link: "/document-vault" },
  { label: "Roles", icon: RolesIcon, link: "/roles" },
  { label: "Team Members", icon: TeamsIcon, link: "/team-members" },
  // { label: "Bidder Preference", icon: TeamsIcon, link: "/bidder-preference" },
];
export const LinkItemsBidder: Array<LinkItemProps> = [
  { label: "Home", icon: HomeIcon, link: "/home" },
  { label: "Bid Centre", icon: BidCenterIcon, link: "/bid-centre" },
  { label: "Conversations", icon: ConversationIcon, link: "/conversations" },
  // {
  //   label: "Subscription",
  //   icon: MdOutlineSubscriptions,
  //   link: "/subscription",
  // },
  { label: "Roles", icon: RolesIcon, link: "/roles" },
  { label: "Teams", icon: TeamsIcon, link: "/team-members" },
];
