import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  FormControl,
  Input,
  Select,
  Box,
  SimpleGrid,
  FormErrorMessage,
  HStack,
  Button,
  useToast,
  Flex,
  Text,
  FormLabel,
} from "@chakra-ui/react";
import { useStore } from "../../../store/store";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import {
  BEProductMap,
  convertProductName,
  flattenObject,
} from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
import { changeSattusToDraft } from "../Forms";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";

type FormValues = {
  Machinery_category: string;
  Machinery_category_other?: string;
  equipements: string;
  policytype: string;
  insurer: string;
  comp_location: string;
  Location: string;
  Equipment_operating: string;
  Risk_Locations: string;
  Risk_location_address: string;
  Machinery_Type: string;
  Security_Services: string;
  Risk_Terrain: string;
  Equipment_Storage: string;
  Claim_Occured: string;
  Make: string;
  Model: string;
  Manufacture_year: number;
  Price: number;
  Earthquake_Sublimit: number;
  Terrorism: number;
  Owners_Surrounding_Property: number;
  Debris_Removal_limit: number;
  Maintenance_Cover: number;
  Machinery_plants: number;
  start_date: string;
  end_date: string;
  policy_start_date: string;
  policy_end_date: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer?: string;
  previous_policy_expiry?: string;
};

const ContractorssPlantMachinery = ({
  mode,
  product,
  editData,
  selectedCompany,
  setCreatedBid,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { step, setStep, setHoveredLabel, resetHoveredLabel } = useStore();
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      Equipment_operating: "Floater Basis",
      Risk_Locations: "Any where in India",
      Machinery_Type: "New Machinery",
      Security_Services: "Fenced Compound",
      Risk_Terrain: "Low Lying",
      Equipment_Storage:
        "Open Storage - Less than 10 meters from Erection / construction Site",
      Claim_Occured: "No",
      Manufacture_year: 0,
      Price: 0,
      Earthquake_Sublimit: 0,
      Terrorism: 0,
      Owners_Surrounding_Property: 0,
      Debris_Removal_limit: 0,
      Maintenance_Cover: 0,
      Machinery_plants: 0,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
      expected_premium: 0,
      sum_insured: 0,
    },
  });

  const toast = useToast();
  const onSubmit = (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    formData.append("comp_id", selectedCompany.toString() || "");
    const parsedProduct = BEProductMap[product];

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }

    formData.append("form_properties", JSON.stringify({ ...data }));
    if (editData?.id) {
      setIsSubmitting(true);
      useFetch
        .put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData
        )
        .then((res) => {
          setCreatedBid(res.data?.data?.id);
          toast({
            title: `Bid updated successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      setIsSubmitting(true);
      useFetch
        .post(`/api/${selectedCompany}/store-bid/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setCreatedBid(res.data?.id);
          toast({
            title: `Bid created successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormValues, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);
  const price = +watch("Price");

  useEffect(() => {
    setValue("sum_insured", price);
  }, [price]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid columns={2} spacing={4}>
        <HStack>
          <FormControl isInvalid={Boolean(errors?.Machinery_category)}>
            <Flex align="start">
              <HoverableFormLabel>Machinery Category*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Type of machinery to be covered under this policy"
                />
              )}
            </Flex>

            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select Machinery category"
              {...register("Machinery_category", {
                required: "This field is required",
              })}
              onFocus={() => setHoveredLabel("Machinery Category")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="Excavators">Excavators</option>
              <option value="Backhoe Loaders">Backhoe Loaders</option>
              <option value="Motor Graders">Motor Graders</option>
              <option value="Forklifts">Forklifts</option>
              <option value="Breakers">Breakers</option>
              <option value="Crawler Dozers">Crawler Dozers</option>
              <option value="Mobile Concrete batching">
                Mobile Concrete batching
              </option>
              <option value="Loading Shovel">Loading Shovel</option>
              <option value="Overhead Gantry Crane">
                Overhead Gantry Crane
              </option>
              <option value="Stationery concrete pump">
                Stationery concrete pump
              </option>
              <option value="Canal Paving machine">Canal Paving machine</option>
              <option value="Self loader mixure">Self loader mixure</option>
              <option value="Side Discharge Conveyor">
                Side Discharge Conveyor
              </option>
              <option value="Full Section Canal Paver Compact Concrete Batching">
                Full Section Canal Paver Compact Concrete Batching
              </option>
              <option value="Canal Paving Machine">Canal Paving Machine</option>
              <option value="Others">Others</option>
            </Select>
            <FormErrorMessage>
              {errors.Machinery_category && "This field is required"}
            </FormErrorMessage>
          </FormControl>
          {watch("Machinery_category") === "Others" && (
            <FormControl isInvalid={Boolean(errors?.Machinery_category_other)}>
              <HoverableFormLabel>Machinery Name*</HoverableFormLabel>
              <Input
                {...register("Machinery_category_other", {
                  required: "This field is required",
                })}
                borderRadius="16px"
                background="white"
              />
              {errors?.Machinery_category_other && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Machinery_category_other?.message}
                </Text>
              )}
            </FormControl>
          )}
        </HStack>
        <FormControl isInvalid={Boolean(errors?.equipements)}>
          <HoverableFormLabel>
            Imported Equipment Available in India*
          </HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Select equipments"
            {...register("equipements", { required: "This field is required" })}
            onFocus={() =>
              setHoveredLabel("Imported Equipment Available in India")
            }
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
          <FormErrorMessage>
            {errors.equipements && "This field is required"}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.policytype)}>
          <HoverableFormLabel>Policy Type*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Select policy type"
            {...register("policytype", { required: "This field is required" })}
            onFocus={() => setHoveredLabel("Policy Type")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="New">New</option>
            <option value="Renewal">Renewal</option>
          </Select>
          <FormErrorMessage>
            {errors.policytype && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.insurer)}>
          <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Preference"
            onFocus={() => setHoveredLabel("Bidder Preference")}
            {...register("insurer", { required: "This field is required" })}
          >
            <option value="Both">Both</option>
            <option value="Brokers">Brokers</option>
            <option value="INS_COMP">Insurance Company</option>
          </Select>
          <FormErrorMessage>
            {errors.insurer && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.comp_location)}>
          <HoverableFormLabel>
            Where is your company located?*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("comp_location", {
              required: "This field is required",
            })}
            onFocus={() => setHoveredLabel("Where is your company located?")}
            onBlur={() => resetHoveredLabel()}
          />
          <FormErrorMessage>
            {errors.comp_location && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Location)}>
          <HoverableFormLabel>Risk Location type*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Location"
            onFocus={() => setHoveredLabel("Risk Location type")}
            {...register("Location", { required: "This field is required" })}
          >
            <option value="Specific">Specific</option>
            <option value="Floater">Floater</option>
          </Select>
          <FormErrorMessage>
            {errors.Location && "This field is required"}
          </FormErrorMessage>
        </FormControl>
        <FormControl>
          <HoverableFormLabel>Equipment operating</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            {...register("Equipment_operating")}
            defaultValue="Floater Basis"
            onFocus={() => setHoveredLabel("Equipment operating")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Risk_Locations)}>
          <HoverableFormLabel>Risk Locations*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Risk_Locations", {
              required: "This field is required",
            })}
            defaultValue="Any where in India"
            onFocus={() => setHoveredLabel("Risk Locations")}
            onBlur={() => resetHoveredLabel()}
          />
          <FormErrorMessage>
            {errors.Risk_Locations && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Risk_location_address)}>
          <HoverableFormLabel>Risk location address*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Risk_location_address", {
              required: "This field is required",
            })}
            onFocus={() => setHoveredLabel("Risk location address")}
            onBlur={() => resetHoveredLabel()}
          />
          <FormErrorMessage>
            {errors.Risk_location_address && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Machinery_Type)}>
          <HoverableFormLabel>Machinery Type*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Machinery Type"
            {...register("Machinery_Type", {
              required: "This field is required",
            })}
            onFocus={() => setHoveredLabel("Machinery Type")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="New Machinery">New Machinery</option>
            <option value="Second hand Machinery">Second hand Machinery</option>
          </Select>
          <FormErrorMessage>
            {errors.Machinery_Type && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Security_Services)}>
          <HoverableFormLabel>Security Services*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Security Services"
            {...register("Security_Services", {
              required: "This field is required",
            })}
            onFocus={() => setHoveredLabel("Security Services")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Fenced Compound">Fenced Compound</option>
            <option value="Fenced Compound +24 Hours watch and Ward Security">
              Fenced Compound +24 Hours watch and Ward Security
            </option>
            <option value="Fenced Compound +24 Hours watch and Ward Security + site lightning for hours of darkness">
              Fenced Compound +24 Hours watch and Ward Security + site lightning
              for hours of darkness
            </option>
          </Select>
          <FormErrorMessage>
            {errors.Security_Services && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Risk_Terrain)}>
          <HoverableFormLabel>Risk Terrain*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Risk Terrain"
            {...register("Risk_Terrain", {
              required: "This field is required",
            })}
            onFocus={() => setHoveredLabel("Risk Terrain")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Low Lying">Low Lying</option>
            <option value="Flat">Flat</option>
            <option value="Hilly">Hilly</option>
          </Select>
          <FormErrorMessage>
            {errors.Risk_Terrain && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Equipment_Storage)}>
          <HoverableFormLabel>Equipment Storage*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Equipment Storage"
            {...register("Equipment_Storage", {
              required: "This field is required",
            })}
            onFocus={() => setHoveredLabel("Equipment Storage")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Open Storage - Less than 10 meters from Erection / construction Site">
              Open Storage - Less than 10 meters from Erection / construction
              Site
            </option>
            <option value="In sheds/ Closed Premises - Less than 10 meters from Erection / Construction Site">
              In sheds/ Closed Premises - Less than 10 meters from Erection /
              Construction Site
            </option>
            <option value="In sheds/ Closed Premises - More than 10 meters from Erection / Construction Site">
              In sheds/ Closed Premises - More than 10 meters from Erection /
              Construction Site
            </option>
          </Select>
          <FormErrorMessage>
            {errors.Equipment_Storage && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Claim_Occured)}>
          <HoverableFormLabel>Claim Occurred*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Claim Occured"
            {...register("Claim_Occured", {
              required: "This field is required",
            })}
            onFocus={() => setHoveredLabel("Claim Occurred")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
          <FormErrorMessage>
            {errors.Claim_Occured && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <Box
          borderTop="1px solid"
          mt={2}
          mb={4}
          color="darkorchid"
          gridColumn="span 2"
        >
          <FormLabel color="darkorchid">
            Machinery description
          </FormLabel>
        </Box>

        <FormControl isInvalid={Boolean(errors?.Make)}>
          <HoverableFormLabel>Make*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            onFocus={() => setHoveredLabel("Make")}
            {...register("Make", { required: "This field is required" })}
          />
          <FormErrorMessage>
            {errors.Make && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Model)}>
          <HoverableFormLabel>Model*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            {...register("Model", { required: true })}
            onFocus={() => setHoveredLabel("Model")}
            onBlur={() => resetHoveredLabel()}
          />
          <FormErrorMessage>
            {errors.Model && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Manufacture_year)}>
          <HoverableFormLabel>Year of Manufacture</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            onFocus={() => setHoveredLabel("Year of Manufacture")}
            {...register("Manufacture_year", { required: true })}
            min="0"
          />
          <FormErrorMessage>
            {errors.Manufacture_year && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Price)}>
          <HoverableFormLabel>Price</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("Price", { required: true })}
            min="0"
            onFocus={() => setHoveredLabel("Price")}
            onBlur={() => resetHoveredLabel()}
          />
          <FormErrorMessage>
            {errors.Price && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <Box borderTop="1px solid" mt={2} mb={4} gridColumn="span 2">
          <FormLabel color="darkorchid">
            Optional Coverages{" "}
            <span style={{ fontSize: "11px", color: "gray" }}>(Sublimit)</span>
          </FormLabel>
        </Box>

        <FormControl>
          <HoverableFormLabel>Earthquake Sublimit</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("Earthquake_Sublimit")}
            min="0"
            onFocus={() => setHoveredLabel("Earthquake Sublimit")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Terrorism</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("Terrorism")}
            min="0"
            onFocus={() => setHoveredLabel("Terrorism")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Owners Surrounding Property</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("Owners_Surrounding_Property")}
            min="0"
            onFocus={() => setHoveredLabel("Owners Surrounding Property")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Debris Removal limit</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("Debris_Removal_limit")}
            min="0"
            onFocus={() => setHoveredLabel("Debris Removal limit")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>
            Maintenance Cover (Enter month)
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("Maintenance_Cover")}
            min="0"
            onFocus={() => setHoveredLabel("Maintenance Cover (Enter month)")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>
            Machinery plants and equipment
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("Machinery_plants")}
            min="0"
            onFocus={() => setHoveredLabel("Machinery plants and equipment")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>
        <Box borderTop="1px solid" mt={2} gridColumn="span 2" />
        <FormControl id="start_date" isInvalid={Boolean(errors?.start_date)}>
          <HoverableFormLabel>Bid's Start Date*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={todayDate}
            {...register("start_date", {
              required: "Start Date is required",
            })}
            onFocus={() => setHoveredLabel("Bid's Start Date")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.start_date && (
            <FormErrorMessage>{errors?.start_date?.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl id="end_date" isInvalid={Boolean(errors?.end_date)}>
          <HoverableFormLabel>Bid's End Date*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={watch("start_date")}
            onFocus={() => setHoveredLabel("Bid's End Date")}
            {...register("end_date", { required: "End Date is required" })}
          />
          {errors?.end_date && (
            <FormErrorMessage>{errors?.end_date?.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl id="policy_start_date">
          <HoverableFormLabel>Policy Start Date</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={todayDate}
            {...register("policy_start_date")}
            onFocus={() => setHoveredLabel("Policy Start Date")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl id="policy_end_date">
          <HoverableFormLabel>Policy End Date</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={watch("policy_start_date")}
            {...register("policy_end_date")}
            onFocus={() => setHoveredLabel("Policy End Date")}
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl
          id="expected_premium"
          isInvalid={Boolean(errors?.expected_premium)}
        >
          <Flex align="start">
            <HoverableFormLabel>Anticipated premium*</HoverableFormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Anticipated premium for acquiring this policy."
              />
            )}
          </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("expected_premium", {
              required: "Anticipated premium is required",
            })}
            onFocus={() => setHoveredLabel("Anticipated premium")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.expected_premium && (
            <FormErrorMessage>
              {errors?.expected_premium?.message}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl id="sum_insured" isInvalid={Boolean(errors?.sum_insured)}>
          <HoverableFormLabel>Sum insured*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("sum_insured", {
              required: "Sum insured is required",
            })}
            onFocus={() => setHoveredLabel("Sum insured")}
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.sum_insured && (
            <FormErrorMessage>{errors?.sum_insured?.message}</FormErrorMessage>
          )}
        </FormControl>
        {watch("policytype") === "Renewal" && (
          <HStack gridColumn="span 2" gap="20px">
            <FormControl
              id="previous_insurer"
              isInvalid={Boolean(errors?.previous_insurer)}
            >
              <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...register("previous_insurer", {
                  required: "Previous insurer is required",
                })}
                onFocus={() => setHoveredLabel("Previous Insurer")}
                onBlur={() => resetHoveredLabel()}
              />
              {errors?.previous_insurer && (
                <FormErrorMessage>
                  {errors?.previous_insurer?.message}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              id="previous_policy_expiry"
              isInvalid={Boolean(errors?.previous_policy_expiry)}
            >
              <HoverableFormLabel>Previous policy expiry*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...register("previous_policy_expiry", {
                  required: "Previous policy expiry is required",
                })}
                onFocus={() => setHoveredLabel("Previous policy expiry")}
                onBlur={() => resetHoveredLabel()}
              />
              {errors?.previous_policy_expiry && (
                <FormErrorMessage>
                  {errors?.previous_policy_expiry?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </HStack>
        )}
      </SimpleGrid>
      <HStack w="100%" justify="space-between" align="center" mt="20px">
        <Button
          type="button"
          borderRadius="21px"
          onClick={() => setStep(step - 1)}
        >
          Previous
        </Button>
        <Button
          type="submit"
          borderRadius="21px"
          colorScheme="green"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Next
        </Button>
      </HStack>
    </form>
  );
};

export default ContractorssPlantMachinery;
