import {
  Button,
  Flex,
  Image,
  Skeleton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { TbFileStar } from "react-icons/tb";

import { theme } from "../../partials/utils/Contants";
import BidCard from "../../components/BidCard";
import { useNavigate } from "react-router-dom";
import SanbotAI from "../../components/SanbotAI";
import { useEffect, useState } from "react";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import noBidImg from "../../partials/assets/images/emoji-no-bid.png";
import { useStore } from "../../store/store";
import QuoteComparison from "../../components/QuoteComparison";
import { RxCross1 } from "react-icons/rx";

const BidCentre = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const {
    setStep,
    setSelectedCategories,
    selectedCompany,
    setSelectedQuotesList,
  } = useStore();
  const [selectedBid, setSelectedBid] = useState({
    id: null,
    bidId: null,
    action: "",
    data: {},
  });
  const [liveBidsLsit, setLiveBidsLsit] = useState([]);
  const [draftedBidsList, setDraftedBidsList] = useState([]);
  const [completedBidsList, setCompletedBidsList] = useState([]);
  const [engagedBidsList, setEngagedBidsList] = useState([]);
  const [archivedBidsList, setArchivedBidsList] = useState([]);

  const [isLiveLoading, setIsLiveLoading] = useState(false);
  const [isDraftedLoading, setIsDraftedLoading] = useState(false);
  const [isCompletedLoading, setIsCompletedLoading] = useState(false);
  const [isEngagedLoading, setIsEngagedLoading] = useState(false);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);

  function fetchLiveBids() {
    setIsLiveLoading(true);
    useFetch
      .get(`api/${selectedCompany}/live-bids-client/`)
      .then((response) => {
        setLiveBidsLsit(response?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLiveLoading(false));
  }
  function fetchDraftedBids() {
    setIsDraftedLoading(true);
    useFetch
      .get(`api/${selectedCompany}/drafted-bids/`)
      .then((response) => {
        setDraftedBidsList(response?.data?.message);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsDraftedLoading(false));
  }

  function fetchCompletedBids() {
    setIsCompletedLoading(true);
    useFetch
      .get(`api/${selectedCompany}/completed-bids-client/`)
      .then((response) => {
        setCompletedBidsList(response?.data?.completed_bids);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsCompletedLoading(false));
  }
  function fetchEngagedbidBids() {
    setIsEngagedLoading(true);
    useFetch
      .get(`api/${selectedCompany}/engaged-bids-client/`)
      .then((response) => {
        setEngagedBidsList(response?.data?.engaged);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsEngagedLoading(false));
  }
  function fetchArchivedBids() {
    setIsArchiveLoading(true);
    useFetch
      .get(`api/${selectedCompany}/archieved-bids/`)
      .then((res) => {
        setArchivedBidsList(res?.data?.details);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => setIsArchiveLoading(false));
  }
  function fetchAfterComplete() {
    fetchEngagedbidBids();
    fetchCompletedBids();
  }
  function fetchAll() {
    fetchLiveBids();
    fetchDraftedBids();
    fetchCompletedBids();
    fetchEngagedbidBids();
    fetchArchivedBids();
  }
  useEffect(() => {
    if (selectedCompany) {
      fetchAll();
    }
    setSelectedBid({
      id: null,
      bidId: null,
      action: "",
      data: {},
    });
  }, [selectedCompany]);

  const handleTabChange = (index: number) => {
    setSelectedTabIndex(index);
    setSelectedBid({
      id: null,
      bidId: null,
      action: "",
      data: {},
    });
  };

  useEffect(() => {
    return () => {
      setSelectedQuotesList([]);
    };
  }, []);
  return (
    <>
      <Flex height="100%" width="100%">
        <Flex
          direction="column"
          padding={selectedBid ? "30px 15px" : "30px 60px 30px 30px"}
          flex={2}
          gap="10px"
        >
          <Flex align="center" w="100%" justify="space-between">
            <Text as="h2" fontSize="24px" fontWeight="500">
              Bid Centre
            </Text>
            <Button
              rightIcon={<TbFileStar />}
              borderRadius="24px"
              bg="black"
              colorScheme="black"
              color="#F6F6F6"
              w="143px"
              height="24px"
              fontSize="10px"
              fontWeight="500"
              onClick={() => {
                setStep(1);
                navigate("/create-bid");
                setSelectedCategories([]);
              }}
            >
              Create New Bid
            </Button>
          </Flex>
          <Tabs
            variant="unstyled"
            width="100%"
            index={selectedTabIndex}
            onChange={handleTabChange}
          >
            <TabList gap="40px">
              <Tab
                height="47px"
                fontSize="0.8rem"
                fontWeight="500"
                color={theme.color.GRAY_LABEL}
                _selected={{
                  color: "black",
                }}
                onClick={() =>
                  setSelectedBid({
                    id: null,
                    bidId: null,
                    action: "",
                    data: {},
                  })
                }
              >
                Live
              </Tab>
              <Tab
                height="47px"
                fontSize="0.8rem"
                fontWeight="500"
                color={theme.color.GRAY_LABEL}
                _selected={{
                  color: "black",
                }}
                onClick={() =>
                  setSelectedBid({
                    id: null,
                    bidId: null,
                    action: "",
                    data: {},
                  })
                }
              >
                Engaged
              </Tab>
              <Tab
                height="47px"
                fontSize="0.8rem"
                fontWeight="500"
                color={theme.color.GRAY_LABEL}
                _selected={{
                  color: "black",
                }}
                onClick={() =>
                  setSelectedBid({
                    id: null,
                    bidId: null,
                    action: "",
                    data: {},
                  })
                }
              >
                Draft
              </Tab>

              <Tab
                height="47px"
                fontSize="0.8rem"
                fontWeight="500"
                color={theme.color.GRAY_LABEL}
                _selected={{
                  color: "black",
                }}
                onClick={() =>
                  setSelectedBid({
                    id: null,
                    bidId: null,
                    action: "",
                    data: {},
                  })
                }
              >
                Completed
              </Tab>
              <Tab
                height="47px"
                fontSize="0.8rem"
                fontWeight="500"
                color={theme.color.GRAY_LABEL}
                _selected={{
                  color: "black",
                }}
                onClick={() =>
                  setSelectedBid({
                    id: null,
                    bidId: null,
                    action: "",
                    data: {},
                  })
                }
              >
                Archived
              </Tab>
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="black"
              borderRadius="1px"
            />
            <TabPanels>
              <TabPanel px="0px">
                {!isLiveLoading && liveBidsLsit?.length ? (
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    maxH="calc(100vh - 175px)"
                    height="100%"
                    overflowY="auto"
                    className="hide-scrollbar"
                  >
                    {liveBidsLsit?.map((item: any) => {
                      const form = item?.form_properties
                        ? typeof item?.form_properties === "string"
                          ? JSON.parse(item?.form_properties)
                          : item?.form_properties
                        : {};
                      return (
                        <BidCard
                          data={item}
                          type="live"
                          fetchData={fetchLiveBids}
                          setSelectedBid={setSelectedBid}
                          selectedBid={selectedBid}
                          form={form}
                          fetchAll={fetchAll}
                        />
                      );
                    })}
                  </Flex>
                ) : (
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    justify="center"
                    minH="200px"
                  >
                    {isLiveLoading ? (
                      <Skeleton height="110px" w="100%" borderRadius="16px" />
                    ) : (
                      <Flex
                        direction="column"
                        align="center"
                        gap="25px"
                        pt="40px"
                      >
                        <Text fontWeight="600">
                          No live bids yet. Create your bid now
                        </Text>
                        <Image src={noBidImg} height="182px" width="207px" />
                        <Button
                          borderRadius="24px"
                          bg="black"
                          colorScheme="black"
                          color="white"
                          padding="4px 14px"
                          fontSize="12px"
                          fontWeight="500"
                          w="104px"
                          h="24px"
                          onClick={() => navigate("/create-bid")}
                        >
                          Create Bid
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                )}
              </TabPanel>

              <TabPanel px="0px">
                {!isEngagedLoading && engagedBidsList?.length ? (
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    overflowY="auto"
                    maxH="calc(100vh - 175px)"
                    height="100%"
                    className="hide-scrollbar"
                  >
                    {engagedBidsList?.map((item: any) => {
                      const form = item?.form_properties
                        ? typeof item?.form_properties === "string"
                          ? JSON.parse(item?.form_properties)
                          : item?.form_properties
                        : {};

                      return (
                        <BidCard
                          data={item}
                          form={form}
                          type="engaged"
                          fetchData={fetchEngagedbidBids}
                          selectedBid={selectedBid}
                          setSelectedBid={setSelectedBid}
                          fetchAfterComplete={fetchAfterComplete}
                          fetchAll={fetchAll}
                        />
                      );
                    })}
                  </Flex>
                ) : (
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    justify="center"
                    minH="200px"
                  >
                    {isEngagedLoading ? (
                      <Skeleton height="110px" w="100%" borderRadius="16px" />
                    ) : (
                      <Flex
                        direction="column"
                        align="center"
                        gap="25px"
                        pt="40px"
                      >
                        <Text fontWeight="600">
                          No engaged bids yet. Create your bid now
                        </Text>
                        <Image src={noBidImg} height="182px" width="207px" />
                        <Button
                          borderRadius="24px"
                          bg="black"
                          colorScheme="black"
                          color="white"
                          padding="4px 14px"
                          fontSize="12px"
                          fontWeight="500"
                          w="104px"
                          h="24px"
                          onClick={() => navigate("/create-bid")}
                        >
                          Create Bid
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                )}
              </TabPanel>
              <TabPanel px="0px">
                {!isDraftedLoading && draftedBidsList?.length ? (
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    maxH="calc(100vh - 175px)"
                    height="100%"
                    overflowY="auto"
                    className="hide-scrollbar"
                  >
                    {draftedBidsList?.map((item: any) => {
                      const form = item?.form_properties
                        ? typeof item?.form_properties === "string"
                          ? JSON.parse(item?.form_properties)
                          : item?.form_properties
                        : {};
                      return (
                        <BidCard
                          data={item}
                          form={form}
                          type="draft"
                          fetchData={fetchDraftedBids}
                          fetchAll={fetchAll}
                        />
                      );
                    })}
                  </Flex>
                ) : (
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    justify="center"
                    minH="200px"
                  >
                    {isDraftedLoading ? (
                      <Skeleton height="110px" w="100%" borderRadius="16px" />
                    ) : (
                      <Flex
                        direction="column"
                        align="center"
                        gap="25px"
                        pt="40px"
                      >
                        <Text fontWeight="600">
                          No drafted bids yet. Create your bid now
                        </Text>
                        <Image src={noBidImg} height="182px" width="207px" />
                        <Button
                          borderRadius="24px"
                          bg="black"
                          colorScheme="black"
                          color="white"
                          padding="4px 14px"
                          fontSize="12px"
                          fontWeight="500"
                          w="104px"
                          h="24px"
                          onClick={() => navigate("/create-bid")}
                        >
                          Create Bid
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                )}
              </TabPanel>
              <TabPanel px="0px">
                {!isCompletedLoading && completedBidsList?.length ? (
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    overflowY="auto"
                    maxH="calc(100vh - 175px)"
                    height="100%"
                    className="hide-scrollbar"
                  >
                    {completedBidsList?.map((item: any) => {
                      const form = item?.form_properties
                        ? typeof item?.form_properties === "string"
                          ? JSON.parse(item?.form_properties)
                          : item?.form_properties
                        : {};
                      return (
                        <BidCard
                          data={item}
                          form={form}
                          type="completed"
                          fetchData={fetchAfterComplete}
                          selectedBid={selectedBid}
                          setSelectedBid={setSelectedBid}
                          completedPolicy={item?.policy}
                          quoteForCompleted={item?.quote}
                          fetchAll={fetchAll}
                        />
                      );
                    })}
                  </Flex>
                ) : (
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    justify="center"
                    minH="200px"
                  >
                    {isCompletedLoading ? (
                      <Skeleton height="110px" w="100%" borderRadius="16px" />
                    ) : (
                      <Flex
                        direction="column"
                        align="center"
                        gap="25px"
                        pt="40px"
                      >
                        <Text fontWeight="600">
                          No completed bids yet. Create your bid now
                        </Text>
                        <Image src={noBidImg} height="182px" width="207px" />
                        <Button
                          borderRadius="24px"
                          bg="black"
                          colorScheme="black"
                          color="white"
                          padding="4px 14px"
                          fontSize="12px"
                          fontWeight="500"
                          w="104px"
                          h="24px"
                          onClick={() => navigate("/create-bid")}
                        >
                          Create Bid
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                )}
              </TabPanel>
              <TabPanel px="0px">
                {!isArchiveLoading && archivedBidsList?.length ? (
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    overflowY="auto"
                    maxH="calc(100vh - 175px)"
                    height="100%"
                    className="hide-scrollbar"
                  >
                    {archivedBidsList.map((item: any) => {
                      const form = item?.form_properties
                        ? typeof item?.form_properties === "string"
                          ? JSON.parse(item?.form_properties)
                          : item?.form_properties
                        : {};
                      return (
                        <BidCard
                          data={item}
                          form={form}
                          type="inactive"
                          showActions={false}
                          fetchAll={fetchAll}
                        />
                      );
                    })}
                  </Flex>
                ) : (
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    justify="center"
                    minH="200px"
                  >
                    {isArchiveLoading ? (
                      <Skeleton height="110px" w="100%" borderRadius="16px" />
                    ) : (
                      <Flex
                        direction="column"
                        align="center"
                        gap="25px"
                        pt="40px"
                      >
                        <Text fontWeight="600">No bid arhived yet.</Text>
                      </Flex>
                    )}
                  </Flex>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>

        <SanbotAI
          selectedBid={selectedBid}
          setSelectedBid={setSelectedBid}
          onComparisonOpen={onOpen}
          onComparisonClose={onClose}
        />
      </Flex>
      <QuoteComparison isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
};

export default BidCentre;
