import {
  Button,
  Flex,
  Skeleton,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

import { GoArrowUpRight } from "react-icons/go";

import { useNavigate } from "react-router-dom";
import { theme } from "../../../partials/utils/Contants";
import BidderBidCard from "../../../components/BidderBidCard";
import { useEffect, useState } from "react";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import BidderSanbotAI from "../../../components/Bidder/Bidder-Sanbot";
import { useFetchUser } from "../../../hooks/User";
import { statusOrder } from "../Bidder-BidCenter";

const BidderHome = () => {
  const navigate = useNavigate();
  const user = useFetchUser();
  const [selectedBid, setSelectedBid] = useState({ id: null, action: "" });

  const [liveBidsLsit, setLiveBidsLsit] = useState([]);
  const [engagedBidsList, setEngagedBidsList] = useState([]);
  const [completedBidsList, setCompletedBidsList] = useState<any>([]);

  const [isLiveLoading, setIsLiveLoading] = useState(false);
  const [isEngagedLoading, setIsEngagedLoading] = useState(false);
  const [isCompletedLoading, setIsCompletedLoading] = useState(false);
  function fetchLiveBids() {
    setIsLiveLoading(true);
    useFetch
      .get("api/live-bids-bidder/")
      .then((response) => {
        const topBids = response?.data?.live_bids?.slice(0, 3) || [];
        setLiveBidsLsit(topBids);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLiveLoading(false));
  }
  function fetchEngagedBids() {
    setIsEngagedLoading(true);
    useFetch
      .get("api/engaged_bids-bidder/")
      .then((response) => {
        const topBids = response?.data?.engaged?.slice(0, 3) || [];
        const engaged = topBids?.map((item: any) => {
          const isAccepted = item?.interest?.status === "accepted";
          let status = item?.interest?.status || "pending";
          if (isAccepted) {
            status = "Accepted";
            if (item?.quote_status === "pending") {
              status = "Waiting for Quote Approval";
            } else if (item?.quote_status === "accepted") {
              status = "Quote Accepted";
            } else if (item?.quote_status === "rejected") {
              status = "Quote Rejected";
            }
          }
          return { ...item, status };
        });

        const sortedEngaged = engaged?.sort((a: any, b: any) => {
          const statusComparison =
            statusOrder[a.status] - statusOrder[b.status];

          if (statusComparison === 0) {
            return b.id - a.id;
          }

          return statusComparison;
        });
        setEngagedBidsList(sortedEngaged);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsEngagedLoading(false));
  }
  function fetchCompletedBids() {
    setIsCompletedLoading(true);
    useFetch
      .get("api/completed-bids-bidder")
      .then((response) => {
        const topBids = response?.data?.completed_bids?.slice(0, 3) || [];

        setCompletedBidsList(topBids);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsCompletedLoading(false));
  }
  function fetchAll() {
    fetchLiveBids();
    fetchEngagedBids();
    fetchCompletedBids();
  }
  useEffect(() => {
    fetchAll();
  }, []);
  return (
    <Flex height="100%" width="100%">
      <Flex
        direction="column"
        padding={selectedBid ? "30px 15px" : "30px 60px 30px 30px"}
        flex={2}
        gap="10px"
      >
        <Flex align="center" w="100%" justify="space-between">
          <Text as="h2" fontSize="24px" fontWeight="500">
            Bid Centre{" "}
          </Text>
          <Button
            rightIcon={<GoArrowUpRight />}
            borderRadius="24px"
            bg="white"
            colorScheme="white"
            color="black"
            border="1px solid #dadada"
            minW="104px"
            _hover={{ filter: "brightness(1.2)", color: "gray.600" }}
            onClick={() => {
              navigate("/bid-centre");
            }}
          >
            View all
          </Button>
        </Flex>
        <Tabs position="relative" variant="unstyled" width="100%">
          <TabList gap="40px">
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ id: null, action: "" })}
            >
              Live Bids
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ id: null, action: "" })}
            >
              Engaged Bids
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ id: null, action: "" })}
            >
              Completed Bids
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="black"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel px="0px">
              {!isLiveLoading && liveBidsLsit?.length ? (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  overflowY="auto"
                  maxH="calc(100vh - 190px)"
                  className="hide-scrollbar"
                >
                  {liveBidsLsit?.map((item: any) => {
                    const form = item?.form_properties
                      ? typeof item?.form_properties === "string"
                        ? JSON.parse(item?.form_properties)
                        : item?.form_properties
                      : {};
                    return (
                      <BidderBidCard
                        fetchAll={fetchAll}
                        data={item}
                        type="live"
                        fetchData={fetchLiveBids}
                        setSelectedBid={setSelectedBid}
                        selectedBid={selectedBid}
                        bidId={item?.id}
                        bidderCount={item?.bidders}
                        form={form}
                      />
                    );
                  })}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isLiveLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Text>No Live Bids Available</Text>
                  )}
                </Flex>
              )}
            </TabPanel>
            <TabPanel px="0px">
              {!isEngagedLoading && engagedBidsList?.length ? (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  overflowY="auto"
                  maxH="calc(100vh - 190px)"
                  className="hide-scrollbar"
                >
                  {engagedBidsList?.map((item: any) => {
                    const form = item?.form_properties
                      ? typeof item?.form_properties === "string"
                        ? JSON.parse(item?.form_properties)
                        : item?.form_properties
                      : {};

                    const quote = item?.quote.find(
                      (q: any) => q?.quote_status === "Accepted"
                    );
                    const isAccepted = item?.interest?.status === "accepted";
                    let status = item?.interest?.status || "pending";
                    if (isAccepted) {
                      status = "Accepted";
                      if (item?.quote_status === "pending") {
                        status = "Waiting for Quote Approval";
                      } else if (item?.quote_status === "accepted") {
                        status = "Quote Accepted";
                      } else if (item?.quote_status === "rejected") {
                        status = "Quote Rejected";
                      }
                    }

                    return (
                      <BidderBidCard
                        fetchAll={fetchAll}
                        data={item}
                        type="engaged"
                        fetchData={fetchEngagedBids}
                        bidId={item?.id}
                        pdfFile={item?.pdf_file}
                        quoteFile={item?.policy_file}
                        setSelectedBid={setSelectedBid}
                        selectedBid={selectedBid}
                        quote={quote}
                        engagedStatus={status}
                        form={form}
                      />
                    );
                  })}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isEngagedLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Text>No Engaged Bids Available</Text>
                  )}
                </Flex>
              )}
            </TabPanel>
            <TabPanel px="0px">
              {!isCompletedLoading && completedBidsList?.length ? (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                   maxH="calc(100vh - 190px)"
                  overflowY="auto"
                  className="hide-scrollbar"
                >
                  {completedBidsList?.map((item: any) => {
                    const form = item?.form_properties
                      ? typeof item?.form_properties === "string"
                        ? JSON.parse(item?.form_properties)
                        : item?.form_properties
                      : {};
                    return (
                      <BidderBidCard
                        fetchAll={fetchAll}
                        data={item}
                        type="completed"
                        fetchData={fetchEngagedBids}
                        bidId={item?.id}
                        pdfFile={item?.policy?.policy_file}
                        quoteFile={item?.quote?.quote_file}
                        setSelectedBid={setSelectedBid}
                        selectedBid={selectedBid}
                        bidderCount={item?.bidders}
                        form={form}
                        quote={item?.quote}
                      />
                    );
                  })}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isCompletedLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Text>No Completed Bids Available</Text>
                  )}
                </Flex>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <BidderSanbotAI
        selectedBid={selectedBid}
        setSelectedBid={setSelectedBid}
      />
    </Flex>
  );
};

export default BidderHome;
