import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Text,
  useDisclosure,
  Modal,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  ModalBody,
  Input,
  Button,
  FormErrorMessage,
  ModalContent,
  ModalCloseButton,
  useToast,
  Icon,
  Spinner,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { TbUserBolt } from "react-icons/tb";
import { useEffect, useMemo, useState } from "react";
import { RiEditCircleLine } from "react-icons/ri";
import { FaRegTrashCan } from "react-icons/fa6";

import Select, { MultiValue } from "react-select";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { CiSearch } from "react-icons/ci";
// import bidderPermissions from './bidder-permissions.json'
interface PermissionOption {
  label: string;
  value: string;
  slug?: any;
}
const BidderRolesPage = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [searchText, setSearchText] = useState("");
  const [errors, setErrors] = useState({ name: "", permissions: "" });
  const [name, setName] = useState("");
  const [permissions, setPermissions] = useState<any>([]);
  const [rolesList, setRolesList] = useState([]);
  const [permissionsList, setPermissionsList] = useState<any>([]);
  const [bidderPermissions, setBidderPermissions] = useState<any>();
  function fetchRolesList() {
    setIsLoading(true);
    useFetch
      .get(`api/add-bidder-role/`)
      .then((res) => {
        setIsLoading(false);

        setRolesList(res?.data);
      })
      .catch((error) => {
        setIsLoading(false);

        console.log(error);
      });
  }
  const validateForm = () => {
    const error = { name: "", permissions: "" };
    let isValid = true;

    if (!name.trim()) {
      error.name = "Name cannot be empty.";
      isValid = false;
    }

    if (permissions.length === 0) {
      error.permissions = "At least one permission must be selected.";
      isValid = false;
    }
    setErrors(error);
    return isValid;
  };

  const onSubmit = () => {
    if (!!validateForm) {
      setIsSubmitting(true);
      const fileteredPermissions = permissions.map((x: any) => x.value);
      let body: any = {
        name,
        permissions: fileteredPermissions,
      };
      if (selectedItem?.id) {
        body = {
          name,
          permissions: fileteredPermissions,
          bidder_role_id: selectedItem?.id,
        };
        useFetch
          .patch(`api/add-bidder-role/`, body)
          .then((res) => {
            setIsSubmitting(false);

            toast({
              title: `Role Updated successfully`,
              description: "",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            fetchRolesList();
            onClose();
            setSelectedItem(null);
          })
          .catch((error) => {
            setIsSubmitting(false);
            console.log("error", error);

            toast({
              title: error?.response?.data?.error || `Something went wrong`,
              description: "",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            console.log(error);
          });
      } else {
        useFetch
          .post(`api/add-bidder-role/`, body)
          .then((res) => {
            setIsSubmitting(false);

            toast({
              title: `Role added successfully`,
              description: "",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            fetchRolesList();
            onClose();
            setSelectedItem(null);
          })
          .catch((error) => {
            setIsSubmitting(false);
            console.log("error", error);

            toast({
              title: error?.response?.data?.error || `Something went wrong`,
              description: "",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
            console.log(error);
          });
      }
    }
  };
  const handleChange = (selectedOptions: MultiValue<PermissionOption>) => {
    setPermissions(selectedOptions);
  };
  const handleDelete = () => {
    setIsSubmitting(true);

    useFetch
      .delete(`api/add-bidder-role/?bidder_role_id=${selectedItem?.id}`)
      .then((res) => {
        setIsSubmitting(false);

        toast({
          title: `Deleted Successfully.`,
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onDeleteClose();
        fetchRolesList();
        setSelectedItem(null);
      })
      .catch((error) => {
        setIsSubmitting(false);

        console.log(error);
        toast({
          title: `Something went wrong`,
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };
  const handleClose = () => {
    onClose();
    setName("");
    setPermissions([]);
  };
  const filteredData = useMemo(
    () =>
      (searchText === ""
        ? [...rolesList]
        : rolesList.filter((team: any) =>
            team?.name?.toLowerCase().includes(searchText.toLowerCase())
          )
      ).sort((a: any, b: any) =>
        a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
      ),
    [rolesList, searchText]
  );
  function fethcBidderPermissions() {
    useFetch
      .get(`api/bidder-permissions/`)
      .then((res) => {
        const permission = res.data?.bidder_permissions?.map(
          (p: { name: string }) => ({ label: p?.name, value: p?.name })
        );
        setBidderPermissions(permission);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  useEffect(() => {
    fetchRolesList();
    fethcBidderPermissions();
  }, []);
  console.log("selectedItem", selectedItem);

  useEffect(() => {
    if (bidderPermissions?.length) {
      const list = selectedItem?.permissions?.map((p: any) => ({
        label: p?.name,
        value: p?.name,
      }));
      setPermissions(list);
    }
  }, [bidderPermissions]);
  useEffect(() => {
    if (selectedItem?.id) {
      setName(selectedItem?.name);

      const data = selectedItem?.permissions?.map((item: any) => ({
        label: item?.name,
        value: item?.name,
        slug: item?.name,
      }));

      setPermissions(data);
    } else {
      setName("");
      setPermissions([]);
    }
  }, [selectedItem]);
  return (
    <>
      {!isLoading ? (
        <Flex
          w="100%"
          direction="column"
          gap="40px"
          bg="white"
          padding="20px"
          borderRadius="21px"
        >
          <Flex w="100%" justify="space-between" align="center">
            <Text fontSize="1.2rem" fontWeight="700" color="#25396f" ps="5px">
              Role Management
            </Text>
            <Button
              rightIcon={<TbUserBolt />}
              borderRadius="24px"
              bg="black"
              colorScheme="black"
              color="white"
              padding="0px 11px"
              fontSize="12px"
              fontWeight="500"
              w="142px"
              h="24px"
              onClick={() => {
                onOpen();
                setSelectedItem(null);
              }}
            >
              Add new role
            </Button>
          </Flex>
          <InputGroup>
            <InputLeftElement>
              <CiSearch />
            </InputLeftElement>
            <Input
              borderRadius="16px"
              type="text"
              w="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              placeholder="Search"
              fontSize="12px"
              fontWeight="500"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </InputGroup>

          <TableContainer borderRadius="21px" bg="gray.50">
            <Table variant="striped" colorScheme="gray">
              <Thead bg="black" color="white">
                <Tr>
                  <Th color="white">No.</Th>
                  <Th color="white">Role type</Th>
                  <Th color="white">Permissions</Th>
                  <Th color="white">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredData?.map((item: any, index: number) => {
                  const permissionsArray = item?.permissions?.map(
                    (p: any) => p?.name
                  );
                  return (
                    <Tr key={item}>
                      <Td>{index + 1}</Td>
                      <Td>{item?.name}</Td>
                      <Td>
                        <Text whiteSpace="break-spaces">
                          {permissionsArray.join(", ")}
                        </Text>
                      </Td>
                      <Td>
                        <Flex align="center" gap="10px">
                          <Icon
                            as={RiEditCircleLine}
                            fontSize="18px"
                            cursor="pointer"
                            onClick={() => {
                              setSelectedItem(item);
                              onOpen();
                            }}
                          />
                          <Icon
                            as={FaRegTrashCan}
                            fontSize="18px"
                            cursor="pointer"
                            onClick={() => {
                              setSelectedItem(item);
                              onDeleteOpen();
                            }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent borderRadius="21px" pb="35px">
              <ModalHeader>{selectedItem ? "Edit" : "Add"} Role</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex direction="column" gap="12px" align="center" w="100%">
                  <FormControl isInvalid={Boolean(errors.name)}>
                    <FormLabel htmlFor="name">Role Type*</FormLabel>
                    <Input
                      id="name"
                      type="text"
                      placeholder="Enter name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    {errors.name && (
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={Boolean(errors.permissions)}>
                    <FormLabel htmlFor="permissions">Permissions*</FormLabel>
                    <Select<PermissionOption, true>
                      value={permissions}
                      onChange={handleChange}
                      options={bidderPermissions}
                      isMulti
                    />
                    {errors.permissions && (
                      <FormErrorMessage>{errors.permissions}</FormErrorMessage>
                    )}
                  </FormControl>
                  <Button
                    onClick={onSubmit}
                    w="fit-content"
                    borderRadius="21px"
                    colorScheme="green"
                    mt="12px"
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
          <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
            <ModalOverlay />
            <ModalContent borderRadius="21px" pb="35px">
              <ModalCloseButton />
              <ModalBody pt="50px">
                <Flex direction="column" align="center">
                  <Text fontWeight="600">
                    Are you sure you want to Delete this Role
                  </Text>
                  {selectedItem?.name && (
                    <Text>
                      <b>Role :</b> {selectedItem?.name}
                    </Text>
                  )}
                </Flex>
                <Flex
                  align="center"
                  gap="20px"
                  w="full"
                  justify="center"
                  mt="20px"
                >
                  <Button borderRadius="21px" onClick={onDeleteClose}>
                    Cancel
                  </Button>
                  <Button
                    borderRadius="21px"
                    colorScheme="red"
                    onClick={handleDelete}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                  >
                    Delete
                  </Button>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Flex>
      ) : (
        <Flex w="100%" align="end" justify="center" h="30vh">
          {isLoading ? <Spinner size="lg" /> : <Text>No Roles found</Text>}
        </Flex>
      )}
    </>
  );
};

export default BidderRolesPage;
