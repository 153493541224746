import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import "../form.css";
import { useStore } from "../../../store/store";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import {
  BEProductMap,
  convertProductName,
  flattenObject,
} from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
import { changeSattusToDraft } from "../Forms";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";

interface FormData {
  Nature_of_work: string;
  Contractor_name?: string;
  employeenumber: number;
  policytype: string;
  insurer: string;
  comp_location: string;
  Claim_Experience: string;
  Any_other_details?: string;
  Unskilled: number;
  Wages_per_month1: number;
  Semi_skilled: number;
  Wages_per_month2: number;
  Skilled: number;
  Wages_per_month3: number;
  Occupational_Diseases?: number;
  Medical_Expenses?: number;
  Coverage_for_Contractors?: number;
  start_date: string;
  end_date: string;
  policy_start_date?: string;
  policy_end_date?: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer?: string;
  previous_policy_expiry?: string;
}

const WorkmenCompensation = ({
  product,
  editData,
  mode,
  selectedCompany,
  setCreatedBid,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { step, setStep, setHoveredLabel, resetHoveredLabel } = useStore();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      employeenumber: 0,
      Unskilled: 0,
      Wages_per_month1: 0,
      Wages_per_month2: 0,
      Wages_per_month3: 0,
      Skilled: 0,
      Semi_skilled: 0,
      Occupational_Diseases: 0,
      Medical_Expenses: 0,
      Coverage_for_Contractors: 0,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
      expected_premium: 0,
      sum_insured: 0,
    },
  });

  const onSubmit = (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    formData.append("comp_id", selectedCompany.toString() || "");
    const parsedProduct = BEProductMap[product];

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }

    formData.append("form_properties", JSON.stringify({ ...data }));
    if (editData?.id) {
      setIsSubmitting(true);
      useFetch
        .put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData
        )
        .then((res) => {
          setCreatedBid(res.data?.data?.id);
          toast({
            title: `Bid updated successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      setIsSubmitting(true);
      useFetch
        .post(`/api/${selectedCompany}/store-bid/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setCreatedBid(res.data?.id);
          toast({
            title: `Bid created successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    }
  };
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormData, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);

  const Unskilled = +watch("Unskilled");
  const Wages_per_month1 = +watch("Wages_per_month1");
  const SemiSkilled = +watch("Semi_skilled");
  const Wages_per_month2 = +watch("Wages_per_month2");
  const Skilled = +watch("Skilled");
  const Wages_per_month3 = +watch("Wages_per_month3");

  useEffect(() => {
    const sum1 = Unskilled * Wages_per_month1;
    const sum2 = SemiSkilled * Wages_per_month2;
    const sum3 = Skilled * Wages_per_month3;
    const sum = sum1 + sum2 + sum3;
    setValue("sum_insured", sum);
  }, [
    Unskilled,
    Wages_per_month1,
    Wages_per_month2,
    Wages_per_month3,
    Skilled,
    SemiSkilled,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.Nature_of_work}>
            <HoverableFormLabel>Nature of work*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Nature_of_work", {
                required: "Nature of work is required",
              })}
              onFocus={() => setHoveredLabel("Nature of work")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.Nature_of_work && (
              <Text color="red.500" fontSize="sm">
                {errors?.Nature_of_work?.message}
              </Text>
            )}
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Contractor name</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Contractor_name")}
              onFocus={() => setHoveredLabel("Contractor name")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.employeenumber}>
            <HoverableFormLabel>Employee Count*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("employeenumber", {
                  required: "Employee count is required",
                })}
                onFocus={() => setHoveredLabel("Employee Count")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
            {errors?.employeenumber && (
              <Text color="red.500" fontSize="sm">
                {errors?.employeenumber?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.policytype}>
            <HoverableFormLabel>Policy Type*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select policy type"
              {...register("policytype", {
                required: "Policy type is required",
              })}
              onFocus={() => setHoveredLabel("Policy Type")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="New">New</option>
              <option value="Renewal">Renewal</option>
            </Select>
            {errors?.policytype && (
              <Text color="red.500" fontSize="sm">
                {errors?.policytype?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.insurer}>
            <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Preference"
              {...register("insurer", {
                required: "Bidder preference is required",
              })}
              onFocus={() => setHoveredLabel("Bidder Preference")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="Both">Both</option>
              <option value="Brokers">Brokers</option>
              <option value="INS_COMP">Insurance Company</option>
            </Select>
            {errors?.insurer && (
              <Text color="red.500" fontSize="sm">
                {errors?.insurer?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.comp_location}>
            <HoverableFormLabel>
              Where is your company located?*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("comp_location", {
                required: "Company location is required",
              })}
              onFocus={() => setHoveredLabel("Where is your company located?")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.comp_location && (
              <Text color="red.500" fontSize="sm">
                {errors?.comp_location?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.Claim_Experience}>
            <HoverableFormLabel>
              Claim Experience for past 3 years*
            </HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("Claim_Experience", {
                required: "Claim experience is required",
              })}
              onFocus={() =>
                setHoveredLabel("Claim Experience for past 3 years")
              }
              onBlur={() => resetHoveredLabel()}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </Select>
            {errors?.Claim_Experience && (
              <Text color="red.500" fontSize="sm">
                {errors?.Claim_Experience?.message}
              </Text>
            )}
          </FormControl>

          <FormControl>
            <HoverableFormLabel>
              Any other Details That Need to be Shared
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Any_other_details")}
              onFocus={() =>
                setHoveredLabel("Any other Details That Need to be Shared")
              }
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>
        </HStack>
        <Box borderTop="1px solid" mt={6} pt={4}>
          <Text fontWeight="bold" color="darkorchid">
            Particulars of work to be covered in Detail
          </Text>
        </Box>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.Unskilled}>
            <HoverableFormLabel>Unskilled Workers*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Unskilled", {
                  required: "Unskilled count is required",
                })}
                onFocus={() => setHoveredLabel("Unskilled Workers")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
            {errors?.Unskilled && (
              <Text color="red.500" fontSize="sm">
                {errors?.Unskilled?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.Wages_per_month1}>
            <HoverableFormLabel>Wages per month*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Wages_per_month1", {
                  required: "Wages per month is required",
                })}
                onFocus={() => setHoveredLabel("Wages per month")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
            {errors?.Wages_per_month1 && (
              <Text color="red.500" fontSize="sm">
                {errors?.Wages_per_month1?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.Semi_skilled}>
            <HoverableFormLabel>Semi-skilled Workers*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Semi_skilled", {
                  required: "Semi skilled count is required",
                })}
                onFocus={() => setHoveredLabel("Semi-skilled Workers")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
            {errors?.Semi_skilled && (
              <Text color="red.500" fontSize="sm">
                {errors?.Semi_skilled?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.Wages_per_month2}>
            <HoverableFormLabel>Wages per month*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Wages_per_month2", {
                  required: "Wages per month is required",
                })}
                onFocus={() => setHoveredLabel("Wages per month")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
            {errors?.Wages_per_month2 && (
              <Text color="red.500" fontSize="sm">
                {errors?.Wages_per_month2?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.Skilled}>
            <HoverableFormLabel>Skilled Workers*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Skilled", {
                  required: "Skilled count is required",
                })}
                onFocus={() => setHoveredLabel("Skilled Workers")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
            {errors?.Skilled && (
              <Text color="red.500" fontSize="sm">
                {errors?.Skilled?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.Wages_per_month3}>
            <HoverableFormLabel>Wages per month*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Wages_per_month3", {
                  required: "Wages per month is required",
                })}
                onFocus={() => setHoveredLabel("Wages per month")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
            {errors?.Wages_per_month3 && (
              <Text color="red.500" fontSize="sm">
                {errors?.Wages_per_month3?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <Box borderTop="1px solid" mt={6} pt={4}>
          <Text fontWeight="bold" color="darkorchid">
            Optional Coverages
          </Text>
        </Box>

        <HStack gap={4}>
          <FormControl>
            <Flex align="start">
              <HoverableFormLabel>Occupational Diseases</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Health problem caused by exposure to a workplace"
                />
              )}
            </Flex>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Occupational_Diseases")}
                onFocus={() => setHoveredLabel("Occupational Diseases")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>

          <FormControl>
            <Flex align="start">
              <HoverableFormLabel>Medical Expenses</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Cost of treatment of injury"
                />
              )}
            </Flex>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Medical_Expenses")}
                onFocus={() => setHoveredLabel("Medical Expenses")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        <HStack gap={4} w="50%">
          <FormControl>
            <HoverableFormLabel>
              Coverage for Contractors and/or SubContractors
            </HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Coverage_for_Contractors")}
                onFocus={() =>
                  setHoveredLabel(
                    "Coverage for Contractors and/or SubContractors"
                  )
                }
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        {/* border NEEDED */}
        <Box borderTop="1px solid" mt={6} pt={2} />

        <HStack gap={4}>
          <FormControl isInvalid={!!errors.start_date}>
            <HoverableFormLabel>Bid's Start Date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("start_date", {
                required: "Bid's start date is required",
              })}
              onFocus={() => setHoveredLabel("Bid's Start Date")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.start_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.start_date?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.end_date}>
            <HoverableFormLabel>Bid's End Date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch("start_date")}
              {...register("end_date", {
                required: "Bid's end date is required",
              })}
              onFocus={() => setHoveredLabel("Bid's End Date")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.end_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.end_date?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <HoverableFormLabel>Policy Start Date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              min={todayDate}
              type="date"
              {...register("policy_start_date")}
              onFocus={() => setHoveredLabel("Policy Start Date")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Policy End Date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch("policy_start_date")}
              {...register("policy_end_date")}
              onFocus={() => setHoveredLabel("Policy End Date")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.expected_premium}>
            <Flex align="start">
              <HoverableFormLabel>Anticipated premium*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Anticipated premium for acquiring this policy."
                />
              )}
            </Flex>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("expected_premium", {
                  required: "Anticipated premium is required",
                })}
                onFocus={() => setHoveredLabel("Anticipated premium")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
            {errors?.expected_premium && (
              <Text color="red.500" fontSize="sm">
                {errors?.expected_premium?.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.sum_insured}>
            <HoverableFormLabel>Total coverages*</HoverableFormLabel>

            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("sum_insured", {
                  required: "Total coverages are required",
                })}
                onFocus={() => setHoveredLabel("Total coverages")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
            {errors?.sum_insured && (
              <Text color="red.500" fontSize="sm">
                {errors?.sum_insured?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        {watch("policytype") === "Renewal" && (
          <HStack gap={4}>
            <FormControl>
              <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="text"
                {...register("previous_insurer")}
                onFocus={() => setHoveredLabel("Previous Insurer")}
                onBlur={() => resetHoveredLabel()}
              />
            </FormControl>

            <FormControl>
              <HoverableFormLabel>Previous policy expiry*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...register("previous_policy_expiry")}
                onFocus={() => setHoveredLabel("Previous policy expiry")}
                onBlur={() => resetHoveredLabel()}
              />
            </FormControl>
          </HStack>
        )}
        <Flex w="100%" justify="space-between" align="center" mt="20px">
          <Button
            type="button"
            borderRadius="21px"
            onClick={() => setStep(step - 1)}
          >
            Previous
          </Button>
          <Button
            type="submit"
            borderRadius="21px"
            colorScheme="green"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Next
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default WorkmenCompensation;
