import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  VStack,
  HStack,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { useStore } from "../../../store/store";
import { changeSattusToDraft } from "../Forms";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { BEProductMap, flattenObject } from "../../../partials/utils/helper";
import { todayDate } from "../../../partials/utils/Contants";

import { FaInfoCircle } from "react-icons/fa";
import "../form.css";
import "./standard.css";

type FormValues = {
  insured: string;
  Are_you_owner_or_rented: string;
  policytype: string;
  insurer: string;
  Occupancy: string;
  comp_location: string;
  Floater: string;
  Location1?: string;
  Location2?: string;
  Building_Value: number;
  Content_Value: number;
  Stock_Value: number;
  Plant_and_Machinery: number;
  Furniture_Fixture_Fittings: number;
  Other_Items_Value: number;
  Construction: string;
  Fire_Protection_Install: string;
  Unit_Age: number;
  Distance_between_block?: number;
  Distance_between_Nearest: number;
  Surrouding_Exposure?: string;
  Claim_experience: string;
  Any_other_details?: string;
  Debris_Removal?: number;
  Architects_Surveyors?: number;
  Spontaneous_Combustion?: number;
  Omission_extensions?: number;
  Spoilage_Material?: number;
  Leakage_Contamination?: number;
  Loss_of_rent?: number;
  Loss_of_rent_limit?: number;
  Temporary_Removal_of_Stocks?: number;
  Forest_Fire?: number;
  Earthquake?: number;
  Impact_damage?: string;
  Impact_damage_value?: number;
  STFI?: number;
  Money_coverage?: number;
  Burglury?: number;
  First_loss_Profit?: number;
  Terrorism?: number;
  Fidelity_insurance_Coverage?: number;
  start_date: string;
  end_date: string;
  policy_start_date?: string;
  policy_end_date?: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer?: string;
  previous_policy_expiry?: string;
};

const schema = yup.object().shape({
  insured: yup.string().required("This field is required"),
  Are_you_owner_or_rented: yup.string().required("This field is required"),
  policytype: yup.string().required("This field is required"),
  insurer: yup.string().required("This field is required"),
  Occupancy: yup.string().required("This field is required"),
  comp_location: yup.string().required("This field is required"),
  Floater: yup.string().required("This field is required"),
  Building_Value: yup.number().required("This field is required").min(0),
  Content_Value: yup.number().required("This field is required").min(0),
  Stock_Value: yup.number().required("This field is required").min(0),
  Plant_and_Machinery: yup.number().required("This field is required").min(0),
  Furniture_Fixture_Fittings: yup
    .number()
    .required("This field is required")
    .min(0),
  Other_Items_Value: yup.number().required("This field is required").min(0),
  Construction: yup.string().required("This field is required"),
  Fire_Protection_Install: yup.string().required("This field is required"),
  Unit_Age: yup.number().required("This field is required").min(0),
  Distance_between_Nearest: yup
    .number()
    .required("This field is required")
    .min(0),
  Claim_experience: yup.string().required("This field is required"),
  start_date: yup.string().required("This field is required"),
  end_date: yup.string().required("This field is required"),
  expected_premium: yup.number().required("This field is required").min(0),
  sum_insured: yup.number().required("This field is required").min(0),
});

const StandardFire = ({
  product,
  editData,
  mode,
  selectedCompany,
  createdBid,
  setCreatedBid,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const { step, setStep, setHoveredLabel, resetHoveredLabel } = useStore();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      Building_Value: 0,
      Content_Value: 0,
      Stock_Value: 0,
      Plant_and_Machinery: 0,
      Furniture_Fixture_Fittings: 0,
      Other_Items_Value: 0,
      Unit_Age: 0,
      Distance_between_block: 0,
      Distance_between_Nearest: 0,
      Debris_Removal: 0,
      Architects_Surveyors: 0,
      Spontaneous_Combustion: 0,
      Omission_extensions: 0,
      Spoilage_Material: 0,
      Leakage_Contamination: 0,
      Loss_of_rent: 0,
      Loss_of_rent_limit: 0,
      Temporary_Removal_of_Stocks: 0,
      Forest_Fire: 0,
      Earthquake: 0,
      Impact_damage_value: 0,
      STFI: 0,
      Money_coverage: 0,
      Burglury: 0,
      First_loss_Profit: 0,
      Terrorism: 0,
      Fidelity_insurance_Coverage: 0,
      expected_premium: 0,
      sum_insured: 0,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
    },
  });

  const onSubmit = (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    formData.append("comp_id", selectedCompany.toString() || "");
    const parsedProduct = BEProductMap[product];
    console.log("product", product);
    console.log("parsedProduct", parsedProduct);

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }

    formData.append("form_properties", JSON.stringify({ ...data }));
    if (editData?.id) {
      setIsSubmitting(true);
      useFetch
        .put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData
        )
        .then((res) => {
          setCreatedBid(res.data?.data?.id);
          toast({
            title: `Bid updated successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      setIsSubmitting(true);
      useFetch
        .post(`/api/${selectedCompany}/store-bid/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setCreatedBid(res.data?.id);
          toast({
            title: `Bid created successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    }
  };
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormValues, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);
  const buildingValue = +watch("Building_Value");
  const contentValue = +watch("Content_Value");
  const stockValue = +watch("Stock_Value");
  const plantAndMachinery = +watch("Plant_and_Machinery");
  const furnitureFixtureFittings = +watch("Furniture_Fixture_Fittings");
  const otherItemsValue = +watch("Other_Items_Value");

  useEffect(() => {
    // Sum all values
    const sum =
      buildingValue +
      contentValue +
      stockValue +
      plantAndMachinery +
      furnitureFixtureFittings +
      otherItemsValue;
    // Set the sum in the form field
    setValue("sum_insured", sum);
  }, [
    buildingValue,
    contentValue,
    stockValue,
    plantAndMachinery,
    furnitureFixtureFittings,
    otherItemsValue,
    setValue,
  ]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack gap={4} align="stretch">
        {/* Insured */}
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.insured}>
            <HoverableFormLabel>
              Whom do you want to insure?*
            </HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Whom do you want to insure?"
              {...register("insured")}
              onFocus={() => setHoveredLabel("Whom do you want to insure?")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="Business">Business</option>
              <option value="Home">Home</option>
            </Select>
          </FormControl>

          <FormControl isInvalid={!!errors.Are_you_owner_or_rented}>
            <HoverableFormLabel>Are you owner or rented?*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Are you owner or rented"
              {...register("Are_you_owner_or_rented")}
              onFocus={() => setHoveredLabel("Are you owner or rented?")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="owner">Owner</option>
              <option value="rented">Rented</option>
            </Select>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.policytype}>
            <HoverableFormLabel>Policy Type*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Select policy type"
              {...register("policytype")}
              onFocus={() => setHoveredLabel("Policy Type")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="New">New</option>
              <option value="Renewal">Renewal</option>
            </Select>
          </FormControl>

          <FormControl isInvalid={!!errors.insurer}>
            <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Preference"
              {...register("insurer")}
              onFocus={() => setHoveredLabel("Bidder Preference")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="Both">Both</option>
              <option value="Brokers">Brokers</option>
              <option value="INS_COMP">Insurance Company</option>
            </Select>
          </FormControl>
        </HStack>
        <FormControl isInvalid={!!errors.Occupancy}>
          <HoverableFormLabel>Occupancy*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Please select Occupancy"
            {...register("Occupancy")}
            maxWidth="100%"
            onFocus={() => setHoveredLabel("Occupancy")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Abrasive Manufacturing">
              Abrasive Manufacturing
            </option>
            <option value="Aerated Water Factories">
              Aerated Water Factories
            </option>
            <option value="Aerial Ropeway including trolley stations">
              Aerial Ropeway including trolley stations
            </option>
            <option value="Agarbatti manufacturing">
              Agarbatti manufacturing
            </option>
            <option value="Aircraft Hangers">Aircraft Hangers</option>
            <option value="Airport Terminal Buildings (including all facilities like Cafes, Shops etc)">
              Airport Terminal Buildings (including all facilities like Cafes,
              Shops etc)
            </option>
            <option value="Aluminium, Zinc, Copper Factories">
              Aluminium, Zinc, Copper Factories
            </option>
            <option value="Aluminium/ Magnesium Powder Plants">
              Aluminium/ Magnesium Powder Plants
            </option>
            <option value="Amusement parks">Amusement parks</option>
            <option value="Analytical / Quality Control Laboratories">
              Analytical / Quality Control Laboratories
            </option>
            <option value="Areca nut and/or Betel nut factories">
              Areca nut and/or Betel nut factories
            </option>
            <option value="Arms and Ammunition dealers">
              Arms and Ammunition dealers
            </option>
            <option value="Asbestos Steam Packing and lagging manufacturing">
              Asbestos Steam Packing and lagging manufacturing
            </option>
            <option value="Atta and Cereal Grinding (excluding Dal Mills)">
              Atta and Cereal Grinding (excluding Dal Mills)
            </option>
            <option value="Audio/Video Cassette Manufacturing">
              Audio/Video Cassette Manufacturing
            </option>
            <option value="Auditoriums / Planetarium">
              Auditoriums / Planetarium
            </option>
            <option value="Automobile Filter Manufacturing">
              Automobile Filter Manufacturing
            </option>
            <option value="Automobile Manufacturing">
              Automobile Manufacturing
            </option>
            <option value="Bakeries">Bakeries</option>
            <option value="Basket Weavers and Cane Furniture Makers">
              Basket Weavers and Cane Furniture Makers
            </option>
            <option value="Battery Charging Service Stations">
              Battery Charging Service Stations
            </option>
            <option value="Battery Manufacturing">Battery Manufacturing</option>
            <option value="Beedi Factories">Beedi Factories</option>
            <option value="Biscuit Factories">Biscuit Factories</option>
            <option value="Bituminised Paper and / or Hessian Cloth Manufacturing including Tar Felt Manufacturing.">
              Bituminised Paper and / or Hessian Cloth Manufacturing including
              Tar Felt Manufacturing.
            </option>
            <option value="Boiler House">Boiler House</option>
            <option value="Book Binders, Envelope and Paper Bag Manufacturing">
              Book Binders, Envelope and Paper Bag Manufacturing
            </option>
            <option value="Boundary Walls - Made up of Combustible Material">
              Boundary Walls - Made up of Combustible Material
            </option>
            <option value="Boundary Walls - Others">
              Boundary Walls - Others
            </option>
            <option value="Breweries">Breweries</option>
            <option value="Brickworks (including refractories and fire bricks)">
              Brickworks (including refractories and fire bricks)
            </option>
            <option value="Bridges - Concrete/Steel">
              Bridges - Concrete/Steel
            </option>
            <option value="Bridges-Wooden">Bridges-Wooden</option>
            <option value="Building In course of construction">
              Building In course of construction
            </option>
            <option value="Bus Terminus">Bus Terminus</option>
            <option value="Cable Manufacturing">Cable Manufacturing</option>
            <option value="Cafes, Restaurants, Hotels, Confectioner and Sweet meat sellers, Health Resorts">
              Cafes, Restaurants, Hotels, Confectioner and Sweet meat sellers,
              Health Resorts
            </option>
            <option value="Camphor Manufacturing">Camphor Manufacturing</option>
            <option value="Candle Works">Candle Works</option>
            <option value="Canning Factories">Canning Factories</option>
            <option value="Capsule Manufacturing">Capsule Manufacturing</option>
            <option value="Carbon paper / Typewriter Ribbon Manufacturing">
              Carbon paper / Typewriter Ribbon Manufacturing
            </option>
            <option value="Cardamom Factories">Cardamom Factories</option>
            <option value="Cardboard Box Manufacturing">
              Cardboard Box Manufacturing
            </option>
            <option value="Carpenters, Wood wool Manufacturing, Furniture Manufacturing and other wood worker shops (excluding sawmill)">
              Carpenters, Wood wool Manufacturing, Furniture Manufacturing and
              other wood worker shops (excluding sawmill)
            </option>
            <option value="Carpet and Drugget Manufacturing (Cotton/jute/wool)">
              Carpet and Drugget Manufacturing (Cotton/jute/wool)
            </option>
            <option value="Carpet and Drugget Manufacturing (Others)">
              Carpet and Drugget Manufacturing (Others)
            </option>
            <option value="Cashew nut Factories">Cashew nut Factories</option>
            <option value="Cattle feed Mill">Cattle feed Mill</option>
            <option value="Celluloid Goods Manufacturing">
              Celluloid Goods Manufacturing
            </option>
            <option value="Cement / asbestos/concrete products Manufacturing">
              Cement / asbestos/concrete products Manufacturing
            </option>
            <option value="Cement Factories">Cement Factories</option>
            <option value="Ceramic Factories and Crockery and Stoneware pipe Manufacturing/Clay Works.">
              Ceramic Factories and Crockery and Stoneware pipe
              Manufacturing/Clay Works.
            </option>
            <option value="Chemical Manufacturing (Using materials with Flash Point below 32o C), Bulk Drug Manufacturing">
              Chemical Manufacturing (Using materials with Flash Point below 32o
              C), Bulk Drug Manufacturing
            </option>
            <option value="Chemical Manufacturing(others), Pharmaceuticals, Toiletry products">
              Chemical Manufacturing(others), Pharmaceuticals, Toiletry products
            </option>
            <option value="Cigar and Cigarette Manufacturing">
              Cigar and Cigarette Manufacturing
            </option>
            <option value="Cigarette Filter Manufacturing (Others)">
              Cigarette Filter Manufacturing (Others)
            </option>
            <option value="Cigarette Filter Manufacturing (Using Solvents with Flash Point below 32o C)">
              Cigarette Filter Manufacturing (Using Solvents with Flash Point
              below 32o C)
            </option>
            <option value="Cinema Film Production Studios">
              Cinema Film Production Studios
            </option>
            <option value="Cinema Theatres (For single screen)">
              Cinema Theatres (For single screen)
            </option>
            <option value="Cinematography Film Editing, Laboratory and Sound recording rooms where Film processing is carried out">
              Cinematography Film Editing, Laboratory and Sound recording rooms
              where Film processing is carried out
            </option>
            <option value="Cinematography Film Editing, Laboratory and Sound recording rooms without Film processing.">
              Cinematography Film Editing, Laboratory and Sound recording rooms
              without Film processing.
            </option>
            <option value="Circus, Touring Drama Troupes and Touring Cinema Theatres">
              Circus, Touring Drama Troupes and Touring Cinema Theatres
            </option>
            <option value="Cloth Processing units situated outside the compound of Textile mills and Cloth processing units which are not owned by the textile mills but are situated within the textile mills complex/compound.">
              Cloth Processing units situated outside the compound of Textile
              mills and Cloth processing units which are not owned by the
              textile mills but are situated within the textile mills
              complex/compound.
            </option>
            <option value="Coal Processing Plants">
              Coal Processing Plants
            </option>
            <option value="Coal/Coke/Charcoal ball and briquettes Manufacturing">
              Coal/Coke/Charcoal ball and briquettes Manufacturing
            </option>
            <option value="Coffee Curing, Roasting / Grinding">
              Coffee Curing, Roasting / Grinding
            </option>
            <option value="Coir Factories">Coir Factories</option>
            <option value="Cold Storage premises">Cold Storage premises</option>
            <option value="Collieries - underground Machinery and pit head gear and Dragline Machine used in Collieries">
              Collieries - underground Machinery and pit head gear and Dragline
              Machine used in Collieries
            </option>
            <option value="Compressor Houses - Compressor (others)">
              Compressor Houses - Compressor (others)
            </option>
            <option value="Compressor Houses - Compressors handling air, Inert Gas and CO2">
              Compressor Houses - Compressors handling air, Inert Gas and CO2
            </option>
            <option value="Condensed Milk Factories, Milk Pasteurising Plants and Dairies">
              Condensed Milk Factories, Milk Pasteurising Plants and Dairies
            </option>
            <option value="Confectionery Manufacturing Plants/Sugar Candy Manufacturing Plants/Sweet Meat Manufacturing Plants">
              Confectionery Manufacturing Plants/Sugar Candy Manufacturing
              Plants/Sweet Meat Manufacturing Plants
            </option>
            <option value="Contractors Plant and Machinery - At one location only">
              Contractors Plant and Machinery - At one location only
            </option>
            <option value="Contractors Plant and Machinery anywhere in India (at specified locations)">
              Contractors Plant and Machinery anywhere in India (at specified
              locations)
            </option>
            <option value="Cork Products Manufacturing">
              Cork Products Manufacturing
            </option>
            <option value="Cotton Gin and Press Houses">
              Cotton Gin and Press Houses
            </option>
            <option value="Cotton Seed cleaning / Delinting Factory">
              Cotton Seed cleaning / Delinting Factory
            </option>
            <option value="Dams">Dams</option>
            <option value="Data Processing/Call Centres/Business Process Outsourcing Centres">
              Data Processing/Call Centres/Business Process Outsourcing Centres
            </option>
            <option value="Dehydration Factories">Dehydration Factories</option>
            <option value="Detergent Manufacturing (Others)">
              Detergent Manufacturing (Others)
            </option>
            <option value="Detergent Manufacturing with Sulphonation Plant">
              Detergent Manufacturing with Sulphonation Plant
            </option>
            <option value="Dish Antenna">Dish Antenna</option>
            <option value="Disposable Diaper Manufacturing">
              Disposable Diaper Manufacturing
            </option>
            <option value="Distilleries">Distilleries</option>
            <option value="Duplicating/stencil paper Manufacturing">
              Duplicating/stencil paper Manufacturing
            </option>
            <option value="Dwellings">Dwellings</option>
            <option value="Dwellings: Cooperative Society">
              Dwellings: Cooperative Society
            </option>
            <option value="Educational and Research Institutes imparting training in various crafts">
              Educational and Research Institutes imparting training in various
              crafts
            </option>
            <option value="Effluent /Sewage Treatment Plants">
              Effluent /Sewage Treatment Plants
            </option>
            <option value="Electric Crematoriums">Electric Crematoriums</option>
            <option value="Electric Generation Stations - Biomass Power Stations">
              Electric Generation Stations - Biomass Power Stations
            </option>
            <option value="Electric Generation Stations - Hydro Power stations">
              Electric Generation Stations - Hydro Power stations
            </option>
            <option value="Electric Generation Stations - Nuclear Power Plant">
              Electric Generation Stations - Nuclear Power Plant
            </option>
            <option value="Electric Generation Stations - Others">
              Electric Generation Stations - Others
            </option>
            <option value="Electric Generation Stations - Solar Power stations">
              Electric Generation Stations - Solar Power stations
            </option>
            <option value="Electric Generation Stations - Thermal Power stations">
              Electric Generation Stations - Thermal Power stations
            </option>
            <option value="Electric Generation Stations - Windmill">
              Electric Generation Stations - Windmill
            </option>
            <option value="Electric Lamp /T.V. Picture Tube Manufacturing">
              Electric Lamp /T.V. Picture Tube Manufacturing
            </option>
            <option value="Electric Sub-Stations, Loco Sheds">
              Electric Sub-Stations, Loco Sheds
            </option>
            <option value="Electric Transmission / Distribution Lines">
              Electric Transmission / Distribution Lines
            </option>
            <option value="Electronic Goods Manufacturing /Assembly">
              Electronic Goods Manufacturing /Assembly
            </option>
            <option value="Electronic Software Parks/Electronic Software Development Units">
              Electronic Software Parks/Electronic Software Development Units
            </option>
            <option value="Enamel-ware factories">Enamel-ware factories</option>
            <option value="Engineering Workshop - Clock / Watch Manufacturing">
              Engineering Workshop - Clock / Watch Manufacturing
            </option>
            <option value="Engineering Workshop - Ferro Plants">
              Engineering Workshop - Ferro Plants
            </option>
            <option value="Engineering Workshop - Manufacturing of Diamonds">
              Engineering Workshop - Manufacturing of Diamonds
            </option>
            <option value="Engineering Workshop - Metal Extraction, Ore Processing (other than Aluminium, Copper, Zinc), Plant processing Raw Sand into Silica">
              Engineering Workshop - Metal Extraction, Ore Processing (other
              than Aluminium, Copper, Zinc), Plant processing Raw Sand into
              Silica
            </option>
            <option value="Engineering Workshop - Pipe Extruding, Stamping, Pressing, Forging Mills, Metal Smelting, Foundries, Galvanising Work">
              Engineering Workshop - Pipe Extruding, Stamping, Pressing, Forging
              Mills, Metal Smelting, Foundries, Galvanising Work
            </option>
            <option value="Engineering Workshop - Rolling Mill">
              Engineering Workshop - Rolling Mill
            </option>
            <option value="Engineering Workshop - Steel Plants">
              Engineering Workshop - Steel Plants
            </option>
            <option value="Engineering Workshop - Structural Steel fabricators, Sheet Metal fabricators">
              Engineering Workshop - Structural Steel fabricators, Sheet Metal
              fabricators
            </option>
            <option value="Engineering Workshop (Others), Motor Vehicle Garages">
              Engineering Workshop (Others), Motor Vehicle Garages
            </option>
            <option value="Exhibitions, Fetes, Mandaps.">
              Exhibitions, Fetes, Mandaps.
            </option>
            <option value="Explosives / Blasting Factories">
              Explosives / Blasting Factories
            </option>
            <option value="Fertiliser Manufacturing (both Petrochemical and others like Phosphatic)">
              Fertiliser Manufacturing (both Petrochemical and others like
              Phosphatic)
            </option>
            <option value="Filter and wax paper Manufacturing">
              Filter and wax paper Manufacturing
            </option>
            <option value="Fireworks Manufacturing">
              Fireworks Manufacturing
            </option>
            <option value="Flax / Hemp Mills">Flax / Hemp Mills</option>
            <option value="Flour Mills">Flour Mills</option>
            <option value="Foam Rubber Manufacturing">
              Foam Rubber Manufacturing
            </option>
            <option value="Foamed Plastics Manufacturing and / or converting plants">
              Foamed Plastics Manufacturing and / or converting plants
            </option>
            <option value="French Polish Manufacturing">
              French Polish Manufacturing
            </option>
            <option value="Fruit and vegetable drying/dehydrating factories">
              Fruit and vegetable drying/dehydrating factories
            </option>
            <option value="Fruit products and Condiment Factories (including fruit pulp making)">
              Fruit products and Condiment Factories (including fruit pulp
              making)
            </option>
            <option value="Fuel Station- Petrol / Diesel Kiosks (with CNG)">
              Fuel Station- Petrol / Diesel Kiosks (with CNG)
            </option>
            <option value="Fuel Station- Petrol / Diesel Kiosks (without CNG)">
              Fuel Station- Petrol / Diesel Kiosks (without CNG)
            </option>
            <option value="Garment Makers, Topee, Hats and the like makers">
              Garment Makers, Topee, Hats and the like makers
            </option>
            <option value="Gas Holders/ Bullets/spheres and storages for liquefied gases except for Nitrogen, Carbon dioxide and inert gases">
              Gas Holders/ Bullets/spheres and storages for liquefied gases
              except for Nitrogen, Carbon dioxide and inert gases
            </option>
            <option value="Gas Holders/ Vessels for Nitrogen, Carbon dioxide and inert gases">
              Gas Holders/ Vessels for Nitrogen, Carbon dioxide and inert gases
            </option>
            <option value="Ghee Factories including vegetable Ghee mfg.">
              Ghee Factories including vegetable Ghee mfg.
            </option>
            <option value="Glass Fibre Manufacturing">
              Glass Fibre Manufacturing
            </option>
            <option value="Glass Manufacturing/Automobile Glass Manufacturing">
              Glass Manufacturing/Automobile Glass Manufacturing
            </option>
            <option value="Glass wool Manufacturing">
              Glass wool Manufacturing
            </option>
            <option value="Gold thread factories / Gilding factories">
              Gold thread factories / Gilding factories
            </option>
            <option value="Grain/seeds disintegrating/crushing/ Decorticating factories/ Dal mills.">
              Grain/seeds disintegrating/crushing/ Decorticating factories/ Dal
              mills.
            </option>
            <option value="Granite Factories (Others)">
              Granite Factories (Others)
            </option>
            <option value="Granite Factories using inflammable solvents">
              Granite Factories using inflammable solvents
            </option>
            <option value="Graphite electrode Manufacturing">
              Graphite electrode Manufacturing
            </option>
            <option value="Grease / Wax Manufacturing">
              Grease / Wax Manufacturing
            </option>
            <option value="Green Houses/ Algae/ Spirulina and the like">
              Green Houses/ Algae/ Spirulina and the like
            </option>
            <option value="Gum/Glue/Gelatine Manufacturing">
              Gum/Glue/Gelatine Manufacturing
            </option>
            <option value="Gypsum board manufacturer">
              Gypsum board manufacturer
            </option>
            <option value="Hoarding, Neon signs">Hoarding, Neon signs</option>
            <option value="Hosiery, lace, Embroidery/Thread factories">
              Hosiery, lace, Embroidery/Thread factories
            </option>
            <option value="Hospitals including X-ray and other Diagnostic clinics">
              Hospitals including X-ray and other Diagnostic clinics
            </option>
            <option value="Ice candy and Ice cream Manufacturing">
              Ice candy and Ice cream Manufacturing
            </option>
            <option value="Ice factories">Ice factories</option>
            <option value="Incandescent Gas mantle Manufacturing">
              Incandescent Gas mantle Manufacturing
            </option>
            <option value="Indoor stadiums, Health Club, Gymnasium and Swimming Pool">
              Indoor stadiums, Health Club, Gymnasium and Swimming Pool
            </option>
            <option value="Industrial Diamonds Manufacturing">
              Industrial Diamonds Manufacturing
            </option>
            <option value="Industrial Gas Manufacturing">
              Industrial Gas Manufacturing
            </option>
            <option value="Ink (excluding printing ink) Manufacturing">
              Ink (excluding printing ink) Manufacturing
            </option>
            <option value="Jaggery Manufacturing">Jaggery Manufacturing</option>
            <option value="Jute Mills">Jute Mills</option>
            <option value="Katha Manufacturing">Katha Manufacturing</option>
            <option value="Khandsari Sugar Manufacturing">
              Khandsari Sugar Manufacturing
            </option>
            <option value="Lac or Shellac Factories">
              Lac or Shellac Factories
            </option>
            <option value="Laundries / Dry Cleaning">
              Laundries / Dry Cleaning
            </option>
            <option value="Leather Cloth Factories">
              Leather Cloth Factories
            </option>
            <option value="Leather Goods Manufacturing (incl. boot/shoe)">
              Leather Goods Manufacturing (incl. boot/shoe)
            </option>
            <option value="Libraries">Libraries</option>
            <option value="Lignite Handling System">
              Lignite Handling System
            </option>
            <option value="Lime Kiln">Lime Kiln</option>
            <option value="Liquefied Gas Bottling/Recovery Plants">
              Liquefied Gas Bottling/Recovery Plants
            </option>
            <option value="Lithographic presses">Lithographic presses</option>
            <option value="Lodging/Boarding Houses">
              Lodging/Boarding Houses
            </option>
            <option value="Malt Extraction Plants">
              Malt Extraction Plants
            </option>
            <option value="Man-made Fibre Manufacturing (using Cellulose)">
              Man-made Fibre Manufacturing (using Cellulose)
            </option>
            <option value="Man-made Fibre Manufacturing Plant (Others)">
              Man-made Fibre Manufacturing Plant (Others)
            </option>
            <option value="Manure Blending works">Manure Blending works</option>
            <option value="Marriage Halls">Marriage Halls</option>
            <option value="Match Factories">Match Factories</option>
            <option value="Mattress and Pillow making">
              Mattress and Pillow making
            </option>
            <option value="Mess Houses / Clubs">Mess Houses / Clubs</option>
            <option value="Metal/Tin printers">Metal/Tin printers</option>
            <option value="Metallizing works (involving metals only)">
              Metallizing works (involving metals only)
            </option>
            <option value="Metallizing Works (others)">
              Metallizing Works (others)
            </option>
            <option value="Mica Products Manufacturing">
              Mica Products Manufacturing
            </option>
            <option value="Mineral Oil blending and processing">
              Mineral Oil blending and processing
            </option>
            <option value="Mosaic Factories">Mosaic Factories</option>
            <option value="Motor Vehicle showroom including sales and service">
              Motor Vehicle showroom including sales and service
            </option>
            <option value="Multiplex Theatre Complexes">
              Multiplex Theatre Complexes
            </option>
            <option value="Museums">Museums</option>
            <option value="Mushroom Growing Premises (Excluding Crops)">
              Mushroom Growing Premises (Excluding Crops)
            </option>
            <option value="Nitro Cellulose Manufacturing - Industrial Grade">
              Nitro Cellulose Manufacturing - Industrial Grade
            </option>
            <option value="Nitro Cellulose Manufacturing - Others">
              Nitro Cellulose Manufacturing - Others
            </option>
            <option value="Non-woven fabric Manufacturing">
              Non-woven fabric Manufacturing
            </option>
            <option value="Office premises / Meeting Rooms">
              Office premises / Meeting Rooms
            </option>
            <option value="Oil and Leather Cloth Factories">
              Oil and Leather Cloth Factories
            </option>
            <option value="Oil Distillation Plants (essential)">
              Oil Distillation Plants (essential)
            </option>
            <option value="Oil Extraction">Oil Extraction</option>
            <option value="Oil Mills (Vegetable)">Oil Mills (Vegetable)</option>
            <option value="Oil Mills refining (Veg/Animal)">
              Oil Mills refining (Veg/Animal)
            </option>
            <option value="Others">Others</option>
            <option value="Paint (others) and Varnish Factories">
              Paint (others) and Varnish Factories
            </option>
            <option value="Paint factories (Water based)">
              Paint factories (Water based)
            </option>
            <option value="Paints - Nitrocellulose based">
              Paints - Nitrocellulose based
            </option>
            <option value="Pan Masala making">Pan Masala making</option>
            <option value="Paper and Cardboard Mills (including Lamination)">
              Paper and Cardboard Mills (including Lamination)
            </option>
            <option value="Particle Board Manufacturing">
              Particle Board Manufacturing
            </option>
            <option value="Pencil Manufacturing">Pencil Manufacturing</option>
            <option value="Petroleum Coke Calcination">
              Petroleum Coke Calcination
            </option>
            <option value="Pilot Plants">Pilot Plants</option>
            <option value="Pipe lines (carrying water only)">
              Pipe lines (carrying water only)
            </option>
            <option value="Pipe lines (others)">Pipe lines (others)</option>
            <option value="Places of worships">Places of worships</option>
            <option value="Plastic Goods Manufacturing (excluding Foam Plastics)/ I. Using Plastic raw materials having calorific value upto 15,000 btu/lb Polytetrafluoroethylene, Polychorotrif, fluroehtylene, Polyvinyl chloride, polyvinylidene chloride, Polyvinylidene fluoride, Chlorinated polyether, Polycarbonate, Polymethyl methacrylate, Phenol formaldehyde, Urea-formaldehyde. Melamine-formaldehyde, Polyurethane, Polyester, Silicones.">
              Plastic Goods Manufacturing (excluding Foam Plastics)/ I. Using
              Plastic raw materials having calorific value upto 15,000 btu/lb
              Polytetrafluoroethylene, Polychorotrif, fluroehtylene, Polyvinyl
              chloride, polyvinylidene chloride, Polyvinylidene fluoride,
              Chlorinated polyether, Polycarbonate, Polymethyl methacrylate,
              Phenol formaldehyde, Urea-formaldehyde. Melamine-formaldehyde,
              Polyurethane, Polyester, Silicones.
            </option>
            <option value="Plastic Goods Manufacturing (excluding Foam Plastics)/II. Using Plastic raw materials having calorific value of above 15000 btu/ib Polyethylene, Polypropylene, Polystyrene, Poly-alphamethylstyrene, Acrylonitrilebutadiene- styrene, Polybutylene.">
              Plastic Goods Manufacturing (excluding Foam Plastics)/II. Using
              Plastic raw materials having calorific value of above 15000 btu/ib
              Polyethylene, Polypropylene, Polystyrene, Poly-alphamethylstyrene,
              Acrylonitrilebutadiene- styrene, Polybutylene.
            </option>
            <option value="Plywood / Wood veneering Factories/ Laminating Factories">
              Plywood / Wood veneering Factories/ Laminating Factories
            </option>
            <option value="Polyester Film Manufacturing / BOPP Film Manufacturing">
              Polyester Film Manufacturing / BOPP Film Manufacturing
            </option>
            <option value="Port Premises including jetties and equipment thereon and other port facilities">
              Port Premises including jetties and equipment thereon and other
              port facilities
            </option>
            <option value="Poultry Farms (Excluding birds therein)">
              Poultry Farms (Excluding birds therein)
            </option>
            <option value="Presses for carpets, rugs and tobacco">
              Presses for carpets, rugs and tobacco
            </option>
            <option value="Presses for coir fibres/waste/Grass/fodder/boosa/Jute">
              Presses for coir fibres/waste/Grass/fodder/boosa/Jute
            </option>
            <option value="Presses for coir yarn / cotton/senna leaves">
              Presses for coir yarn / cotton/senna leaves
            </option>
            <option value="Presses for hides and skins">
              Presses for hides and skins
            </option>
            <option value="Printing Ink Manufacturing / Roller composition factories">
              Printing Ink Manufacturing / Roller composition factories
            </option>
            <option value="Printing Press">Printing Press</option>
            <option value="Pulverising Plants(Metals and non-hazardous goods)">
              Pulverising Plants(Metals and non-hazardous goods)
            </option>
            <option value="Pulverizing Plants (Others)">
              Pulverizing Plants (Others)
            </option>
            <option value="Pump Houses (Others)">Pump Houses (Others)</option>
            <option value="Pump Houses (Water)">Pump Houses (Water)</option>
            <option value="Railway tracks">Railway tracks</option>
            <option value="Rice Mills">Rice Mills</option>
            <option value="Rice Polishing Units">Rice Polishing Units</option>
            <option value="Roads">Roads</option>
            <option value="Rope Works (others)">Rope Works (others)</option>
            <option value="Rope works (Plastic), Assembling of Plastic Goods such as Toys and the like">
              Rope works (Plastic), Assembling of Plastic Goods such as Toys and
              the like
            </option>
            <option value="Rubber Factories">Rubber Factories</option>
            <option value="Rubber Goods Manufacturing without spreading">
              Rubber Goods Manufacturing without spreading
            </option>
            <option value="Rubber Goods Mfg with Spreading">
              Rubber Goods Mfg with Spreading
            </option>
            <option value="Salt crushing Factories and Refineries">
              Salt crushing Factories and Refineries
            </option>
            <option value="Saw Mills (including Timber Merchants premises where sawing is done).">
              Saw Mills (including Timber Merchants premises where sawing is
              done).
            </option>
            <option value="Schools / Colleges">Schools / Colleges</option>
            <option value="Sea Food / Meat Processing">
              Sea Food / Meat Processing
            </option>
            <option value="Shopping Malls (other than Multiplexes)">
              Shopping Malls (other than Multiplexes)
            </option>
            <option value="Shops dealing in goods otherwise not provided for">
              Shops dealing in goods otherwise not provided for
            </option>
            <option value="Shops dealing in hazardous goods">
              Shops dealing in hazardous goods
            </option>
            <option value="Showrooms and display centres where goods are kept for display and no sales are carried out">
              Showrooms and display centres where goods are kept for display and
              no sales are carried out
            </option>
            <option value="Silent Risk">Silent Risk</option>
            <option value="Silk Mills / Spun Silk Mills">
              Silk Mills / Spun Silk Mills
            </option>
            <option value="Snuff Manufacturing">Snuff Manufacturing</option>
            <option value="Soap Manufacturing">Soap Manufacturing</option>
            <option value="Sponge Iron Plants">Sponge Iron Plants</option>
            <option value="Sports Galleries, Outdoor stadiums.">
              Sports Galleries, Outdoor stadiums.
            </option>
            <option value="Sports Goods Manufacturing">
              Sports Goods Manufacturing
            </option>
            <option value="Spray Painting, Powder coating">
              Spray Painting, Powder coating
            </option>
            <option value="Stables (excluding animals)">
              Stables (excluding animals)
            </option>
            <option value="Starch Factories">Starch Factories</option>
            <option value="Stone quarries">Stone quarries</option>
            <option value="Storage of Category I hazardous Goods subject to warranty that goods listed in Category II, III, Coir waste, C oir fibre and Caddies are not stored therein. (Materials stored in Godowns &amp; Silos)">
              Storage of Category I hazardous Goods subject to warranty that
              goods listed in Category II, III, Coir waste, C oir fibre and
              Caddies are not stored therein. (Materials stored in Godowns &amp;
              Silos)
            </option>
            <option value="Storage of Category I hazardous Goods subject to warranty that goods listed in Category II, III, Coir waste, Coir fibre and Caddies are not stored therein.(Materials stored in Open)">
              Storage of Category I hazardous Goods subject to warranty that
              goods listed in Category II, III, Coir waste, Coir fibre and
              Caddies are not stored therein.(Materials stored in Open)
            </option>
            <option value="Storage of Coir Waste, Coir Fibre, Caddies (Materials stored in Godowns &amp; Silos)">
              Storage of Coir Waste, Coir Fibre, Caddies (Materials stored in
              Godowns &amp; Silos)
            </option>
            <option value="Storage of Coir Waste, Coir Fibre, Caddies (Materials stored in Open)">
              Storage of Coir Waste, Coir Fibre, Caddies (Materials stored in
              Open)
            </option>
            <option value="Storage of hazardous Goods listed in Category II subject to warranty that goods listed in Category III, Coir waste, Coir fibre and Caddies are not stored therein. (Materials stored in Godowns &amp; Silos)">
              Storage of hazardous Goods listed in Category II subject to
              warranty that goods listed in Category III, Coir waste, Coir fibre
              and Caddies are not stored therein. (Materials stored in Godowns
              &amp; Silos)
            </option>
            <option value="Storage of hazardous Goods listed in Category II subject to warranty that goods listed in Category III, Coir waste, Coir fibre and Caddies are not stored therein. (Materials stored in Open)">
              Storage of hazardous Goods listed in Category II subject to
              warranty that goods listed in Category III, Coir waste, Coir fibre
              and Caddies are not stored therein. (Materials stored in Open)
            </option>
            <option value="Storage of hazardous Goods listed in Category III subject to warranty that Coir waste, Coir fibre and, Caddies are not stored therein. (Materials stored in Godowns &amp; Silos)">
              Storage of hazardous Goods listed in Category III subject to
              warranty that Coir waste, Coir fibre and, Caddies are not stored
              therein. (Materials stored in Godowns &amp; Silos)
            </option>
            <option value="Storage of hazardous Goods listed in Category III subject to warranty that Coir waste, Coir fibre and, Caddies are not stored therein. (Materials stored in Open)">
              Storage of hazardous Goods listed in Category III subject to
              warranty that Coir waste, Coir fibre and, Caddies are not stored
              therein. (Materials stored in Open)
            </option>
            <option value="Storage of Non-hazardous goods subject to warranty that hazardous goods of Category I, II, III, Coir waste, Coir fibre and Caddies are not stored therein. (Materials stored in Godowns &amp; Silos)">
              Storage of Non-hazardous goods subject to warranty that hazardous
              goods of Category I, II, III, Coir waste, Coir fibre and Caddies
              are not stored therein. (Materials stored in Godowns &amp; Silos)
            </option>
            <option value="Storage of Non-hazardous goods subject to warranty that hazardous goods of Category I, II, III, Coir waste, Coir fibre and Caddies are not stored therein. (Materials stored in Open)">
              Storage of Non-hazardous goods subject to warranty that hazardous
              goods of Category I, II, III, Coir waste, Coir fibre and Caddies
              are not stored therein. (Materials stored in Open)
            </option>
            <option value="Sugar factories">Sugar factories</option>
            <option value="Surgical Cotton Manufacturing">
              Surgical Cotton Manufacturing
            </option>
            <option value="Tanks (others)">Tanks (others)</option>
            <option value="Tanks containing liquids flashing at 32o C and below">
              Tanks containing liquids flashing at 32o C and below
            </option>
            <option value="Tanneries">Tanneries</option>
            <option value="Tapioca factories">Tapioca factories</option>
            <option value="Tarpaulin and canvas proofing factories">
              Tarpaulin and canvas proofing factories
            </option>
            <option value="Tea blending/packing factories">
              Tea blending/packing factories
            </option>
            <option value="Tea Factories">Tea Factories</option>
            <option value="Telephone Exchanges">Telephone Exchanges</option>
            <option value="Textile Mills - Composite mills">
              Textile Mills - Composite mills
            </option>
            <option value="Textile Mills - Spinning mills">
              Textile Mills - Spinning mills
            </option>
            <option value="Tile and Pottery works">
              Tile and Pottery works
            </option>
            <option value="Tiny sector Industries with values at risk not exceeding Rs 10 lakhs">
              Tiny sector Industries with values at risk not exceeding Rs 10
              lakhs
            </option>
            <option value="Tissue Culture Premises (Excluding Crops)">
              Tissue Culture Premises (Excluding Crops)
            </option>
            <option value="Tobacco Curing / Redrying Factories">
              Tobacco Curing / Redrying Factories
            </option>
            <option value="Tobacco grinding/ crushing Manufacturing">
              Tobacco grinding/ crushing Manufacturing
            </option>
            <option value="Transporter's Godowns and Godowns of clearing and forwarding agents. (Materials stored in Godowns &amp; Silos)">
              Transporter's Godowns and Godowns of clearing and forwarding
              agents. (Materials stored in Godowns &amp; Silos)
            </option>
            <option value="Transporter's Godowns and Godowns of clearing and forwarding agents. (Materials stored in Open)">
              Transporter's Godowns and Godowns of clearing and forwarding
              agents. (Materials stored in Open)
            </option>
            <option value="Turpentine and rosin distilleries">
              Turpentine and rosin distilleries
            </option>
            <option value="Tyre Retreading and Resoling factories">
              Tyre Retreading and Resoling factories
            </option>
            <option value="Tyres and Tubes Manufacturing">
              Tyres and Tubes Manufacturing
            </option>
            <option value="Umbrella Assembly factories">
              Umbrella Assembly factories
            </option>
            <option value="Vehicles stored in Open Including Tractors">
              Vehicles stored in Open Including Tractors
            </option>
            <option value="Velvet Cloth Manufacturing">
              Velvet Cloth Manufacturing
            </option>
            <option value="Vermicelli factories">Vermicelli factories</option>
            <option value="Water Treatment Plants / Water Tanks">
              Water Treatment Plants / Water Tanks
            </option>
            <option value="Weaving Mills">Weaving Mills</option>
            <option value="Weigh Bridges">Weigh Bridges</option>
            <option value="Wheat Threshers">Wheat Threshers</option>
            <option value="Wireless Transmitting Stations">
              Wireless Transmitting Stations
            </option>
            <option value="Wood seasoning/treatment/ impregnation">
              Wood seasoning/treatment/ impregnation
            </option>
            <option value="Wool cleaning and pressing factories">
              Wool cleaning and pressing factories
            </option>
            <option value="Woollen Mills/Fur garments and accessories">
              Woollen Mills/Fur garments and accessories
            </option>
            <option value="Yarn Processing">Yarn Processing</option>
            <option value="Zip fasteners Manufacturing">
              Zip fasteners Manufacturing
            </option>
          </Select>
        </FormControl>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.comp_location}>
            <HoverableFormLabel>
              Where is your company located?*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("comp_location")}
              onFocus={() => setHoveredLabel("Where is your company located?")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.Floater}>
            <Flex align="start">
              <HoverableFormLabel>Floater*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Include the whole risk area."
                />
              )}
            </Flex>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Floater"
              {...register("Floater")}
              onFocus={() => setHoveredLabel("Floater")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <HoverableFormLabel>Location 1</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Location1")}
              onFocus={() => setHoveredLabel("Location 1")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Location 2</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Location2")}
              onFocus={() => setHoveredLabel("Location 2")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>
        </HStack>
        <Box borderTop="1px solid" mt={6} pt={4}>
          <Text fontWeight="bold" color="darkorchid">
            Coverage Description
          </Text>
        </Box>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.Building_Value}>
            <HoverableFormLabel>Building Value*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Building_Value")}
                onFocus={() => setHoveredLabel("Building Value")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>

          <FormControl isInvalid={!!errors.Content_Value}>
            <HoverableFormLabel>Content Value*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Content_Value")}
                onFocus={() => setHoveredLabel("Content Value")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.Stock_Value}>
            <HoverableFormLabel>Stock Value*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Stock_Value")}
                onFocus={() => setHoveredLabel("Stock Value")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>

          <FormControl isInvalid={!!errors.Plant_and_Machinery}>
            <HoverableFormLabel>Plant and Machinery*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Plant_and_Machinery")}
                onFocus={() => setHoveredLabel("Plant and Machinery")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.Furniture_Fixture_Fittings}>
            <HoverableFormLabel>
              Furniture, Fixture & Fittings*
            </HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Furniture_Fixture_Fittings")}
                onFocus={() => setHoveredLabel("Furniture, Fixture & Fittings")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>

          <FormControl isInvalid={!!errors.Other_Items_Value}>
            <HoverableFormLabel>Other Items Value*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Other_Items_Value")}
                onFocus={() => setHoveredLabel("Other Items Value")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        <Box borderTop="1px solid" mt={6} pt={4}>
          <Text fontWeight="bold" color="darkorchid">
            Special Condition
          </Text>
        </Box>
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.Construction}>
            <HoverableFormLabel>Construction*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Construction"
              {...register("Construction")}
              onFocus={() => setHoveredLabel("Construction")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="Pucca">Pucca</option>
              <option value="Kutcha">Kutcha</option>
            </Select>
          </FormControl>

          <FormControl isInvalid={!!errors.Fire_Protection_Install}>
            <HoverableFormLabel>Fire Protection Install*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Fire Protection Installed"
              {...register("Fire_Protection_Install")}
              onFocus={() => setHoveredLabel("Fire Protection Install")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
          </FormControl>
        </HStack>{" "}
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.Unit_Age}>
            <HoverableFormLabel>Unit Age*</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Unit_Age")}
                onFocus={() => setHoveredLabel("Unit Age")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>

          <FormControl>
            <HoverableFormLabel input="Distance between block (In case of multiple locations In Meter)">
              Distance Between Blocks
              <span style={{ fontSize: "10px", color: "gray" }}>
                (In case of multiple locations In Meter)
              </span>
            </HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Distance_between_block")}
                onFocus={() =>
                  setHoveredLabel("Distance Between Blocks (Meters)")
                }
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>{" "}
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.Distance_between_Nearest}>
            <HoverableFormLabel input="Distance between Nearest fire brigade (In Meter)">
              Distance Between Nearest Fire Brigade
              <span style={{ fontSize: "10px", color: "gray" }}>
                (In Meter)
              </span>
              *
            </HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Distance_between_Nearest")}
                onFocus={() =>
                  setHoveredLabel(
                    "Distance Between Nearest Fire Brigade (Meters)"
                  )
                }
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Surrounding Exposure</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              placeholder="Surrounding Exposure"
              {...register("Surrouding_Exposure")}
              onFocus={() => setHoveredLabel("Surrounding Exposure")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="flood zones">flood zones</option>
              <option value="wildfire areas">wildfire areas</option>
              <option value="Presence of petrochemical plants">
                Presence of petrochemical plants
              </option>
              <option value="Flammability of nearby structures">
                Flammability of nearby structures
              </option>
            </Select>
          </FormControl>
        </HStack>{" "}
        <HStack gap={4}>
          <FormControl isInvalid={!!errors.Claim_experience}>
            <HoverableFormLabel>Claim Experience*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Claim_experience")}
              onFocus={() => setHoveredLabel("Claim Experience")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Any other details</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Any_other_details")}
              onFocus={() => setHoveredLabel("Any other details")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>
        </HStack>
        <Box borderTop="1px solid" mt={6} pt={4}>
          <Flex align="start">
            <Text fontWeight="bold" color="darkorchid">
              Optional coverages for material damage{" "}
              <span style={{ fontSize: "12px", color: "gray" }}>
                (Sub limit)
              </span>
            </Text>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Pay additional premium to opt these covers"
              />
            )}
          </Flex>
        </Box>
        <HStack gap={4}>
          <FormControl>
            <Flex align="start">
              <HoverableFormLabel>Debris Removal</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="The remains of something destroyed or broken down are referred to as debris."
                />
              )}
            </Flex>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Debris_Removal")}
                onFocus={() => setHoveredLabel("Debris Removal")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>

          <FormControl>
            <HoverableFormLabel>
              Architects, Surveyors, and Consulting Engineers Fees
            </HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Architects_Surveyors")}
                onFocus={() =>
                  setHoveredLabel(
                    "Architects, Surveyors, and Consulting Engineers Fees"
                  )
                }
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <HoverableFormLabel>Spontaneous Combustion</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Spontaneous_Combustion")}
                onFocus={() => setHoveredLabel("Spontaneous Combustion")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>

          <FormControl>
            <HoverableFormLabel>
              Omission to Insure Additions, Alterations, or Extensions
            </HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Omission_extensions")}
                onFocus={() =>
                  setHoveredLabel(
                    "Omission to Insure Additions, Alterations, or Extensions"
                  )
                }
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <HoverableFormLabel>
              Spoilage Material Damage Cover
            </HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Spoilage_Material")}
                onFocus={() =>
                  setHoveredLabel("Spoilage Material Damage Cover")
                }
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>

          <FormControl>
            <Flex align="start">
              <HoverableFormLabel>
                Leakage And Contamination Cover
              </HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Shields against financial losses or harm brought on by product pollution or contamination"
                />
              )}
            </Flex>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Leakage_Contamination")}
                onFocus={() =>
                  setHoveredLabel("Leakage And Contamination Cover")
                }
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <Flex align="start">
              <Flex align="start">
                <HoverableFormLabel>
                  Loss of Rent (Specify Indemnity in Months)
                </HoverableFormLabel>
                {mode === "Guided" && (
                  <FaInfoCircle
                    fontSize="11px"
                    color="gray.500"
                    title="If there are areas of the building that are unoccupied."
                  />
                )}
              </Flex>
            </Flex>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Loss_of_rent")}
                onFocus={() =>
                  setHoveredLabel("Loss of Rent (Specify Indemnity in Months)")
                }
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Loss of Rent Limit</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Loss_of_rent_limit")}
                onFocus={() => setHoveredLabel("Loss of Rent Limit")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <HoverableFormLabel>Temporary Removal of Stocks</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Temporary_Removal_of_Stocks")}
                onFocus={() => setHoveredLabel("Temporary Removal of Stocks")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Forest Fire</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Forest_Fire")}
                onFocus={() => setHoveredLabel("Forest Fire")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl w="50%">
            <HoverableFormLabel>Earthquake (Fire & Shock)</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Earthquake")}
                onFocus={() => setHoveredLabel("Earthquake (Fire & Shock)")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
          <HStack gap={2} w="50%">
            <FormControl>
              <HoverableFormLabel>Impact Damage</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                placeholder="Impact damage due to insured’s own"
                {...register("Impact_damage")}
                onFocus={() => setHoveredLabel("Impact Damage")}
                onBlur={() => resetHoveredLabel()}
              >
                <option value="Rail/ Road vehicles">Rail/ Road vehicles</option>
                <option value="Forklifts">Forklifts</option>
                <option value="Cranes">Cranes</option>
                <option value="Stackers">Stackers</option>
              </Select>
            </FormControl>
            <FormControl>
              <HoverableFormLabel>Impact Damage - Value</HoverableFormLabel>
              <NumberInput min={0}>
                <NumberInputField
                  borderRadius="21px"
                  bg="white"
                  {...register("Impact_damage_value")}
                  onFocus={() => setHoveredLabel("Impact Damage - Value")}
                  onBlur={() => resetHoveredLabel()}
                />
              </NumberInput>
            </FormControl>
          </HStack>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <Flex align="start">
              <HoverableFormLabel>
                STFI (Storm, Tempest, Flood, Inundation)
              </HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Storm, Tempest, Flood, Inundation, Hurricane, Cyclone, Typhoon and Tornado"
                />
              )}
            </Flex>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("STFI")}
                onFocus={() =>
                  setHoveredLabel("STFI (Storm, Tempest, Flood, Inundation)")
                }
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
          <FormControl>
            <HoverableFormLabel>Money Coverage</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Money_coverage")}
                onFocus={() => setHoveredLabel("Money Coverage")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <HoverableFormLabel>Burglary</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Burglury")}
                onFocus={() => setHoveredLabel("Burglary")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
          <FormControl>
            <HoverableFormLabel>First Loss Profit</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("First_loss_Profit")}
                onFocus={() => setHoveredLabel("First Loss Profit")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <HoverableFormLabel>Terrorism</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Terrorism")}
                onFocus={() => setHoveredLabel("Terrorism")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Fidelity Insurance Coverage</HoverableFormLabel>
            <NumberInput min={0}>
              <NumberInputField
                borderRadius="21px"
                bg="white"
                {...register("Fidelity_insurance_Coverage")}
                onFocus={() => setHoveredLabel("Fidelity Insurance Coverage")}
                onBlur={() => resetHoveredLabel()}
              />
            </NumberInput>
          </FormControl>
        </HStack>
        <Box borderTop="1px solid " mt={4} pt={4} />
        {/* Bid Dates */}
        <HStack gap={4}>
          {/* Bid Start Date */}
          <FormControl id="start_date" isInvalid={Boolean(errors?.start_date)}>
            <HoverableFormLabel>Bid's Start Date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("start_date", {
                required: "Bid's start date is required",
              })}
              onFocus={() => setHoveredLabel("Bid's Start Date")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.start_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.start_date?.message}
              </Text>
            )}
          </FormControl>

          {/* Bid End Date */}
          <FormControl id="end_date" isInvalid={Boolean(errors?.end_date)}>
            <HoverableFormLabel>Bid's End Date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch("start_date")}
              {...register("end_date", {
                required: "Bid's end date is required",
              })}
              onFocus={() => setHoveredLabel("Bid's End Date")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.end_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.end_date?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          {/* Policy Start Date */}
          <FormControl id="policy_start_date">
            <HoverableFormLabel>Policy Start Date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("policy_start_date")}
              onFocus={() => setHoveredLabel("Policy Start Date")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>

          {/* Policy End Date */}
          <FormControl id="policy_end_date">
            <HoverableFormLabel>Policy End Date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch("policy_start_date")}
              {...register("policy_end_date")}
              onFocus={() => setHoveredLabel("Policy End Date")}
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>
        </HStack>
        {/* Expected premium */}
        <HStack gap={4}>
          {/* Anticipated Premium */}
          <FormControl
            id="expected_premium"
            isInvalid={Boolean(errors?.expected_premium)}
          >
            <HoverableFormLabel>Anticipated Premium*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              defaultValue="0"
              {...register("expected_premium", {
                required: "Anticipated premium is required",
              })}
              onFocus={() => setHoveredLabel("Anticipated Premium")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.expected_premium && (
              <Text color="red.500" fontSize="sm">
                {errors?.expected_premium?.message}
              </Text>
            )}
          </FormControl>

          {/* Total Coverages */}
          <FormControl
            id="sum_insured"
            isInvalid={Boolean(errors?.sum_insured)}
          >
            <Flex align="start">
              <HoverableFormLabel>Total Coverages*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Anticipated premium for acquiring this policy."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              defaultValue="0"
              {...register("sum_insured", {
                required: "Total coverages is required",
              })}
              onFocus={() => setHoveredLabel("Total coverages")}
              onBlur={() => resetHoveredLabel()}
            />
            {errors?.sum_insured && (
              <Text color="red.500" fontSize="sm">
                {errors?.sum_insured?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        {/* Previous insurer */}
        {watch("policytype") === "Renewal" && (
          <HStack gap={4}>
            {/* Previous Insurer */}
            <FormControl
              id="previous_insurer"
              isInvalid={Boolean(errors?.previous_insurer)}
            >
              <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...register("previous_insurer", {
                  required: "Previous insurer is required",
                })}
                onFocus={() => setHoveredLabel("Previous Insurer")}
                onBlur={() => resetHoveredLabel()}
              />
              {errors?.previous_insurer && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_insurer?.message}
                </Text>
              )}
            </FormControl>

            {/* Previous Policy Expiry */}
            <FormControl
              id="previous_policy_expiry"
              isInvalid={Boolean(errors?.previous_policy_expiry)}
            >
              <HoverableFormLabel>Previous policy expiry*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...register("previous_policy_expiry", {
                  required: "Previous policy expiry is required",
                })}
                onFocus={() => setHoveredLabel("Previous policy expiry")}
                onBlur={() => resetHoveredLabel()}
              />
              {errors?.previous_policy_expiry && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_policy_expiry?.message}
                </Text>
              )}
            </FormControl>
          </HStack>
        )}
        <Flex w="100%" justify="space-between" align="center" mt="20px">
          <Button
            type="button"
            borderRadius="21px"
            onClick={() => setStep(step - 1)}
          >
            Previous
          </Button>
          <Button
            type="submit"
            borderRadius="21px"
            colorScheme="green"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Next
          </Button>
        </Flex>
      </VStack>
    </form>
  );
};

export default StandardFire;
